// text that should be hidden in transcript mode but not in media mode
// ex: `_ ` and music
export const nonText = {
  attrs: {
    class: { default: 'nonText' }
  },
  parseDOM: [{
    tag: `span.nonTextT`
  }, {
    tag: 'span.nonText'
  }],
  toDOM: (mark) => {
    if (window.mode === 'editTranscript') {
      mark.attrs.class = 'nonTextT'
    } else {
      mark.attrs.class = 'nonText'
    }
    return ['span', mark.attrs]
  }
}
