import Vue from 'vue'

export const defaultState = () => ({
  newDoc: '',
  newParagraph: false,
  currentPara: 0,
  currentTime: 0,
  paragraphMeta: [],
  labelIndex: 1,
  labelName: '',
  labelColor: '',
  paraPos: '',
  elapsedTime: 0,
  isOverMusic: false
})
export default {
  namespaced: true,
  state: defaultState,
  getters: {
    currentTime: state => state.currentTime,
    newDoc: state => state.newDoc,
    newParagraph: state => state.newParagraph,
    elapsedTime: state => state.elapsedTime,
    isOverMusic: state => state.isOverMusic
  },
  mutations: {
    setNewDoc (state, payload) {
      state.newDoc = payload
    },
    clearNewDoc (state) {
      state.newDoc = ''
    },
    addNewParagraph (state, payload) {
      state.newParagraph = payload
    },
    setCurrentPara (state, payload) {
      state.currentPara = payload
    },
    setCurrentTime (state, payload) {
      state.currentTime = payload
    },
    incrementCurrentTime (state, payload) {
      state.currentTime = payload
    },
    setElapsedTime (state, time) {
      state.elapsedTime = time
    },
    setParagraphMeta (state, payload) {
      // console.log('prev meta', payload)
      Vue.set(state, 'paragraphMeta', payload)
    },
    setIsOverMusic (state, payload) {
      state.isOverMusic = payload
    }
  }
}
