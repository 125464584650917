import {
  disableFillerWords, disableFillerWordsWithResult,
  enableFillerWords, enableFillerWordsWithResult,
  trimSilence, trimSilenceWithResult,
  unTrimSilence, unTrimSilenceWithResult,
  updatePodcastInfo
} from '@/view/voiceEditor/proseEditor/util/smartEditUtilities'

const findAndToggleItem = (arr, item) => {
  if (!Array.isArray(arr)) return []
  const i = arr.findIndex(el => el === item)
  return i === -1 ? [...arr, item] : [...arr.slice(0, i), ...arr.slice(i + 1)]
}

export const defaultState = () => ({
  silenceUpto: 2,
  fillerWords: {
    words: ['um', 'uh'],
    isEnabled: true,
    count: 0
  },
  trimSilence: {
    isEnabled: true,
    durationInMs: 0,
    formatedDuration: '0 secs'
  },
  noiseReduction: {
    isEnabled: true,
    config: ['cleanBgNoise', 'voiceLevelling', 'boostSpeaker']
  },
  initialised: false
})
export default {
  namespaced: true,
  state: defaultState,
  getters: {
    isFillerWordsEnabled: state => Boolean(state.fillerWords.isEnabled),
    isTrimSilenceEnabled: state => Boolean(state.trimSilence.isEnabled),
    isNoiseReductionEnabled: state => Boolean(state.noiseReduction.isEnabled),
    fillerWords: state => state.fillerWords,
    silenceUpto: state => state.silenceUpto,
    allFillerWords: state => state.fillerWords.words,
    trimSilence: state => state.trimSilence,
    noiseReduction: state => state.noiseReduction,
    nrConfig: state => state.noiseReduction.config || ['cleanBgNoise', 'voiceLevelling', 'boostSpeaker'],
    initialised: state => state.initialised
  },
  mutations: {
    setSmartEditConfig: function(state, {
      fillerWords = ['um', 'uh'],
      trimSilence = {},
      silenceUpto = 2,
      noiseReduction = {},
      initialised
    }) {
      console.log('smartedit payload', { fillerWords, trimSilence, silenceUpto, noiseReduction, initialised })
      state.fillerWords = fillerWords
      state.trimSilence = trimSilence
      state.silenceUpto = silenceUpto
      state.noiseReduction = noiseReduction
      state.initialised = initialised
    },
    setFillerWords: function(state, payload) {
      if (payload) state.fillerWords = payload
    },
    setTrimSilence: function(state, payload) {
      if (payload) {
        state.trimSilence = payload
      }
    },
    setFillerWordIsEnabled: function(state, payload) {
      state.fillerWords.isEnabled = payload
    },
    setTrimSilenceIsEnabled: function(state, payload) {
      state.trimSilence.isEnabled = payload
    },
    setInitialised: function(state, payload) {
      state.initialised = payload
    },
    setFillerWordCount: function(state, payload) {
      state.fillerWords.count = payload
    },
    updateFillerWords: function(state, payload) {
      state.fillerWords.words = payload
    },
    setTrimmedSilenceDurationInMs: function(state, payload) {
      const formatSeconds = durationInSeconds => {
        const minutes = Math.floor(Math.round(durationInSeconds) / 60)
        const seconds = Math.round(durationInSeconds) - minutes * 60

        return `${minutes ? (minutes + 'm ') : ''}${seconds} secs`
      }
      state.trimSilence.durationInMs = payload
      state.trimSilence.formatedDuration = formatSeconds(payload)
    },
    setSilenceUpto: function(state, payload) {
      state.silenceUpto = payload
    },
    setNoiseReductionIsEnabled: function(state, payload) {
      state.noiseReduction = {
        ...state.noiseReduction,
        isEnabled: payload
      }
    },
    setNoiseReductionConfig: function(state, payload) {
      state.noiseReduction.config = payload
    }
  },
  actions: {
    initialiseSmartEditConfig: function({ state, commit }, { podId, ownerId }) {
      let update = { ...defaultState() }

      // update smart edit store
      commit('setSmartEditConfig', update)
      console.log('smarteditconfig initialised')
      updatePodcastInfo(update, podId, ownerId)
    },
    initialiseSmartEdit: function({ state, commit }, { podId, isEnabled, ownerId }) {
      commit('setInitialised', true)
      console.log('smartedit initialised')
      updatePodcastInfo({
        initialised: true
      }, podId, ownerId)
    },
    setFillerWordsIsEnabled: function({ state, commit }, { podId, isEnabled, ownerId, searchResult }) {
      if (isEnabled) disableFillerWordsWithResult(podId, ownerId, searchResult)
      else enableFillerWordsWithResult(podId, ownerId, searchResult)
      // commit('setFillerWordIsEnabled', isEnabled)
      let update = {
        fillerWords: state.fillerWords
      }
      updatePodcastInfo(update, podId, ownerId)
    },
    setTrimSilenceIsEnabled: function({ state, commit }, { podId, isEnabled, ownerId, searchResult }) {
      if (isEnabled) trimSilenceWithResult(podId, ownerId, searchResult)
      else unTrimSilenceWithResult(podId, ownerId, searchResult)
      // commit('setTrimSilenceIsEnabled', isEnabled)
      let update = {
        trimSilence: state.trimSilence
      }
      updatePodcastInfo(update, podId, ownerId)
    },
    setNoiseReductionIsEnabled({ state, commit }, { podId, isEnabled, all, ownerId }) {
      commit('setNoiseReductionIsEnabled', isEnabled)
      let update = {
        noiseReduction: {
          ...state.noiseReduction,
          isEnabled
        }
      }
      updatePodcastInfo(update, podId, ownerId)
    },
    setNoiseReductionConfig: function({ commit }, payload) {
      commit('setNoiseReductionConfig', payload)
    },
    updateConfig: function({ state, commit }, payload) {
      const {
        podId,
        ownerId,
        silenceUpto,
        words,
        nrConfig,
        isFillerWordsEnabled,
        isTrimSilenceEnabled,
        isNoiseReductionEnabled
      } = payload
      commit('setSilenceUpto', silenceUpto)
      commit('updateFillerWords', words)
      commit('setNoiseReductionConfig', nrConfig)
      commit('setFillerWordIsEnabled', isFillerWordsEnabled)
      commit('setTrimSilenceIsEnabled', isTrimSilenceEnabled)
      commit('setNoiseReductionIsEnabled', isNoiseReductionEnabled)
      const update = {
        silenceUpto,
        fillerWords: {
          ...state.fillerWords,
          isEnabled: isFillerWordsEnabled,
          words
        },
        trimSilence: {
          ...state.trimSilence,
          isEnabled: isTrimSilenceEnabled
        },
        noiseReduction: {
          ...state.noiseReduction,
          isEnabled: isNoiseReductionEnabled,
          config: nrConfig
        }
      }
      updatePodcastInfo(update, podId, ownerId, () => {
        if (isFillerWordsEnabled) disableFillerWords(podId, ownerId)
        else enableFillerWords(podId, ownerId)

        if (isTrimSilenceEnabled) trimSilence(podId, ownerId)
        else unTrimSilence(podId, ownerId)
      })
    }
  }
}
