<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    :class="classname"
  >
    <path d="M20.9468 6.30303C20.0178 4.33574 18.5263 2.68811 16.6609 1.56848C14.7956 0.448846 12.6401 -0.0924917 10.467 0.0129191C8.29399 0.11833 6.20101 0.865755 4.45276 2.16068C2.70451 3.4556 1.3795 5.23986 0.645303 7.28782C-0.0888959 9.33579 -0.199315 11.5555 0.328009 13.6662C0.855334 15.7769 1.99672 17.6839 3.60783 19.1459C5.21893 20.6079 7.2274 21.5594 9.37925 21.8799C11.5311 22.2005 13.7297 21.8758 15.697 20.9468L14.7203 18.8784C13.1621 19.6142 11.4207 19.8714 9.71628 19.6175C8.0119 19.3636 6.42108 18.61 5.145 17.452C3.86892 16.294 2.96488 14.7836 2.54721 13.1118C2.12954 11.44 2.217 9.68186 2.79852 8.05976C3.38005 6.43766 4.42952 5.02443 5.81423 3.99878C7.19894 2.97313 8.8567 2.38113 10.5779 2.29764C12.299 2.21415 14.0063 2.64292 15.4838 3.52973C16.9613 4.41653 18.1426 5.72155 18.8784 7.27975L20.9468 6.30303Z" />
  </svg>
</template>

<script>
export default {
  name: 'CircularLoader',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
