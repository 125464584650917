// only used in editTranscript mode
// Do Not change class any name here - will affect- moveCursorInDirection
export let showDeleted = true
export const deleted = {
  attrs: {
    class: { default: 'deleted' },
    type: { default: 'user' }
  },
  parseDOM: [{ tag: `span.deleted` }, { tag: `span.deletedT` }],
  //  just toggle the class to not show deleted sections
  toDOM: (mark) => {
    if (!showDeleted || window.mode === 'editTranscript') {
      mark.attrs.class = 'deletedT'
    } else {
      mark.attrs.class = 'deleted'
    }
    return ['span', mark.attrs]
  }
}

export function setShowDeleted (showDeletedTruth) {
  showDeleted = showDeletedTruth
}
