import Vue from 'vue'
import VueRouter from 'vue-router'
import VTooltip from 'v-tooltip'
import VueCookies from 'vue-cookies'

// import VueTour from 'vue-tour'
// import VueContentPlaceholders from 'vue-content-placeholders'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/performance'

import CONSTANTS from '@/constants/CONSTANTS'
import VueObserveVisibility from 'vue-observe-visibility'

import App from './App.vue'
import router from './router'
import store from '@/services/store'

// const VueYoutube = require('vue-youtube')
window.$ = window.jQuery = window.jquery = require('jquery')
require('jquery.scrollto')
require('./analytics/index.js')
// require('./logging/index.js')
require('./assets/js/semantic.js')

Vue.use(VueCookies, { expire: Infinity })
Vue.use(VueRouter)
Vue.use(VueObserveVisibility)
// Vue.use(VueContentPlaceholders)
// Vue.use(VueYoutube)
Vue.use(VTooltip, {
  defaultDelay: {
    show: 500,
    hide: 0
  }
})

/* tslint:disable */
// Vue.use(VueTour)
require('animate.css')
require('@/assets/semantic.min.css')
require('@/assets/css/vue-tour.css')
require('@/assets/css/tooltip.scss')
require('@/assets/css/custom.css')
require('@/assets/css/tailwind.css')
require('@/assets/css/guide.scss')
Vue.config.productionTip = true

firebase.initializeApp(CONSTANTS.CONFIG)
export const db = firebase.database()
export const perf = firebase.performance()
let app:any = ''
firebase.auth().onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      el: '#app',
      render: h => h(App)
    })
  }
})
