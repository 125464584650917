
<style scoped lang="scss">
.uploadFileModal {
  width: 780px;
  height: 480px;

  .mainContainer {
    max-height: 356px;
    min-height: 386px;
  }

  .wideInput {
    width: 360px;
  }

  .widerInput {
    width: 512px;
  }
}

</style>

<template>
  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div class="items-end justify-center block max-h-screen min-h-screen p-0 px-4 pt-4 pb-20 text-center">
      <div class="fixed inset-0 transition-opacity bg-primary-dark opacity-80" @click="close" />
      <span class="hidden sm:h-screen sm:inline-block sm:align-middle" aria-hidden="true">&#8203;</span>
      <div class="relative inline-block text-left align-middle transition-all transform bg-white rounded-lg">
        <div @click="close" class="absolute z-10 cursor-pointer top-4 right-4 text-primary-500 hover:text-primary-700" >
          <CloseIcon class="w-5 h-5" />
        </div>

        <!-- Content -->
        <div class="relative flex flex-col p-4 uploadFileModal">
          <UploadStatus
            :status="status"
            :duration="duration"
            :cancelItemId="cancelItemId"
            :totalDuration="totalDuration"
            @cancelUpload="cancelUpload"
            @buyMinutes="buyMinutes"
            @clear="clear"
            @close="close"
            @clearStatus="clearStatus"
            @clearCancelId="clearCancelId"
            @setShowSupportedFiles="setShowSupportedFiles"
          />

          <div v-show="!showSupportedFiles" class="text-3xl font-semibold font-h text-primary">
            Upload <span v-show="isOnboarding">your recording</span>
          </div>

          <div v-show="showSupportedFiles" class="flex items-center text-3xl font-semibold font-h text-primary">
            <div @click="showSupportedFiles=false" class="mr-3">
              <ArrowBack class="w-6 h-6" />
            </div>
            <div>Supported Files</div>
          </div>

            <!-- bordered box -->
            <div class="relative mt-4 border rounded border-primary-300 mainContainer">
              <!-- non supportedfiles tabs-->
              <div v-show="!showSupportedFiles">
                <div v-if="!minimal" class="flex items-center w-full border-b border-primary-300">
                  <div
                    v-for="key in Object.keys(menuOptions)"
                    :key="key"
                    class="flex items-center justify-center w-full text-sm border-b-4 border-transparent cursor-pointer h-11 mr-9 last:mr-0"
                    :class="{'font-semibold border-red': selectedMenu === key}"
                    @click="changeMenu(key)"
                  >
                    {{menuOptions[key]}}
                  </div>
                </div>

                <!-- tabcontent start -->
                <div class="p-6">
                  <!-- normal tab body start -->
                  <form v-show="selectedMenu==='normal'">
                    <input
                      v-show="file==''"
                      @keyup.enter="checkURLNow"
                      @input="url=url.trim();duration=0;urlValid=false;urlTitle='';status='';checkURL();" type="text"
                      placeholder="Copy the audio or video link here"
                      v-model="url"
                      class="w-full px-4 py-3 text-sm border rounded-lg bg-primary-100 border-primary-200 text-primary placeholder-primary-500 focus:border-primary-200 focus:ring-primary focus:ring-2"
                    >

                    <div
                      v-show="file=='' && url==''"
                      class="mt-4 text-sm text-center"
                    >
                      Or
                    </div>

                    <div v-show="url==''" class="flex items-center justify-center mt-4">
                      <label
                        v-show="file==''"
                        for="uploadFile"
                        class="bg-primary border border-primary rounded-xl cursor-pointer font-medium text-xs text-white py-2.75 px-3.75 pr-2.75 base inline-flex items-center uppercase bls hover:bg-primary-900 hover:border-primary-900 focus:outline-none focus:bg-primary-800 focus:border-primary-800"
                      >
                        Upload from Computer
                      </label>
                      <div
                        v-show="file==''"
                        for="uploadFile"
                        @click="openDropbox"
                        class="border rounded-xl cursor-pointer font-medium border-primary-300 text-xs text-primary ml-2.5 py-2.75 px-3.75 base inline-flex items-center uppercase bls hover:bg-primary-200 hover:border-primary-200 focus:outline-none focus:bg-primary-300 focus:border-primary-300"

                      >
                        Upload from Dropbox
                      </div>
                      <div
                        v-show="file!=''"
                        class="flex items-center text-sm cursor-pointer"
                        @click="openFileDialog"
                      >
                        <FileAddedIcon class="w-6 h-6 mr-3 text-green" />
                        <span>
                          {{file.name}}
                        </span>
                      </div>

                      <input
                        class="hidden"
                        id="uploadFile"
                        :accept="videoOnly ? 'video/*' : 'audio/*,video/*'"
                        type="file"
                        value="upload"
                        @change="checkFile($event)"
                      />
                    </div>

                    <div v-show="urlValid==true" class="flex flex-col items-center mt-4">
                      <div class="mb-1 text-base font-semibold font-h text-primary">
                        Title<sup>*</sup>
                      </div>
                      <input
                        @keyup.enter="upload"
                        @input="status=''"
                        type="text"
                        placeholder="Title"
                        v-model="urlTitle"
                        class="px-4 py-3 text-sm border rounded-lg bg-primary-100 border-primary-200 text-primary placeholder-primary-500 wideInput focus:border-primary-200 focus:ring-primary focus:ring-2"
                      >

                      <div class="mt-3 mb-1 text-base font-semibold font-h text-primary">
                        Language
                      </div>

                      <v-popover
                        placement="bottom"
                        popoverClass="popover-sm"
                        popoverArrowClass="hidden"
                      >
                        <div class="flex items-center justify-between px-4 py-3 text-sm border rounded cursor-pointer bg-primary-100 border-primary-200 text-primary wideInput">
                          <div class="text-sm">
                            {{languages.find(t => t.code.t == targetLanguage).simplename.t}}
                          </div>
                          <ChevronDown class="w-3 h-3 dropdownCaret"/>
                        </div>

                        <template slot="popover">
                          <div class="max-h-36 py-1.5 pr-0.5 pl-1.5 wideInput overflow-y-auto">
                            <div
                              v-for="(value,key) in languages"
                              :key="key"
                              @click="targetLanguage=value.code.t;"
                              v-close-popover
                              class="rounded cursor-pointer text-sm text-primary mb-2 py-2.5 pr-2.5 pl-9 relative hover:bg-primary-200 last:mb-0"
                            >
                              {{value.simplename.t}}
                              <CheckIcon v-if="targetLanguage == value.code.t" class="h-5 top-2.5 left-1.5 w-5 absolute"/>
                            </div>
                          </div>
                        </template>
                      </v-popover>
                    </div>
                  </form>
                  <!-- normal tab body end -->
                  <!-- normal tab footer start -->
                  <div v-show="selectedMenu=='normal'"  class="flex items-center justify-center mt-4">
                    <Button
                      v-show="(file!='' || url!='') && (urlValid==true && checkUrlTitle(urlTitle))"
                      :disabled="!(urlValid==true && checkUrlTitle(urlTitle) && (duration<=balance || (balance>0 && billing.trial)))"
                      @click="upload"
                      :variant="!(urlValid==true && checkUrlTitle(urlTitle) && (duration<=balance || (balance>0 && billing.trial)))?'disabled':'primary'"
                    >
                      Upload
                    </Button>
                    <Button
                      v-show="urlValid==='progressing'"
                      variant="disabled"
                    >
                      Progressing
                    </Button>
                    <div
                      v-show="(file!='' || url!='')&&(urlValid==true ||urlValid==false)"
                      @click="clear"
                      class="cursor-pointer ml-2.5 hover:text-red"
                    >
                      <RestoreIcon class="w-6 h-6" />
                    </div>

                    <div
                      v-show="urlValid==false"
                      class="absolute bottom-0 left-0 right-0 flex justify-center border-t border-primary-300"
                    >
                      <div class="rounded bg-primary-100 my-2 text-xs text-primary py-3 px-3.5">
                        Note: Spext works best when audio has no background noise.
                      </div>
                    </div>
                  </div>
                  <!-- normal tab footer end -->

                  <!-- folder tab body start -->
                  <form v-show="selectedMenu==='folder'">
                    <div class="flex flex-col items-center">
                      <label
                        v-show="folder==''"
                        for="uploadFolder"
                        class="bg-primary border border-primary rounded-xl cursor-pointer font-medium mt-20 text-xs text-white py-2.75 px-3.75 pr-2.75 base inline-flex items-center uppercase bls hover:bg-primary-900 hover:border-primary-900 focus:outline-none focus:bg-primary-800 focus:border-primary-800"
                      >
                        Upload a folder
                      </label>
                      <!-- upload folders here -->
                      <input
                        class="hidden"
                        id="uploadFolder"
                        accept="audio/*,video/*"
                        type="file"
                        value="upload"
                        @change="checkFolder($event)"
                        webkitdirectory
                      />

                      <div
                        v-if="folder!='' && folder.length>0"
                        class="flex items-center"
                      >
                        <FolderCheckIcon class="h-6 text-green mr-2.5 w-6" />
                        <div>
                          <div class="text-sm">
                            Folder "{{folder[0].webkitRelativePath.split('/').reverse()[1]}}" Selected
                          </div>
                          <div v-show="urlValid==true" class="text-xs">
                            <div v-if="supported>0">
                              <span class="mr-1 text-primary-600">Found</span>
                              <span
                                @click="showSupportedFiles=true"
                                class="cursor-pointer text-blue"
                              >
                                {{supported}} Supported Files
                              </span>
                            </div>
                            <div v-else>
                              <span class="mr-1 text-primary-600">Found</span>
                              <span
                                @click="showSupportedFiles=true"
                                class="underline cursor-pointer text-red"
                              >
                                {{supported}} Supported Files
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-show="urlValid==true && supported>0" class="flex flex-col items-center mt-8">
                      <div class="mb-4 text-base font-semibold font-h text-primary">
                        Language
                      </div>

                      <v-popover
                        placement="bottom"
                        popoverClass="popover-sm"
                        popoverArrowClass="hidden"
                      >
                        <div class="flex items-center justify-between px-4 py-3 text-sm border rounded cursor-pointer bg-primary-100 border-primary-200 text-primary wideInput">
                          <div class="text-sm">
                            {{languages.find(t => t.code.t == targetLanguage).simplename.t}}
                          </div>
                          <ChevronDown class="w-3 h-3 dropdownCaret"/>
                        </div>

                        <template slot="popover">
                          <div class="max-h-36 py-1.5 pr-0.5 pl-1.5 wideInput overflow-y-auto">
                            <div
                              v-for="(value,key) in languages"
                              :key="key"
                              @click="targetLanguage=value.code.t;"
                              v-close-popover
                              class="rounded cursor-pointer text-sm text-primary mb-2 py-2.5 pr-2.5 pl-9 relative hover:bg-primary-200 last:mb-0"
                            >
                              {{value.simplename.t}}
                              <CheckIcon v-if="targetLanguage == value.code.t" class="h-5 top-2.5 left-1.5 w-5 absolute"/>
                            </div>
                          </div>
                        </template>
                      </v-popover>
                    </div>
                  </form>
                  <!-- folder tab body end -->
                  <!-- folder tab footer start -->
                  <div
                    v-show="selectedMenu==='folder' && !showSupportedFiles"
                    class="flex items-center justify-center mt-6"
                  >
                    <Button
                      v-show="(urlValid==true && supported>0 && totalDuration<=balance)"
                      @click="uploadFolder"
                    >
                      Upload
                    </Button>

                    <Button
                      v-show="urlValid==='progressing'"
                      variant="disabled"
                    >
                      Progressing
                    </Button>

                    <div
                      v-show="(folder != '' || url != '')&&(urlValid==true ||urlValid==false)"
                      @click="clear"
                      class="cursor-pointer ml-2.5 hover:text-red"
                    >
                      <RestoreIcon class="w-6 h-6" />
                    </div>

                    <div
                      v-show="urlValid==false"
                      class="absolute bottom-0 left-0 right-0 flex justify-center border-t border-primary-300"
                    >
                      <div class="rounded bg-primary-100 my-2 text-xs text-primary py-3 px-3.5">
                        Note: Spext works best when audio has no background noise.
                      </div>
                    </div>
                  </div>
                  <!-- folder tab footer end -->

                  <!-- multi tab body start -->
                  <form
                    v-show="selectedMenu==='multi' && step==1"
                    class="flex flex-col items-center"
                  >
                    <div class="text-sm">
                      Select Tracks
                    </div>
                    <!-- Multitrack upload options -->
                    <div class="flex w-2/3 mt-5">
                        <div class="flex flex-col items-center w-4/12">
                          <UploadItem
                            id="1"
                            ref="uploadItem1"
                            @addToUploadList="addToUploadList"
                          />
                        </div>
                        <div class="flex flex-col items-center w-4/12">
                          <UploadItem
                            id="2"
                            ref="uploadItem2"
                            @addToUploadList="addToUploadList"
                          />
                        </div>
                        <div class="flex flex-col items-center w-4/12">
                          <UploadItem
                            id="3"
                            ref="uploadItem3"
                            @addToUploadList="addToUploadList"
                          />
                        </div>
                    </div>
                  </form>

                  <form
                    v-if="selectedMenu==='multi' && step==2"
                    class="flex flex-col items-center"
                  >
                    <div class="text-sm text-primary-700">
                      First, let's merge the tracks and make sure they sound right.
                    </div>

                    <div v-show="mergedTrack===true" class="mt-8">
                      <AudioPlayerUpload :podId="podId" :field="'audio_url'" :uid="user.uid"/>
                    </div>
                    <!-- play merged audio -->
                    <div v-show="mergedTrack===false" class="flex flex-col items-center mt-8">
                      <MusicPlay class="h-14 text-primary-400 w-14"/>
                      <div class="mt-1 text-sm text-primary-700">
                        Merging tracks...
                      </div>
                    </div>
                  </form>

                  <form
                    v-show="selectedMenu==='multi' && step==3"
                    class="flex flex-col items-center"
                  >
                    <div class="mt-1 mb-2 text-base font-semibold font-h text-primary">
                      Title
                    </div>
                    <input
                      @keyup.enter="upload"
                      @input="status=''"
                      type="text"
                      placeholder="Title"
                      v-model="urlTitle"
                      class="px-4 py-3 text-sm border rounded-lg bg-primary-100 border-primary-200 text-primary placeholder-primary-500 widerInput focus:border-primary-200 focus:ring-primary focus:ring-2"
                    >

                    <div class="mt-3 mb-1 text-base font-semibold font-h text-primary">
                      Language
                    </div>

                    <v-popover
                      placement="bottom"
                      popoverClass="popover-sm"
                      popoverArrowClass="hidden"
                    >
                      <div class="flex items-center justify-between px-4 py-3 text-sm border rounded cursor-pointer bg-primary-100 border-primary-200 text-primary widerInput">
                        <div class="text-sm">
                          {{languages.find(t => t.code.t == targetLanguage).simplename.t}}
                        </div>
                        <ChevronDown class="w-3 h-3 dropdownCaret"/>
                      </div>

                      <template slot="popover">
                        <div class="max-h-36 py-1.5 pr-0.5 pl-1.5 widerInput overflow-y-auto">
                          <div
                            v-for="(value,key) in languages"
                            :key="key"
                            @click="targetLanguage=value.code.t;"
                            v-close-popover
                            class="rounded cursor-pointer text-sm text-primary mb-2 py-2.5 pr-2.5 pl-9 relative hover:bg-primary-200 last:mb-0"
                          >
                            {{value.simplename.t}}
                            <CheckIcon v-if="targetLanguage == value.code.t" class="h-5 top-2.5 left-1.5 w-5 absolute"/>
                          </div>
                        </div>
                      </template>
                    </v-popover>
                  </form>
                  <!-- multi tab body end -->
                  <!-- multi tab footer start -->
                  <div
                    v-show="selectedMenu=='multi' && step===1"
                    class="flex items-center justify-center mt-5"
                  >
                    <Button
                      v-if="tracks > 1"
                      @click="step=2;mergeTracks();"
                    >
                      Next
                    </Button>
                    <Button
                      v-else
                      variant="disabled"
                    >
                      Next
                    </Button>

                    <div class="absolute bottom-0 left-0 right-0 flex justify-center p-2 border-t border-primary-300">
                      <div class="p-3 text-xs rounded bg-primary-100 text-primary">
                        <div>
                          💡 Ensure all tracks start at the same time. They can be of different length but they have to start at the same time.
                        </div>
                        <div class="mt-1">
                          <b>Audacity users:</b> If one of the voice track does not start at the very beginning, please add silence to ensure all start at same start time
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-show="selectedMenu=='multi' && step===2"
                    class="flex items-center justify-center mt-8"
                  >
                    <Button
                      @click="stopSound();step=1"
                      variant="secondary"
                      class="mr-2.5"
                    >
                      Previous
                    </Button>
                    <Button
                      v-if="mergedTrack"
                      @click="stopSound();step=3"
                    >
                      Next
                    </Button>
                    <Button
                      v-else
                      variant="disabled"
                    >
                      Next
                    </Button>

                    <div
                      v-show="mergedTrack"
                      class="absolute bottom-0 left-0 right-0 flex justify-center p-2 border-t border-primary-300"
                    >
                      <div class="p-3 text-xs rounded bg-primary-100 text-primary">
                        <div class="font-bold">
                          Not Sounding right?
                        </div>
                        <div>
                          <b>Ensure all tracks start at the same time.</b> They can be of different length but they have to start at the same time.
                        </div>
                        <div class="mt-1">
                          <b>Audacity users:</b> If one of the voice track does not start at the very beginning, please add silence to ensure all start at same start time
                        </div>
                        <div
                          class="cursor-pointer text-blue"
                          @click="window.Intercom('showNewMessage', 'I am having trouble in Multi-Track.')"
                        >
                          Contact Support
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-show="selectedMenu=='multi' && step===3"
                    class="flex items-center justify-center mt-8"
                  >
                    <Button
                      @click="step=2"
                      variant="secondary"
                      class="mr-2.5"
                    >
                      Previous
                    </Button>
                    <Button
                      v-if="urlTitle!==''"
                      @click="uploadMulti"
                    >
                      Upload
                    </Button>
                    <Button
                      v-else
                      variant="disabled"
                    >
                      Upload
                    </Button>

                    <Button
                      @click="clear"
                      variant="secondary"
                      class="ml-2.5"
                    >
                      Reset
                    </Button>
                  </div>

                  <!-- multi tab footer end -->

                </div>
                <!-- tabcontent end -->
              </div>
              <!-- nonsupportedFiles end -->

              <!-- supportedfiles start -->
              <div v-show="showSupportedFiles" class="max-h-full p-2.5 overflow-y-auto">
                <div
                  v-for="(tfile,index) in folderFiles.filter(t => t.file.name !== '.DS_Store')"
                  :key="index"
                  class="flex py-2.5 px-2 items-center justify-between"
                >
                  <div
                    v-if="tfile.valid===2"
                    class="flex items-center"
                  >
                    <CheckIcon class="h-6 text-green mr-2.5 w-6"/>
                    <div class="text-sm">{{tfile.file.name.slice(0,10)}}</div>
                  </div>
                  <div
                    v-else
                    class="flex items-center"
                  >
                    <CloseCircleIcon class="h-6 text-red mr-2.5 w-6"/>
                    <div class="text-sm">{{tfile.file.name.slice(0,10)}}</div>
                  </div>

                  <div class="flex items-center">
                    <div
                      v-if="tfile.valid===2"
                      class="text-xs text-primary"
                    >
                      {{humanLength(tfile.duration)}}
                    </div>
                    <div
                      @click="removeFile(index)"
                      v-if="tfile.valid===2"
                      class="ml-1 cursor-pointer text-primary-500 hover:text-red"
                    >
                      <DeleteIcon class="w-6 h-6" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- URL Checking loader -->
              <div
                v-show="(urlValid!=true && urlValid!=false && urlValid!=='progressing')"
                class="flex flex-col items-center mt-10"
              >
                <CircularLoaderIcon class="h-7 text-primary w-7"/>
                <div class="mt-5 text-xs text-primary">
                  Checking...
                </div>
              </div>
            </div>
            <!-- bordered box end -->

        </div>
        <!-- Content end -->
      </div>
    </div>
  </div>
</template>

<script>
/*
Logic to upload files here -

if file upload
  if checkFile()
    urlValid=true, show upload Button
  else
    urlValid = false
if url upload
  if checkURL() -> checkURLNow()
    urlValid=true, show upload Button
  else
    urlValid = ERROR_CODE
upload
  if file
    fileAdded -> parent.fileAdded()
  else
    addPodcast()
*/
import axios from 'axios'
import * as firebase from 'firebase/app'
import 'firebase/database'
import debounce from 'lodash/debounce'
import CONSTANTS from '@/constants/CONSTANTS'
import { Howl } from 'howler'
import store from '@/services/store'
import { humanLength } from '@/utilities/utility'

import Button from '@/components/base/buttons/Button.vue'
import CircularLoaderIcon from '@/components/base/icons/CircularLoader.vue'
import CloseIcon from '@/components/base/icons/Close.vue'
import FileAddedIcon from '@/components/base/icons/FileAdded.vue'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'
import CheckIcon from '@/components/base/icons/Check.vue'
import RestoreIcon from '@/components/base/icons/Restore.vue'
import FolderCheckIcon from '@/components/base/icons/FolderCheck.vue'
import MusicPlay from '@/components/base/icons/MusicPlay.vue'
import ArrowBack from '@/components/base/icons/ArrowBack.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'
import CloseCircleIcon from '@/components/base/icons/CloseCircle.vue'
import { setOnboardingState } from '@/services/api/account'
import { mapGetters } from 'vuex'

const uuidv1 = require('uuid/v1')
require('@/assets/js/dropins.js')
// import Aplayer from 'vue-aplayer'
export default {
  name: 'upload',
  components: {
    Button,
    RestoreIcon,
    CircularLoaderIcon,
    CloseIcon,
    CloseCircleIcon,
    FileAddedIcon,
    ChevronDown,
    CheckIcon,
    FolderCheckIcon,
    MusicPlay,
    ArrowBack,
    DeleteIcon,
    UploadItem: () => import(/* webpackChunkName: "UploadItemForUpload" */'@/components/UploadItemForUpload'),
    'AudioPlayerUpload': () => import(/* webpackChunkName: "AudioPlayerUpload" */'@/components/AudioPlayerUpload'),
    UploadStatus: () => import(/* webpackChunkName: "UploadStatus" */'@/components/upload/UploadStatus')
  },
  props: {
    podcastListLength: {
      type: Number,
      default: 0
    },
    // user information here
    user: {
      type: Object,
      default: function () {
        return {}
      }
    },
    minimal: {
      type: Boolean,
      default: false
    },
    videoOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showSupportedFiles: false,
      folder: '',
      folderFiles: [],
      cancelItemId: '',
      mergedTrack: false,
      action: 'play',
      audioStatus: 'Play',
      podId: uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1'),
      step: 1,
      selectedMenu: 'normal',
      menuOptions: {
        'normal': 'Normal',
        'folder': 'Folder',
        'multi': 'Multi-Track'
      },
      status: '',
      url: '',
      urlTitle: '',
      urlValid: false, // can be [true, false, url]
      uploadState: {
        200: 'Great! The link has been added to the queue.',
        201: "Insufficient minutes! You have <span class='whiteText'>21 minutes remaining</span> and this audio is <span class='whiteText'>45 minutes</span>. <a class='underline cursor-pointer whiteText'>Buy more minutes?</a>", // reduce balance to 12 & add this: https://www.youtube.com/watch?v=X7iRe8O8Jsk
        202: `File added ${this.isOnboarding ? 'successfully! Taking you to your Spext Drive shortly...' : ''}`,
        500: 'Unknown Error. Please try later.',
        501: "File is too large to upload. We support audio files upto 1GB and video files upto 2 GB. <a class='underline whiteText' href='mailto:hello@spext.co?Subject=Enterprise%20plan' target='_top'>Contact us for the Enterprise plan!</a>", //
        502: "Hmm, looks like we don’t support that format. Try a Youtube video or a link that ends with .mp3, .mp4 etc. <a class='underline whiteText'>Try Again?</a>", // http://techslides.com/demos/samples/sample.swf
        503: 'Problem in accessing the link', // google.com
        504: 'Can not determine File size', //
        600: 'Hmm, this seems to be a duplicate link. Try a different one.', // Take a link from queue
        601: 'A file with the same title exists already. Please try a different title.',
        602: 'A Title is required to upload', // /Not required now
        603: 'Unknown Error. Please try later.',
        604: 'Cant connect to server.'
      },
      errorCode: {
        500: 'We were not able to import your file. Please try again or upload a new file.'
      },
      successCode: {
        200: 'File Added'
      },
      duration: 0,
      source: '',
      file: {
        name: ''
      },
      languages: [
        { code: { t: 'en-US' }, simplename: { t: 'English (United States)' } }
      ],
      targetLanguage: 'en-US',
      engines: ['Google', 'Watson', 'Mix'],
      targetEngine: 'Google',
      files: {
        '1': '',
        '2': '',
        '3': ''
      },
      sounds: [],
      sound: '',
      trackids: [],
      removedLanguages: ['marathi', 'tamil', 'telugu', 'urdu', 'malay', 'bengali', 'gujarati', 'kannada', 'malayalam']
    }
  },
  computed: {
    ...{
      billing () {
        return store.state.app.userInfo.billing
      },
      balance () {
        return store.state.app.userInfo.billing.seconds
      },
      insufficientBalance () {
      // eslint-disable-next-line eqeqeq,no-mixed-operators
        return ((this.balance == 0) || (this.duration > this.balance) && !this.billing.trial)
      },
      totalDuration: function () {
        var vm = this
        var number = 0
        for (var i = 0; i < vm.folderFiles.length; i++) {
          if (vm.folderFiles[i].valid === 2) {
            number += vm.folderFiles[i].duration
          }
        }
        return number
      },
      supported: function () {
        var vm = this
        var number = 0
        for (var i = 0; i < vm.folderFiles.length; i++) {
          if (vm.folderFiles[i].valid === 2) number += 1
        }
        return number
      },
      tracks: function () {
        var number = 0
        if (this.files['1'] !== '') {
          number += 1
        }
        if (this.files['2'] !== '') {
          number += 1
        }
        if (this.files['3'] !== '') {
          number += 1
        }
        return number
      },
      dirty: function () {
        var number = 0
        var vm = this
        if (vm.$refs.uploadItem1 && vm.$refs.uploadItem1.task.state_ !== undefined) {
          number += 1
        }
        if (vm.$refs.uploadItem2 && vm.$refs.uploadItem2.task.state_ !== undefined) {
          number += 1
        }
        if (vm.$refs.uploadItem3 && vm.$refs.uploadItem3.task.state_ !== undefined) {
          number += 1
        }
        if (vm.url !== '') {
          number += 1
        }
        if (vm.file !== '') {
          number += 1
        }
        return number
      },
      isOnboarding() {
        return window.location.pathname === '/onboarding'
      },
      ...mapGetters({
        userOnboardingState: 'app/onboardingState'
      })
    }
  },
  mounted () {
    this.getSupportedLanguages()
  },
  methods: {
    removeFile (index) {
      var vm = this
      vm.folderFiles.splice(index, 1)
    },
    uploadFolder () {
      var vm = this
      vm.urlValid = 'progressing'
      for (var i = 0; i < vm.folderFiles.length; i++) {
        console.log(vm.folderFiles[i].file.name)
        if (vm.folderFiles[i].valid === 2) {
          vm.$emit('fileAdded', vm.folderFiles[i].file, vm.folderFiles[i].file.name, this.targetLanguage, this.targetEngine, this.speakerCount)
        }
      }
      setTimeout(function () {
        vm.clear()
        vm.close()
        // vm.status = 200
      }, 1000)
    },
    cancelUpload () {
      console.log('cancelign')
      var vm = this
      if (vm.cancelItemId === '1') {
        vm.$refs.uploadItem1.cancelUpload()
      } else if (vm.cancelItemId === '2') {
        vm.$refs.uploadItem2.cancelUpload()
      } else if (vm.cancelItemId === '3') {
        vm.$refs.uploadItem3.cancelUpload()
      }
      vm.cancelItemId = ''
    },
    changeMenu (menu) {
      console.log('menu change', menu)
      var vm = this
      if (vm.dirty > 0 && menu === 'normal') {
        vm.status = 607
        return
      }
      if (vm.dirty > 0 && menu === 'multi') {
        vm.status = 606
        return
      }
      vm.clear()
      vm.selectedMenu = menu
      console.log('changed to', menu)
    },
    mergeTracks () {
      var vm = this
      if (vm.mergedTrack) {
        return
      }
      vm.trackids = []
      for (var key in vm.files) {
        if (vm.files[key] !== '') {
          vm.trackids.push(vm.files[key])
        }
      }
      var data = {
        'userid': vm.user.uid,
        'podid': vm.podId,
        'trackids': vm.trackids.join('|')
      }
      console.log('merging tracks', vm.trackids.join('|'), data)
      axios(
        {
          method: 'POST',
          url: CONSTANTS.MERGE_TRACKS,
          data: data
        }).then(function (res) {
        // vm.mergedTrack = true
        // vm.url = res.data
        // vm.music.url = res.data
        // vm.sound = new Howl({
        //   src: [vm.url],
        //   preload: false,
        //   onplay: function () {
        //     console.log('playing')
        //     vm.audioStatus = 'Pause'
        //   },
        //   onload: function () {
        //     console.log('loaded')
        //     vm.playPauseAudio()
        //     vm.action = 'play'
        //   },
        //   onend: function () {
        //     console.log('ended playing')
        //     vm.audioStatus = 'Play'
        //   },
        //   onpause: function () {
        //     console.log('paused')
        //     vm.audioStatus = 'Play'
        //   },
        //   onstop: function () {
        //     console.log('stopped')
        //     vm.audioStatus = 'Play'
        //   },
        //   onplayerror: function () {
        //     console.log('play error')
        //     vm.audioStatus = 'Play'
        //   },
        //   onloaderror: function () {
        //     console.log('load error')
        //     vm.audioStatus = 'Play'
        //   }
        // })
        console.log('res is', res)
      }).catch(function (err) {
        console.log(err)
      })
    },
    mergeTracks2 () {
      // code not being used
      var vm = this
      vm.sounds = []
      if (vm.$refs.uploadItem1.task.state_ === 'success') {
        var sound1 = new Howl({
          src: [vm.$refs.uploadItem1.task.snapshot.downloadURL],
          preload: true
        })
        vm.sounds.push(sound1)
      }
      if (vm.$refs.uploadItem2.task.state_ === 'success') {
        var sound2 = new Howl({
          src: [vm.$refs.uploadItem2.task.snapshot.downloadURL],
          preload: true
        })
        vm.sounds.push(sound2)
      }
      if (vm.$refs.uploadItem3.task.state_ === 'success') {
        var sound3 = new Howl({
          src: [vm.$refs.uploadItem3.task.snapshot.downloadURL],
          preload: true
        })
        vm.sounds.push(sound3)
      }
      // vm.mergedTrack = true
    },
    clearSound () {
      if (this.sound !== '') {
        this.sound.unload()
        this.sound = ''
      }
    },
    stopSound () {
      // var vm = this
      // vm.sound.stop()
    },
    playPauseAudio () {
      var vm = this
      if (vm.sound.state() === 'unloaded') {
        vm.sound.load()
        vm.action = 'loading'
        return
      }

      if (vm.sound.playing()) {
        vm.sound.pause()
      } else {
        vm.sound.play()
      }
    },
    playPauseAudio2 () {
      var vm = this
      for (var i = 0; i < vm.sounds.length; i++) {
        if (vm.sounds[i].state() === 'unloaded') {
          return
        }
      }
      if (vm.sounds[0].playing()) {
        for (i = 0; i < vm.sounds.length; i++) {
          vm.sounds[i].pause()
        }
      } else {
        for (i = 0; i < vm.sounds.length; i++) {
          vm.sounds[i].play()
        }
      }
    },
    checksBeforeUpload () {
      var vm = this
      if (vm.$parent.titleExists(vm.urlTitle.trim())) {
        vm.status = 601
        return false
      }
      return true
    },
    uploadMulti () {
      var vm = this
      if (!vm.checksBeforeUpload()) {
        return
      }
      console.log('targetEngine is', vm.targetEngine)
      let trial = !!vm.billing.trial
      firebase.database().ref(vm.user.uid + '/podcast-info/' + vm.podId).update({
        url: vm.url,
        tracks: vm.trackids,
        title: vm.urlTitle,
        language: vm.targetLanguage,
        engine: vm.targetEngine,
        created: Date().toString(),
        updated: Date().toString(),
        status: {
          code: 100,
          msg: 'waiting'
        },
        log: {
          1: {
            status: 'waiting',
            timestamp: Date().toString()
          }
        },
        trial: trial,
        index: vm.podcastListLength
      })
        .then(function () {
          console.log('New podcast created.')
          vm.clear()
          vm.status = 200
        })
    },
    addToUploadList (file, id) {
      console.log('file checked', file, id)
    },
    humanLength (timeInSec) {
      return humanLength(timeInSec)
    },
    getSupportedLanguages () {
      var vm = this
      firebase.database().ref('languages').once('value').then(function (snapshot) {
        if (!snapshot.exists()) {
          vm.languages = []
        } else {
          snapshot.val().forEach(function (item) {
            let isRemovedLanguage = vm.removedLanguages.some(function (language) {
              return item.simplename.t.toLowerCase().startsWith(language.toLowerCase())
            })
            if (isRemovedLanguage) {
              return
            }

            // change code of all english languages to 'en-US' because it is the best model
            // if (item.simplename.t.indexOf('English') >= 0) {
            //   item.code.t = 'en-US'
            // }
            vm.languages.push(item)
          })
        }
      })
    },
    openDropbox () {
      var vm = this
      var options = {
        success: function (files) {
          console.log("Here's the file link: " + files[0].link)
          vm.url = files[0].link
          vm.checkURLNow(files[0].name)
        },
        linkType: 'direct',
        extensions: ['audio', 'video']
      }
      window.Dropbox.choose(options)
    },
    openFileDialog () {
      console.log('here')
      $('#uploadFile').click()
    },
    openFolderDialog () {
      console.log('here')
      $('#uploadFolder').click()
    },
    durationCheck (type) {
      // This method doesnt not work for every url(avi, mpg and not audio/video links). which is fine
      // becuase we are still able to remove othrs
      var vm = this
      vm.urlValid = vm.url
      return new Promise((resolve, reject) => {
        vm.duration = 0
        var video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = function () {
        //   window.URL.revokeObjectURL(this.src)
          vm.duration = Math.ceil(video.duration)
          console.log(this.src)
          console.log(video.duration)
          if ((video.duration < vm.balance) || (vm.balance > 0 && vm.billing.trial)) {
            resolve('Ok')
          }
          reject(new Error('upload err'))
        }
        video.onerror = function () {
          window.URL.revokeObjectURL(this.src)
          console.log('err:' + this.src)
          resolve('Ok')
        }
        if (type === 'file') {
          console.log('upload file duration check')
          video.src = URL.createObjectURL(vm.file)
        } else {
          console.log('url duration check')
          video.src = vm.url
        }
      })
    },
    checkFile (e) {
      e.preventDefault()
      var vm = this
      if (this.billing.seconds <= 0) {
        this.status = 201
        return
      }
      this.file = e.target.files[0]
      if ((((e.target.files[0].size / 1024) / 1024) / 1024) > 4) {
        this.status = 501
        return
      }
      this.durationCheck('file').then(function () {
        vm.urlValid = true
        vm.urlTitle = vm.file.name
        setTimeout(function () {
          $('.ui.upload.modal').modal('refresh')
        }, 100)
      }).catch(function (err) {
        console.log('duration not valid;file', err)
      })
    },
    checkFolderFile (index) {
      var vm = this
      var video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(this.src)
        vm.folderFiles[index].duration = Math.ceil(video.duration)
        vm.folderFiles[index].valid += 1
        console.log(this.src)
        console.log(video)
      }
      if ((((vm.folderFiles[index].file.size / 1024) / 1024) / 1024) <= 1) {
        vm.folderFiles[index].valid += 1
      }
      video.onerror = function () {
        window.URL.revokeObjectURL(this.src)
        console.log('err:' + this.src)
      }
      video.src = URL.createObjectURL(vm.folderFiles[index].file)
    },
    checkFolder (e) {
      e.preventDefault()
      console.log(e)
      console.log('filename', $('input[type=file]').val().replace(/C:\\fakepath\\/i, ''))
      var vm = this
      vm.folder = e.target.files
      // console.log(JSON.stringify(vm.folder))
      Array.prototype.forEach.call(vm.folder, function (file) {
        var temp = {}
        temp.file = file
        temp.valid = 0
        vm.folderFiles.push(temp)
        vm.checkFolderFile(vm.folderFiles.length - 1)
      })
      vm.urlValid = true
    },
    clear () {
      var vm = this
      this.url = ''
      this.file = ''
      this.urlValid = false
      this.urlTitle = ''
      this.status = ''
      this.duration = 0
      if ($('#uploadFile')[0] !== undefined) {
        $('#uploadFile')[0].value = ''
      }
      if (this.$refs.uploadItem1) this.$refs.uploadItem1.clear()
      if (this.$refs.uploadItem2) this.$refs.uploadItem2.clear()
      if (this.$refs.uploadItem3) this.$refs.uploadItem3.clear()
      this.step = 1
      this.selectedMenu = 'normal'
      vm.clearSound()
      this.podId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      this.mergedTrack = false
      vm.folder = ''
      vm.folderFiles = []
      if ($('#uploadFolder')[0] !== undefined) {
        console.log('cleared folder')
        $('#uploadFolder')[0].value = ''
      }
      vm.showSupportedFiles = false
    },
    upload () {
      if (this.$parent.titleExists(this.urlTitle.trim())) {
        this.status = 601
        return
      }
      if (this.file !== '') {
        // file
        this.uploadFile()
      } else {
        // link
        this.addPodcast()
      }
    },
    uploadFile () {
      var vm = this
      console.log('uploadFileQ')
      if (vm.$parent.offline) {
        vm.status = 700
        return
      }
      vm.urlValid = 'progressing'
      this.$emit('fileAdded', this.file, this.urlTitle, this.targetLanguage, this.targetEngine)
      vm.status = 202
      setTimeout(function () {
        vm.clear()
        vm.close()
      }, 2000)
    },
    close (redirect = false) {
      if (typeof redirect !== 'boolean') redirect = false
      if (this.insufficientBalance) {
        this.status = ''
        return this.$parent.closeUploadModal()
      }
      if (this.dirty > 0) this.status = '605'
      else {
        this.status = ''
        this.$parent.closeUploadModal(redirect ? '/drive' : null)
      }
    },
    checkQueue () {
      console.log('check queue')
      this.status = ''
      this.$parent.closeUploadModal()
      store.dispatch('modal/showQueueModal')
    },
    buyMinutes () {
      this.status = ''
      this.$parent.closeUploadModal()
      store.dispatch('dialogs/openModal', { name: 'AccountInfo', props: { tab: 'Top Up' } })
    },
    tryAgain () {
      console.log('try again')
    },
    checkURLNow (title) {
      console.log('check url')
      this.urlTitle = ''
      if (this.url === '') {
        // Test here
        // this.status=200
        this.urlValid = false
        return
      }
      if (this.billing.seconds <= 0) {
        this.status = 201
        return
      }
      if (this.urlValid === this.url) {
        console.log('same url entered, so returning')
        return
      } else {
        if (this.source !== '') {
          this.source.cancel('New url entered so canceling this one.')
        }
      }
      var vm = this
      this.durationCheck('url').then(function (response) {
        console.log(response)
        var CancelToken = axios.CancelToken
        vm.source = CancelToken.source()
        return axios(
          {
            method: 'post',
            url: CONSTANTS.IS_VALID,
            data: {
              'userid': vm.user.uid,
              'link': vm.url
            },
            cancelToken: vm.source.token
          })
      }).then(function (response) {
        var data = response['data']
        console.log(data)
        if (data['status'] === 200) {
          if (title !== undefined) {
            vm.urlTitle = title
          } else {
            vm.urlTitle = data['title']
          }
          vm.urlValid = true
          setTimeout(function () {
            $('.ui.upload.modal').modal('refresh')
          }, 100)
        } else if (data['status'] === 201) {
          vm.duration = Math.ceil(data['duration'])
          // vm.urlValid=false
          // vm.status=data['status']
        } else if (vm.uploadState[data['status']] !== '') {
          vm.urlValid = false
          vm.status = data['status']
        } else {
          vm.urlValid = false
          vm.status = 603
        }
      }).catch(function (err) {
        console.log('duration not ok;url')
        vm.urlValid = false
        vm.status = 604
        console.log(err)
        // return
      })
    },
    checkURL: debounce(
      function () {
        this.checkURLNow()
      }, 1500
    ),
    addPodcast () {
      var vm = this
      if (vm.url === '') {
        vm.clear()
        return
      }
      console.log('targetEngine is', vm.targetEngine)
      let trial = !!vm.billing.trial
      if (['project', 'audiogram'].includes(vm.$route.name)) store.commit('app/setProjectDocId', { title: vm.urlTitle, uid: vm.podId })
      firebase.database().ref(vm.user.uid + '/podcast-info/' + vm.podId).set({
        url: vm.url,
        title: vm.urlTitle,
        language: vm.targetLanguage,
        engine: vm.targetEngine,
        created: Date().toString(),
        updated: Date().toString(),
        status: {
          code: 100,
          msg: 'waiting'
        },
        log: {
          1: {
            status: 'waiting',
            timestamp: Date().toString()
          }
        },
        trial: trial,
        index: vm.podcastListLength,
        opened: Date.now()
      })
        .then(function () {
          console.log('New podcast created.')
          vm.clear()
          // TODO: show modal toast
          vm.status = 202
          vm.$emit('notifyFileAdded', vm.podId)
          console.log('adclkjasndc', vm.isOnboarding, vm.userOnboardingState)
          if (vm.userOnboardingState === '1') {
            setOnboardingState(vm.user.uid, '2')
          }
          setTimeout(() => {
            if (['project', 'audiogram'].includes(vm.$route.name)) store.commit('app/setProjectDocId', null)
          }, 200)
          setTimeout(() => {
            vm.close(vm.$route.name === 'onboarding')
          }, 2000)
          // vm.status = 200
        })
    },
    clearStatus () {
      this.status = ''
    },
    clearCancelId () {
      this.cancelItemId = ''
    },
    setShowSupportedFiles (payload) {
      this.showSupportedFiles = payload
    },
    checkUrlTitle (str) {
      if (!str.replace(/\s/g, '').length) {
        return false
      }
      return true
    }
  }
}
</script>
