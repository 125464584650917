import { Brandkit, Clip, ClipStoreState, ExportedClip, PreviewClip } from '@/types/clipperTypes'
import Clipper from '@/view/voiceEditor/proseEditor/workflows/clipper'
import { Module } from 'vuex'
import store from '..'

const getDefaultState = (): ClipStoreState => ({
  clips: [],
  exportedClips: [],
  hasEdited: false,
  exportStage: 1,
  useBrandkit: false,
  brandkit: null,
  clipper: null,
  selectedClip: null,
  previewClips: [],
  loadingPreviewClips: false,
  exportLoading: false,
  exportClip: null
})

/**
 * This module is used only for data transmission. No relevant logic is/should be written here.
 * We're essentially using vuex instead of setting up our own event bus.
 */
const module: Module<ClipStoreState, any> = {
  namespaced: true,
  state: getDefaultState,
  getters: {
    clips: state => state.clips,
    exportedClips: state => state.exportedClips,
    hasEdited: state => state.hasEdited,
    exportStage: state => state.exportStage,
    useBrandkit: state => state.useBrandkit,
    brandkit: state => state.brandkit,
    clipper: state => state.clipper,
    selectedClip: state => state.selectedClip,
    previewClips: state => state.previewClips,
    loadingPreviewClips: state => state.loadingPreviewClips,
    exportLoading: state => state.exportLoading,
    exportClip: state => state.exportClip
  },
  mutations: {
    setClips: function(state, payload: Clip[]) {
      if (!payload) return
      state.clips = [...payload]
    },
    setExportedClips: function(state, payload: ExportedClip[]) {
      if (!payload) return
      state.exportedClips = [...payload]
    },
    setHasEdited: function(state, payload: boolean) {
      if (!payload) return
      state.hasEdited = payload
    },
    setExportStage: function(state, payload: 1 | 2 | 3) {
      if (!payload) return
      state.exportStage = payload
    },
    setUseBrandkit: function(state, payload: boolean) {
      state.useBrandkit = payload
    },
    setBrandkit: function(state, payload: Brandkit) {
      state.brandkit = payload
    },
    setClipper: function(state, payload: Clipper) {
      state.clipper = payload
    },
    setSelectedClip: function(state, payload: Clip | PreviewClip) {
      state.selectedClip = payload
    },
    setPreviewClips: function(state, payload: PreviewClip[]) {
      if (!payload) return
      state.previewClips = [...payload]
    },
    setLoadingPreviewClips: function(state, payload: boolean) {
      state.loadingPreviewClips = payload
    },
    setExportLoading: function(state, payload: boolean) {
      state.exportLoading = payload
    },
    setExportClip: function(state, payload: ExportedClip) {
      state.exportClip = payload
    },
    setExportClipPlaying: function(state, payload: boolean) {
      if (!state.exportClip) return
      state.exportClip.playing = payload
    },
    appendExportedClips: function(state, payload: ExportedClip[]) {
      if (!payload) return
      state.exportedClips = [...payload, ...state.exportedClips]
    }
  },
  actions: {
    /**
     * This funciton should be the only way of opening the export modal.
     * Opening it any other way may cause unwanted things being
     * displayed on the modal.
     */
    openExportModal: function({ commit }, hasEdited = false) {
      commit('setHasEdited', hasEdited)
      if (!hasEdited) {
        commit('setExportStage', 2)
      }
      store.dispatch('dialogs/openModal', { name: 'ClipperExportModal' })
    },
    /**
     * Creates preview clips that are shown when brandkit is being applies
     */
    makePreviewClips: async function({ commit, state }) {
      commit('setLoadingPreviewClips', true)
      const clips = await state.clipper?.makePreviewClips()
      if (!clips) return
      commit('setPreviewClips', clips)
      commit('setSelectedClip', clips[0])
      commit('setLoadingPreviewClips', false)
    },
    /**
     * Begins clip exports
     */
    beginExport: async function({ commit, state }, useBrandkit) {
      commit('setExportLoading', true)
      await state.clipper?.setUseBrandkit(useBrandkit)
      commit('setUseBrandkit', useBrandkit)
      await state.clipper?.makeClips()
      state.clipper?.resetDocState()
      commit('setExportLoading', false)
      commit('setExportStage', 1)
      store.dispatch('dialogs/closeModal')
    },
    appendExportedClips: async function({ commit, state }, exportedClips: ExportedClip[]) {
      const clipsToAppend = exportedClips.filter(clip => {
        const ind = state.exportedClips.findIndex(el => el.uid === clip.uid)
        return ind === -1
      })

      commit('appendExportedClips', [...clipsToAppend])
    },
    setExportClip: async function ({ commit }, clip: ExportedClip) {
      if (store.state.editor.isAudioPlaying) {
        store.dispatch('editor/toggleState')
      }
      commit('setExportClip', clip)
    },
    onUmount: function({ commit, state }) {
      state.clipper?.stopListeningToProject()
      commit('setClips', [])
      commit('setExportedClips', [])
      commit('setHasEdited', false)
      commit('setExportStage', 1)
      commit('setBrandkit', null)
      commit('setUseBrandkit', false)
      commit('setClipper', null)
      commit('setSelectedClip', null)
      commit('setPreviewClips', null)
      commit('setLoadingPreviewClips', false)
      commit('setExportLoading', false)
      commit('setExportClip', null)
    }
  }
}

export default module
