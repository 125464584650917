const defaultState = () => ({
  currentSpeed: 1
})
export default {
  namespaced: true,
  state: defaultState,
  getters: {
    currentSpeed: state => state.currentSpeed
  },
  mutations: {
    setSpeed (state, payload) {
      state.currentSpeed = payload
    }
  }
}
