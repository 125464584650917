import uuidv1 from 'uuid/v1'

export const imageMark = {
  attrs: {
    class: { default: 'imageMark' },
    image_mark_uid: { default: uuidv1() },
    source: 0,
    position: { default: {
      top: 0,
      right: 0,
      zindex: 0
    } }

  },
  parseDOM: [
    {
      tag: 'span.note[data-image_mark_uid]',
      getAttrs (dom) {
        return {
          image_mark_uid: dom.dataset.image_mark_uid
        }
      }
    }
  ],
  toDOM: function (mark) {
    return ['span', {
      class: 'imageMark',
      'data-image_mark_uid': mark.attrs.image_mark_uid ? mark.attrs.image_mark_uid : 'imageMarkUid'
    }]
  }
}
