import store from '@/services/store'

export const defaultState = () => ({
  uid: '',
  asource: '',
  deleted: false,
  location: [2000, 100000],
  // audio meta
  updateCount: 1,
  audioTimeMs: [2000, 100000], // in ms
  audioText: '----',

  // music meta
  name: '',
  totalMs: 10000,
  // editable
  fromMs: 1000,
  toMs: 3000,
  fadeInMs: 150,
  fadeOutMs: 150,
  volume: 60
})
export default {
  namespaced: true,
  state: defaultState,
  getters: {
    blockmodeConfig: state => ({
      uid: state.uid,
      asource: state.asource,
      location: state.location,
      audioTimeMs: state.audioTimeMs,
      audioText: state.audioText,
      plainAudioText: state.plainAudioText,
      name: state.name,
      totalMs: state.totalMs,
      fromMs: state.fromMs,
      toMs: state.toMs,
      fadeInMs: state.fadeInMs,
      fadeOutMs: state.fadeOutMs,
      volume: state.volume,
      updateCount: state.updateCount
    }),
    updateCount: state => state.updateCount,
    musicDeleted: state => state.deleted
  },
  mutations: {
    update: function(state, update) {
      console.table(update)
      Object.keys(update).forEach(key => {
        state[key] = update[key]
      })
    },
    open: function() {
      if (store.state.clipper.clips.length) {
        return
      }
      store.dispatch('editor/togglePanel', { panel: 'blockmode', forceShow: true })
    },
    close: function(state, closePanel) {
      if (closePanel === 'yes') store.dispatch('editor/togglePanel', { panel: 'blockmode', forceHide: true })
      Object.assign(state, {
        ...defaultState(),
        updateCount: state.updateCount
      })
    },
    deleteMusic: function(state) {
      state.deleted = true
    },
    incrementUpdateCount: function(state) {
      state.updateCount += 1
    }
  },
  actions: {
    open: function({ commit }, payload) {
      if (payload.name && payload.totalMs) {
        commit('update', rest)
        commit('open')
      }
    },
    close: function ({ commit }, closePanel = 'yes') {
      commit('close', closePanel) // close & reset
    },
    update: function ({ commit }, update) {
      if (update.blockmodeUpdate) commit('incrementUpdateCount')
      commit('update', update)
    },
    deleteMusic: function ({ commit }) {
      commit('deleteMusic')
      setTimeout(() => {
        commit('close', 'yes')
      }, 100)
    }
  }
}
