const getDefaultState = () => {
  return {
    newComment: {
      id: '',
      pos: ''
    },
    commentThreadsAndPositions: {}
  }
}

export default {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    setCommentThreadsAndPositions (state, payload) {
      state.commentThreadsAndPositions = payload
    },
    reset (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
