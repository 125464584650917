// inline referring to a single word
// it will come next to the previous inline element
// we need 0 or more inline elementes
// we group these inline elements as "block"
export const paragraph = {
  content: 'inline*',
  group: 'block',
  attrs: {
    labelName: { default: '' }, // every para has labels at the left with color
    labelColor: { default: '' }
  },
  // when we copy from html it will use p element to pick
  // if encounter a p element deal it like a paragraph we defined above
  parseDOM: [{ tag: 'p' }],

  // when you paste it -> it become a p tag element in html
  toDOM (node) { return ['p', node.attrs, 0] }
}
