import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export function getUploadedMedia (uid, callback) {
  firebase.database().ref(uid + '/uploaded_media').on('value', function (res) {
    callback(res.val())
  })
}

export function deleteUploadedMedia (userId, soundId) {
  return firebase.database().ref(userId + '/uploaded_media/' + soundId + '/deleted').set(true)
}
