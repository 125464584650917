<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="15"
    viewBox="0 0 17 15"
    :class="classname"
  >
    <path d="M15.9375 6.42934H3.33625L7.19313 1.75833C7.37347 1.53954 7.46024 1.25748 7.43433 0.974193C7.40843 0.690905 7.27198 0.429595 7.055 0.247748C6.83802 0.0659016 6.55828 -0.0215855 6.27733 0.00453334C5.99638 0.0306521 5.73722 0.168237 5.55687 0.387021L0.244375 6.81502C0.208633 6.86615 0.176672 6.91986 0.14875 6.97572C0.14875 7.02928 0.14875 7.06142 0.0743752 7.11499C0.0262158 7.23783 0.000999941 7.36859 0 7.50067C0.000999941 7.63275 0.0262158 7.76351 0.0743752 7.88635C0.0743752 7.93992 0.0743749 7.97206 0.14875 8.02562C0.176672 8.08148 0.208633 8.13519 0.244375 8.18632L5.55687 14.6143C5.65677 14.7353 5.78187 14.8325 5.92327 14.8992C6.06468 14.9658 6.21891 15.0003 6.375 15C6.62326 15.0005 6.86384 14.9133 7.055 14.7536C7.16259 14.6637 7.25152 14.5532 7.31671 14.4286C7.38189 14.3039 7.42205 14.1675 7.43488 14.0272C7.44771 13.8869 7.43296 13.7454 7.39148 13.6108C7.34999 13.4763 7.28259 13.3513 7.19313 13.243L3.33625 8.572H15.9375C16.2193 8.572 16.4895 8.45913 16.6888 8.25822C16.8881 8.0573 17 7.7848 17 7.50067C17 7.21653 16.8881 6.94404 16.6888 6.74312C16.4895 6.54221 16.2193 6.42934 15.9375 6.42934Z"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowBack',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
