import debounce from 'lodash/debounce'
import { getEditMediaDontAsk } from '@/components/common/browserStorageUtility'
import store from '@/services/store'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
const getDefaultState = () => {
  return {
    editorBlocked: {
      value: false,
      reason: ''
    },
    switching: false,
    addActionPopoverConfig: {
      closeOnClickOutside: true,
      isPositionFixed: false,
      show: false,
      style: {},
      transition: 'bounce-in-left'
    },
    audioControl: null,
    // for demo, remove it fully
    currentPara: 0,
    currentTime: 0,
    dirty: '',
    dirtyMessage: 0,
    editMode: 'editMedia',
    isTranscriptMode: false,
    freshDoc: false,
    isAudioControlReady: false,
    isAudioPlaying: false,
    isDemo: false,
    isProseEditorInitialised: false,
    labelPopoverConfig: {
      closeOnClickOutside: true,
      isPositionFixed: false,
      show: false,
      style: {},
      transition: 'bounce-in-top'
    },
    masterTranscriptVolume: 0,
    noOfMediaMissing: 0,
    ownerId: '',
    playerLoading: 0,
    playerLoadProgress: 0,
    podcastInfo: {},
    reduceNoise: true,
    savingStep: false,
    showCopied: false,
    showCut: false,
    showDeleted: true,
    // TODO: move panel states to dialog module
    defaultPanel: 'shortcuts',
    panelToShow: 'shortcuts',
    steps: {
      filler: {
        completed: false,
        show: false,
        text: 'Select & delete the "uh"'
      },
      playNow: {
        completed: false,
        show: false,
        text: 'Click play to hear the cut'
      },
      reduceNoise: {
        completed: false,
        show: false,
        text: 'Toggle noise reduction'
      }
    },
    textAddAttempt: 0,
    timeDeleted: 0,
    timeEdited: 0,
    title: '',
    docVersion: 1,
    totalTime: 0,
    sourcesReady: false,
    sourcesLoading: false,
    docEndedAt: null,
    docProgress: 1,
    splitVideoPaths: null,
    isPlayerGettingReady: false
  }
}
export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    switching: state => state.switching,
    isEditorBlocked: state => state.editorBlocked.value,
    editorBlockReason: state => state.editorBlocked.reason,
    checkPoint1: state => state.audioControl === null ? false : state.audioControl.checkPoint1, // for demo
    checkPoint2: state => state.audioControl === null ? false : state.audioControl.checkPoint2, // for demo
    checkPoint3: state => state.audioControl === null ? false : state.audioControl.checkPoint3, // for demo
    dirty: state => state.dirty,
    dirtyMessage: state => state.dirtyMessage,
    editMode: state => state.editMode,
    isTranscriptMode: state => state.isTranscriptMode,
    freshDoc: state => state.freshDoc,
    isDemo: state => state.isDemo,
    isPlaying: state => state.isAudioPlaying,
    isProseEditorInitialised: state => state.isProseEditorInitialised,
    masterTranscriptVolume: state => state.masterTranscriptVolume,
    mediaMissing: state => state.noOfMediaMissing,
    playerLoading: state => state.playerLoading,
    playerLoadProgress: state => state.playerLoadProgress,
    playingComplete: state => state.audioControl === null ? false : state.audioControl.playingComplete, // for demo
    podcastInfo: state => state.podcastInfo,
    podcastInfoExists: state => Object.keys(state.podcastInfo).length > 0,
    proseEditor: state => state.proseEditor,
    reduceNoise: state => state.reduceNoise,
    savingStep: state => state.savingStep,
    showCopied: state => state.showCopied,
    showCut: state => state.showCut,
    showDeleted: state => state.showDeleted,
    steps: state => state.steps,
    textAddAttempt: state => state.textAddAttempt,
    timeDeleted: state => state.timeDeleted,
    timeEdited: state => state.timeEdited,
    totalTime: state => state.totalTime,
    panelToShow: state => state.panelToShow,
    defaultPanel: state => state.defaultPanel,
    threadCount: state => state.podcastInfo.count && state.podcastInfo.count.threads ? state.podcastInfo.count.threads : 0,
    isBlockmodeOpen: state => state.panelToShow === 'blockmode',
    sourcesReady: state => state.sourcesReady,
    sourcesLoading: state => state.sourcesLoading,
    docEndedAt: state => state.docEndedAt,
    docProgress: state => state.docProgress,
    splitVideoPaths: state => state.splitVideoPaths,
    isPlayerGettingReady: state => state.isPlayerGettingReady
  },
  mutations: {
    setDocProgress: function(state, payload) {
      const newProgress = state.docProgress + payload
      state.docProgress = newProgress >= 99 ? 99 : newProgress
    },
    resetDocProgress: function(state) {
      state.docProgress = 1
    },
    setDocEndedAt: function(state, payload) {
      state.docEndedAt = payload
    },
    closeLabelPopover: function(state) {
      state.labelPopoverConfig = {
        ...state.labelPopoverConfig,
        show: false
      }
    },
    setEditorBlocked: function(state, payload) {
      if (payload.value === true) state.editorBlocked = payload
      else {
        state.editorBlocked = {
          value: false,
          reason: ''
        }
      }
    },
    openActionPopover: function(state) {
      state.addActionPopoverConfig.show = true
    },
    setNoOfMediaMissing(state, payload) {
      state.noOfMediaMissing = payload
    },
    setCurrentTime(state, payload) {
      state.currentTime = payload
    },
    setIsAudioPlaying(state, payload) {
      state.isAudioPlaying = payload
    },
    setMasterVolume(state, payload) {
      // console.log('setMasterVolume', payload)
      state.masterTranscriptVolume = payload
    },
    setPodcastInfo(state, payload) {
      state.podcastInfo = payload
    },
    setOwnerId(state, payload) {
      state.ownerId = payload
    },
    setPlayerLoadProgress(state, payload) {
      state.playerLoadProgress = payload
    },
    setShowCut(state, payload) {
      state.showCut = payload
    },
    setShowCopied(state, payload) {
      state.showCopied = payload
    },
    setTimeEdited(state, payload) {
      state.timeEdited = payload
    },
    setTimeDeleted(state, payload) {
      state.timeDeleted = payload || 0
    },
    setTitle(state, payload) {
      state.title = payload || ''
    },
    setDocVersion (state, payload) {
      state.docVersion = payload || 1
    },
    setIsPlayerGettingReady (state, payload) {
      state.isPlayerGettingReady = payload
    },
    setSplitVideoPaths(state, payload) {
      let handle = ''
      if (payload && payload[0] && payload[0].file) {
        handle = payload[0].file.split('/')[3].split('.mp4')[0].replace(/-\d+$/, '') + '#!@' + payload[0].file.split('/')[1]
      }
      if (!state.splitVideoPaths) {
        state.splitVideoPaths = {}
      }
      state.splitVideoPaths[handle] = payload || null
    },
    setTotalTime(state, payload) {
      state.totalTime = payload
    },
    setProseEditorInitialised(state) {
      state.isProseEditorInitialised = true
    },
    setAudioControl(state, payload) {
      state.audioControl = payload
    },
    setTextAddAttempt(state) {
      state.textAddAttempt += 1
    },
    setShowDeleted(state, payload) {
      state.showDeleted = payload
    },
    toggleShowDeleted(state) {
      state.showDeleted = !state.showDeleted
    },
    hideDeleted(state) {
      state.showDeleted = false
    },
    setCurrentPara(state, payload) {
      state.currentPara = payload
    },
    incrementPlayerLoading(state) {
      state.playerLoading += 1
    },
    startPlayer(state, computeSchedule = false) {
      myProseEditor.startAudioPlayer(computeSchedule)
    },
    stopPlayer() {
      myProseEditor.stopAudioPlayer()
    },
    setMode(state, payload) {
      state.editMode = payload
    },
    setTranscriptMode(state, payload) {
      state.isTranscriptMode = payload
    },
    setFreshDoc(state, payload) {
      state.freshDoc = payload
    },
    setDirty(state, payload) {
      console.log('saving dirty: ', payload)
      state.dirty = payload
      state.dirtyMessage = 0
    },
    setDirtyMessage(state, payload) {
      state.dirtyMessage = payload
    },
    setSavingStep(state, payload) {
      state.savingStep = payload
    },
    setIsDemo(state, payload) {
      state.isDemo = payload
    },
    setReduceNoise(state, payload) {
      state.reduceNoise = payload
      if (state.isDemo && state.steps.reduceNoise) {
        state.steps.reduceNoise.completed = true
      }
    },
    addToStep(state, payload) {
      if (payload === 'filler') {
        state.steps.filler.show = true
      } else if (payload === 'reduceNoise') {
        state.steps.reduceNoise.show = true
      } else if (payload === 'playNow') {
        state.steps.playNow.show = true
      }
    },
    setCompleted(state, payload) {
      if (payload === 'filler') {
        state.steps.filler.completed = true
      } else if (payload === 'reduceNoise') {
        state.steps.reduceNoise.completed = true
      } else if (payload === 'playNow') {
        state.steps.playNow.completed = true
      }
    },
    reset: state => {
      Object.assign(state, getDefaultState())
    },
    setDefaultPanel: function(state, payload) {
      state.defaultPanel = payload
      state.panelToShow = payload
    },
    setPanelToShow: function(state, payload) {
      state.panelToShow = payload
    },
    setSwitching: function(state, payload) {
      state.switching = payload
    },
    setSourcesReady: function(state, payload = true) {
      state.sourcesReady = payload
    },
    setSourcesLoading: function(state, payload = true) {
      state.sourcesLoading = payload
    }
  },
  actions: {
    setSwitching: function({ state, commit }, switching) {
      if (switching) {
        commit('setSwitching', switching)
        commit('setEditorBlocked', {
          value: true,
          reason: `Switching to ${
            state.editMode === 'editTranscript'
              ? 'edit transcript'
              : 'edit media'
          } mode...`
        })
      } else {
        commit('setSwitching', false)
        commit('setEditorBlocked', {
          value: false
        })
      }
    },
    setEditorBlocked: function({ commit }, payload) {
      commit('setEditorBlocked', payload)
    },
    togglePanel: function({ state, commit }, { panel, forceHide, forceShow }) {
      let newVal = ''
      if (!forceShow && state.panelToShow === panel) {
        newVal = state.defaultPanel
      } else newVal = panel
      if (forceHide) newVal = state.defaultPanel
      if (newVal !== state.panelToShow) commit('setPanelToShow', newVal)
    },
    setDefaultPanel: function({ commit }, payload) {
      commit('setDefaultPanel', payload)
    },
    toggleShowDeleted({ commit }) {
      commit('toggleShowDeleted')
    },
    hideDeleted({ commit }) {
      commit('hideDeleted')
    },
    toggleMode({ state, dispatch }, payload) {
      window.analytics.track('sidebar_editor_modeToggleClicked', { type: payload })
      // if (state.dirty) {
      //   state.dirtyMessage += 1
      //   // console.log('saving')
      //   return
      // }
      if (
        state.editMode === 'editMedia' &&
        payload === 'editTranscript' &&
        !getEditMediaDontAsk() &&
        localStorage['onBoardingEditTranscriptFirstPopover']
      ) {
        store.dispatch('dialogs/openModal', { name: 'EditMediaPrompt' })
      } else {
        state.editMode = payload
      }
    },
    triggerPlusButton() {
      if (!localStorage['onBoardingPlusButton']) {
        setTimeout(function() {
          window.Intercom('startTour', 58547)
        }, 500)
      }
      localStorage['onBoardingPlusButton'] = 'shown'
    },
    toggleState({ state, commit, dispatch }, computeSchedule = false) {
      if (state.playerLoadProgress < 1) {
        commit('incrementPlayerLoading')
        return
      }
      if (!state.isPlayerGettingReady) {
        if (!state.isAudioPlaying) {
          commit('startPlayer', computeSchedule)
        } else {
          if (state.isAudioPlaying) {
            commit('stopPlayer')
          }
          if (!state.isDemo && state.editMode === 'editMedia') dispatch('triggerPlusButton')
        }
      }
      if (state.isDemo && state.steps.playNow && state.steps.playNow.show) commit('setCompleted', 'playNow')
    },
    clearAllNotifications({ commit }) {
      commit('setShowCut', false)
      commit('setShowCopied', false)
      commit('setTimeEdited', 0)
    },
    notifyCopied({ dispatch, commit }) {
      dispatch('clearAllNotifications')
      commit('setShowCopied', true)
      dispatch('refreshCount2')
    },
    refreshCount2: debounce(function({ commit }) {
      commit('setShowCopied', false)
    }, 2500),
    notifyCut({ dispatch, commit }) {
      dispatch('clearAllNotifications')
      commit('setShowCut', true)
      dispatch('refreshCount3')
    },
    refreshCount3: debounce(function({ commit }) {
      commit('setShowCut', false)
    }, 2500),
    notifyEdit({ state, commit, dispatch }, time) {
      // var vm = this
      commit('setShowCut', false)
      commit('setShowCopied', false)
      commit(
        'setTimeEdited',
        (parseFloat(state.timeEdited / 1000) + time).toFixed(3) * 1000
      )
      dispatch('refreshCount1')
      if (state.isDemo && state.steps.filler) {
        commit('setCompleted', 'filler')
        commit('addToStep', 'playNow')
      }
    },
    refreshCount1: debounce(function({ commit }) {
      // console.log('refreshing')
      commit('setTimeEdited', 0)
    }, 2000)
  }
}
