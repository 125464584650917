











































































import Vue from 'vue'
import Modal from '@/components/base/dialogs/Modal.vue'
import Input from '@/components/base/inputs/Input.vue'
import Button from '@/components/base/buttons/Button.vue'
import VueToastr from 'vue-toastr'
import { mapGetters } from 'vuex'

type SlackReqData = Array<{
  title: string;
  value: string;
}>;

export default Vue.extend({
  name: 'EnterpriseForm',
  data: function () {
    return {
      iname: '',
      email: '',
      company: '',
      position: '',
      emailError: '',
      nameError: '',
      isSuccess: false
    }
  },
  computed: {
    ...mapGetters({
      modalName: 'dialogs/modalName',
      modalProps: 'dialogs/modalProps'
    })
  },
  components: {
    Modal,
    Input,
    Button,
    VueToastr
  },
  methods: {
    isEmailValid: (email: string): Boolean => {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },
    handleFormSubmit: function () {
      if (!this.iname) {
        this.nameError = 'Name is required'
        return
      }
      if (!this.email) {
        this.emailError = 'Email is required'
        return
      } else if (!this.isEmailValid(this.email)) {
        this.emailError = 'Invalid email'
        return
      } else {
        this.emailError = ''
      }
      const res: SlackReqData = [
        { title: 'name', value: this.iname },
        { title: 'email', value: this.email },
        { title: 'company', value: this.company },
        { title: 'position', value: this.position }
      ]

      const _info = {
        title: `Enterprise plan request by ${this.email}`,
        color: '#00FFFF',
        fields: res
      }

      const data = {
        text: '*Enterprise plan alert*',
        response_type: 'in_channel',
        attachments: [_info]
      }

      const cloudfnURL =
        'https://us-central1-dev-podcastpi.cloudfunctions.net/sendEnterpriseQueryToSlack'
      fetch(cloudfnURL, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data)
          this.isSuccess = true
        })
        .catch((err) => {
          console.error(err)
          let toasterRef = this.$refs.toastr as any
          toasterRef.Add({
            name: 'enterprise_error',
            title: '',
            type: 'info',
            msg: 'Failed to book a demo :/',
            clickClose: true,
            timeout: 5000,
            position: 'toast-bottom-center',
            progressbar: false,
            preventDuplicates: true
          })
        })
    }
  }
})
