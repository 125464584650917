import { silenceSymbol } from '@/view/voiceEditor/proseEditor/CONSTANTS'

const uuidv1 = require('uuid/v1')

// dynamic nodes, created at runtime, they are not part of schema
// handleText.js is creating these silence nodes

export function createSilenceNode (view) {
  return view.state.schema.text(silenceSymbol, [
    // why do we have two marks with same info? come back later
    view.state.schema.mark('silence', {
      uid: 'silence-' + uuidv1(),
      astart: 0,
      aend: 0.1
    }),
    view.state.schema.mark('info', {
      uid: 'silence-' + uuidv1(),
      astart: 0,
      aend: 0.1
    })
  ])
}
// music can also have silence
export function createSilenceNodeWithMusicMark (view, uid) {
  return view.state.schema.text(silenceSymbol, [
    view.state.schema.mark('silence', {
      uid: 'silence-' + uuidv1(),
      astart: 0,
      aend: 0.1
    }),
    view.state.schema.mark('info', {
      uid: 'silence-' + uuidv1(),
      astart: 0,
      aend: 0.1
    }),
    view.state.schema.mark('note', {
      class: 'note',
      note_uid: uid
    })
  ])
}
