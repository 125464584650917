<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
    <path d="M9 9.00349C9 8.22686 9.84668 7.74665 10.5132 8.14524L15.5104 11.1335C16.1659 11.5255 16.1659 12.4752 15.5104 12.8672L10.5132 15.8553C9.84666 16.2538 9 15.7736 9 14.997V9.00349Z" />
  </svg>
</template>

<script>
export default {
  name: 'MusicPlay',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
