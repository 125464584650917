export const iconbuttonTheme = {
  base: `inline-flex items-center focus:outline-none`,
  sizes: {
    small: `p-1 rounded-2.5 text-xl leading-5`,
    normal: `p-1.75 rounded-2.5 text-xl leading-5`,
    large: `p-2 rounded-xl text-2xl leading-5`
  },
  variants: {
    normal: `border border-transparent hover:border-primary-200 hover:bg-primary-200 active:border-primary-200 active:bg-primary-200 text-primary`,
    bordered: `border border-primary-300 hover:bg-primary-200 active:border-primary-200 active:bg-primary-200 text-primary`,
    disabled: `border border-transparent hover:border-primary-200 text-primary-400`,
    active: `border border-primary bg-primary text-white shadow-key`
  }
}

export const radioTheme = {
  base: `h-4 w-4 cursor-pointer focus:ring-0`,
  indicator: `border-2 border-primary rounded-full`,
  normal: `border-gray-300 text-violet-600 focus:ring-violet-500`,
  invalid: `border-red-500 text-red-600 focus:ring-red-500`,
  disabled: `cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800`
}

export const buttonTheme = {
  base: `inline-flex items-center focus:outline-none font-medium uppercase`,
  sizes: {
    small: `px-3 py-2 text-2xs rounded-lg`,
    normal: `px-3.75 py-2.75 text-xs rounded-xl`
  },
  variants: {
    primary: `border border-primary text-white bg-primary hover:border-primary-900 hover:bg-primary-900 focus:border-primary-800 focus:bg-primary-800`,
    secondary: `text-primary border border-primary-300 hover:border-primary-200 hover:bg-primary-200 active:border-primary-300 active:bg-primary-300`,
    success: `border border-green text-white bg-green hover:border-green-600 hover:bg-green-600 focus:border-green-700 focus:bg-green-700`,
    disabled: `border border-primary-200 text-primary-500 bg-primary-200`
  }
}
