<template>
  <component :is="currentModal" :modalProps="openModalProps" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import x from '@/view/voiceEditor/vue/components/modals/AddFileModal.vue'

export default {
  // TODO: think of a way to avoid importing at one place
  components: {
    SmartEditModal: () => import(/* webpackChunkName: "SmartEditModal" */'@/view/voiceEditor/vue/components/SpextDoc/SmartEditModal.vue'),
    AudioOnboarding: () => import(/* webpackChunkName: "AudioOnboarding" */'@/components/HomeAudioOnBoarding.vue'),
    TranscriptOnboarding: () => import(/* webpackChunkName: "TranscriptOnboarding" */'@/components/TranscriptOnBoarding.vue'),
    AskSubModal: () => import(/* webpackChunkName: "AskSubModal" */'@/components/modals/AskSubModal.vue'),
    ErrorMessage: () => import(/* webpackChunkName: "ErrorMessage" */'@/components/modals/ErrorMessage.vue'),
    AddFile: () => import(/* webpackChunkName: "AddFileModal" */'@/view/voiceEditor/vue/components/modals/AddFileModal.vue'),
    CorrectTranscript: () => import(/* webpackChunkName: "AdminControlModal" */'@/components/modals/CorrectTranscriptModal.vue'),

    // AccountInfo: () => import(/* webpackChunkName: "AccounttInfo" */'@/components/modals/AccountInfoModal/index.vue'),
    // EditMode: () => import(/* webpackChunkName: "EditModeModal" */'@/components/modals/EditModeModal.vue'),
    // KnowPurpose: () => import(/* webpackChunkName: "KnowPurposeModal" */'@/components/modals/KnowPurposeModal.vue'),
    AdminControl: () => import(/* webpackChunkName: "AdminControlModal" */'@/components/modals/AdminControlModal.vue'),
    InviteModal: () => import(/* webpackChunkName: "InviteModal" */'@/components/modals/InviteModal.vue'),
    EditTranscriptPrompt: () => import(/* webpackChunkName: "EditTranscriptPrompt" */'@/components/modals/EditTranscriptPrompt.vue'),
    ExportMessage: () => import(/* webpackChunkName: "ExportMessage" */'@/components/modals/ExportMessage.vue'),
    EditMediaPrompt: () => import(/* webpackChunkName: "EditMediaPrompt" */'@/components/modals/EditMediaPrompt.vue'),
    ClipperExportMessage: () => import(/* webpackChunkName: "ClipperExportMessage" */'@/components/modals/ClipperExportMessage.vue'),
    WelcomeModal: () => import(/* webpackChunkName: "WelcomeModal" */ '@/view/Onboarding/components/WelcomeModal.vue'),
    EditorModal: () => import(/* webpackChunkName: "WelcomeModal" */'@/view/Onboarding/components/EditorModal.vue'),
    ClipperExportModal: () => import(/* webpackChunkName: "ClipperExportModal" */'@/components/modals/Clipper/index.vue')
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal'
    }),
    close: function() {
      this.closeModal()
      this.onClose()
    }
  },
  computed: {
    ...mapGetters({
      openModalName: 'dialogs/modalName',
      openModalProps: 'dialogs/modalProps'
    }),
    currentModal () {
      return this.openModalName || ''
    }
  },
  watch: {
    openModalName: function(val, oldVal) {
      if (val === this.modalname && oldVal !== this.modalname) this.onOpen()
    }
  }
}
</script>

<style>

</style>
