import dayjs from 'dayjs'
import store from '@/services/store'

const findAndUpdateObj = (arr, key, update) => {
  if (!Array.isArray(arr)) return []
  const i = arr.findIndex(el => el[key] === update[key])
  return i === -1 ? [ update, ...arr ] : [...arr.slice(0, i), update, ...arr.slice(i + 1)]
}

const ceVideoDefaultProps = {
  name: 'ce-video',
  id: 'ce-video-default',
  type: 'video/mp4',
  value: '',
  size: {
    height: '100%',
    width: '100%'
  },
  position: { // in percentage & relative from left bottom corner
    x: 0,
    y: 0
  },
  meta: {
    cover: true,
    style: {
      'z-index': 1
    }
  },
  currentTime: 0
}

export const defaultState = () => ({
  // meta
  id: 'default',
  name: '',

  // elements
  elements: [],
  sources: {},
  imageCache: {},
  sourcesReadyMap: {},
  sourcesReady: true,
  sourcesLoading: false,
  logs: [],
  previewVideoStatus: false
})
export default {
  namespaced: true,
  state: defaultState,
  getters: {
    texts: state => state.elements.filter(el => el.type === 'text'),
    images: state => state.elements.filter(el => el.type === 'image'),
    videos: state => state.elements.filter(el => el.type && el.type.startsWith('video/')),
    nodes: state => state.elements.filter(el => el.type === 'node'),
    waves: state => state.elements.filter(el => el.type === 'wave'),
    headings: state => state.elements.filter(el => el.type === 'heading'),
    sources: state => state.sources,
    sourcesReady: state => state.sourcesReady,
    sourcesLoading: state => state.sourcesLoading,
    sourcesReadyMap: state => state.sourcesReadyMap,
    imageCache: state => state.imageCache,
    logs: state => state.logs,
    previewVideoStatus: state => state.previewVideoStatus
  },
  mutations: {
    resetVideo: function(state) {
      state.id = 'default'
      state.name = ''
      state.elements = []
      state.sourcesReadyMap = {}
      state.sources = {}
      state.sourcesReady = true
      state.sourcesLoading = false
    },
    cacheSources: function(state, sources) {
      state.sources = sources
    },
    setSourcesReadyMap: function(state, payload) {
      state.sourcesReadyMap = payload
    },
    setSourcesReady: function(state, payload = true) {
      state.sourcesReady = payload
    },
    setSourcesLoading: function(state, payload = true) {
      state.sourcesLoading = payload
    },
    replaceElements: function(state, elements) {
      state.elements = elements
    },
    addImage: function(state, image) {
      state.imageCache = {
        ...state.imageCache,
        ...image
      }
    },
    findAndUpdateElement: function(state, { find: { by, value }, update }) {
      const getElementIfMounted = state.elements.find(el => el[by] === value && el.id === update.id)
      const elementToUpdate = getElementIfMounted || state.elements.find(el => el[by] === value)

      // if (value === 'ce-subtitle') console.log('fdkjshgkjdsghl subbb', update)

      if (update.type && update.type.startsWith('video/')) {
        console.log('video update', update)
        store.commit('video/commitPreviewVideoStatus', update.value)
        const activeVideo = state.elements.find(el => el.type && el.type.startsWith('video/') && !el.hidden)
        let newElements = activeVideo ? findAndUpdateObj(state.elements, 'id', {
          ...activeVideo,
          hidden: true
        }) : state.elements
        state.elements = findAndUpdateObj(newElements, 'id', {
          ...ceVideoDefaultProps,
          hidden: false,
          ...update
        })

        if (update.value && !state.sources[update.value]) {
          // store.commit('video/addLog', `##Missing cached source - ${update.id}`)
          console.log(`##Missing cached source - ${update.id}`)
          const xhr = new XMLHttpRequest()

          xhr.onload = () => {
            const reader = new FileReader()
            reader.readAsDataURL(xhr.response)
            reader.onload = e => {
              state.sources = {
                ...state.sources,
                [update.value]: e.target.result
              }
            }
          }
          xhr.responseType = 'blob'
          xhr.open('GET', update.value)
          xhr.send()
        }
      } else {
        state.elements = findAndUpdateObj(state.elements, by, {
          ...elementToUpdate,
          ...update
        })
      }
    },
    deleteElements: function(state, ids = []) {
      state.elements = [
        ...state.elements.filter(el => !ids.includes(el.id))
      ]
    },
    addElements: function(state, elements = []) {
      state.elements = [
        ...state.elements,
        ...elements
      ]
    },
    addLog: function(state, msg) {
      state.logs = [
        `${dayjs().format('h:mm:ss A')} - ${msg}`,
        ...state.logs
      ]

      if (window.Sentry) window.Sentry.captureMessage(msg)
    },
    commitPreviewVideoStatus: function(state, msg) {
      state.previewVideoStatus = msg
    }
  },
  actions: {
    findAndUpdateElement: function({ commit }, params) {
      commit('findAndUpdateElement', params)
    },
    replaceElements: function({ commit }, elements) {
      commit('replaceElements', elements)
    },
    initPlayer: function({ commit }, { elements }) {
      commit('replaceElements', elements)
    },
    resetVideo: function({ commit }) {
      commit('resetVideo')
    }
  }
}
