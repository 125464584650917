<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    :class="classname"
  >
    <path d="M11.1616 13.0003L5.58081 18.5811L7.41929 20.4195L13.0001 14.8388L18.5808 20.4195L20.4193 18.5811L14.8385 13.0003L20.4193 7.41953L18.5808 5.58105L13.0001 11.1618L7.41933 5.58105L5.58085 7.41953L11.1616 13.0003Z" />
  </svg>
</template>

<script>
export default {
  name: 'Close',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
