const defaultState = () => ({
  modalName: '',
  modalProps: {}
})

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    modalName: state => state.modalName,
    modalProps: state => state.modalProps
  },
  mutations: {
    openModal: function(state, { name, props }) {
      state.modalName = name
      state.modalProps = props
      $('body').addClass('overflow-hidden')
    },
    closeModal: function(state) {
      state.modalName = ''
      state.modalProps = {}
      $('body').removeClass('overflow-hidden')
    }
  },
  actions: {
    openModal: function({ commit }, payload) {
      commit('openModal', payload)
    },
    closeModal: function({ commit }) {
      commit('closeModal')
    }
  }
}
