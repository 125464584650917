<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.93433 5.93118L3.0657 4.7998L8.00001 9.73412L12.9343 4.7998L14.0657 5.93118L8.00001 11.9969L1.93433 5.93118Z" />
  </svg>
</template>

<script>
export default {
  name: 'ChevronDown',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
