// parseDOM is not required, because we want it to copy info rather than pasted mark
export const pasted = {
  attrs: {
    class: { default: 'pasted' }
  },
  toDOM: function (mark) {
    if (window.mode === 'editTranscript') {
      mark.attrs.class = 'pastedT'
    } else {
      mark.attrs.class = 'pasted'
    }
    return ['span', mark.attrs]
  }
}
