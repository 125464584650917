// only used in editTranscript mode
// Do Not change class any name here - will affect- moveCursorInDirection

export const restored = {
  attrs: {
    isRestored: { default: true }
  },
  toDOM: (mark) => {
    return ['span', mark.attrs]
  }
}
