import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { AccountInfo } from '@/models/Account/AccountInfo'
import CONSTANTS from '@/constants/CONSTANTS'
import store from '@/services/store'

export function initAuth (callback) {
  const allowedLoginsRef = firebase.database().ref(`configs/allowed_logins`)
  allowedLoginsRef.once('value', (snapshot) => {
    const allowedLoginsObject = snapshot.val() || {}
    const allowedLoginsArray = Object.keys(allowedLoginsObject)
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        if (allowedLoginsArray.includes(user.uid)) {
          store.commit('app/setIsUserAllowed', true)
        } else {
          store.commit('app/setIsUserAllowed', false)
        }
      }
      callback(user)
    })
  })
}

export function updateUserInfo (user) {
  let accountInfo = new AccountInfo()
  if (localStorage['coupon']) {
    accountInfo['coupon'] = localStorage['coupon']
  }
  // chnaged the account type based on env to trigger right urls in emails
  accountInfo['accountType'] = CONSTANTS.ACCOUNT_TYPE
  firebase.database().ref(user.uid + '/userInfo/accountInfo/').set(accountInfo).then(function () {
    delete localStorage['coupon']
  }).catch(function (err) {
    delete localStorage['coupon']
    console.log('Account already exists', err.code)
  })
  // }
  firebase.database().ref(user.uid + '/userInfo/personal/').update(user).then(function () {
    console.log('Written User to Firebase')
  }).catch(function (err) {
    console.log('some err', err)
  })
}

export function getUserInfo (userID, callback) {
  firebase.database().ref(userID + '/userInfo').on('value', function (snapshot) {
    if (snapshot.exists()) {
      callback(snapshot.val())
    }
  })
}

export function verifyEmail (successCallback, failureCallback) {
  let actionCodeSettings = {
    url: CONSTANTS.URL
  }
  let user = firebase.auth().currentUser
  user.sendEmailVerification(actionCodeSettings).then(function () {
    successCallback()
  }).catch(function (error) {
    console.log(error)
    failureCallback()
  })
}

export function isUserEmailVerified() {
  return firebase.auth().currentUser.emailVerified
}

export function reloadUser(callback) {
  firebase.auth().currentUser.reload().then(r =>
    callback()
  )
}

export function geCurrentUserJSON() {
  return firebase.auth().currentUser.toJSON()
}

export function setOnboardingState(id, val) {
  return firebase.database().ref(id + '/userInfo/onboarding/state').set(val)
}
