// text* is coming from text.js it can be used as inline* ( not sure )
// zero or more text
// group it with other text.js "inline group"
import uuidv1 from 'uuid/v1'

export const image = {
  content: 'text*',
  inline: true,
  attrs: {
    class: { default: 'localimage' },
    type: { default: 'image' },
    title: { default: 'Image' },
    uid: { default: uuidv1() },
    position: { default: {
      top: 0,
      right: 0,
      zindex: 0
    } }
  },
  draggable: true,
  group: 'inline',
  selectable: false,
  atom: true,
  parseDOM: [
    {
      tag: 'text',
      getAttrs: function getAttrs (dom) {
        return {
          class: dom.getAttribute('class'),
          title: dom.getAttribute('title'),
          position: {
            start: Number(dom.getAttribute('ptop')),
            right: Number(dom.getAttribute('pright')),
            zindex: Number(dom.getAttribute('pzindex'))
          }
        }
      }
    }
  ],
  toDOM: function toDOM (node) {
    return [
      'span',
      {
        class: 'music-icon',
        ptop: node.attrs.position.top,
        pright: node.attrs.position.right,
        pzindex: node.attrs.position.zindex,
        title: node.attrs.title
      }
    ]
  }
}
