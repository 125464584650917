import { EditorView } from 'prosemirror-view'
import EventBus from 'js-event-bus'
import { getSnappedSelectionForLocation } from '../util/utility'
import { TextSelection, Transaction } from 'prosemirror-state'
import { getUidFromPillElementId } from '@/view/voiceEditor/proseEditor/util/utility'
import { Node } from 'prosemirror-model'

export class PillEvents {
  public readonly type: string;
  public uid: string;
  public view: EditorView;
  public pillElements: {
    startPill?: HTMLElement | null;
    endPill?: HTMLElement | null;
  }
  public dragStarted: boolean
  /*
      This eventBus can be imported by any piece of code trying to implement event bus related functionality
     related specifically to the type of pills
  */
  public eventBus: EventBus
  public draggedNode: Node | null = null
  public draggedNodePos: {
    pos: number;
    inside: number;
  } | null
  public cursorPos: number | null = null;
  public cursorElement: HTMLElement | null = null;
  // The pill's node size. This is usually equal to 5 but is also set correctly on creation
  public pillNodeSize: number
  constructor(view: EditorView, uid: string, type: string) {
    this.view = view
    this.uid = uid
    this.type = type
    this.pillElements = {
      startPill: document.getElementById(`start${type}-${uid}`),
      endPill: document.getElementById(`end${type}-${uid}`)
    }
    this.dragStarted = false
    this.eventBus = new EventBus()
    this.draggedNodePos = null
    this.pillNodeSize = 5
  }

  public setupListeners() {
    const { endPill, startPill } = this.pillElements

    endPill?.addEventListener('mousedown', this.onMouseDown)
    endPill?.addEventListener('dragstart', this.onDragStart)
    endPill?.addEventListener('drag', this.onDrag)
    endPill?.addEventListener('dragend', this.onDragEnd)

    startPill?.addEventListener('mousedown', this.onMouseDown)
    startPill?.addEventListener('dragstart', this.onDragStart)
    startPill?.addEventListener('drag', this.onDrag)
    startPill?.addEventListener('dragend', this.onDragEnd)
  }

  // When the user holds their mouse down on one of the pills
  public onMouseDown = (e: MouseEvent) => {
    let posAtXY = this.view.posAtCoords({ left: e.clientX, top: e.clientY })
    if (!this.dragStarted && posAtXY) {
      this.draggedNodePos = posAtXY
      this.draggedNode = this.view.state.doc.nodeAt(posAtXY.inside)
    }
  }

  // As soon as the drag starts
  public onDragStart = (e: DragEvent) => {
    // TODO hide globe icon completely
    e.dataTransfer?.setDragImage(document.createElement('span'), 0, 0)
    this.dragStarted = true
  }

  // While dragging -
  public onDrag = (e: DragEvent) => {
    let pos = this.view.posAtCoords({ left: e.clientX, top: e.clientY })
    if (pos) {
      try {
        let target = pos.pos
        const node = e.target as HTMLElement
        node.style.opacity = '0'
        this.makeSelection(this.draggedNode?.attrs.type, target)
        this.setCursor(target, node)
      } catch (err) {
        console.error(err)
      }
    }
  }

  // Drag end (or drop)
  public onDragEnd = (e: DragEvent) => {
    if (!this.draggedNode || !this.draggedNodePos) return
    // remove the cursor element which moves while dragging
    if (this.cursorElement) this.setCursor(null, this.cursorElement)
    let fromPos = this.draggedNodePos.pos
    let toPos = this.view.posAtCoords({ left: e.clientX, top: e.clientY })?.pos
    if (!toPos) return
    const aNode = e.target as HTMLElement
    aNode.style.opacity = '1'
    let transaction = this.view.state.tr
    const currentNode = this.view.state.doc.nodeAt(fromPos)
    let otherNodePos: number | undefined
    if (currentNode?.attrs.type === `start${this.type}`) {
      // Getting other node data to calculate final start and end position
      const otherElm = this.pillElements.endPill?.getBoundingClientRect()
      if (otherElm) {
        otherNodePos = this.view.posAtCoords({ left: otherElm.left, top: otherElm.top })?.pos
        if (otherNodePos && otherNodePos === toPos) {
          this.cleanUp(fromPos)
          return
        }
        if (otherNodePos) otherNodePos = otherNodePos - currentNode.nodeSize
        // Other node pos has to be calculated considering the position without any pills as this will
        // be used as the end position of the pill
      }
      if (toPos < fromPos) {
        // pill extended from start
        let { start, end } = getSnappedSelectionForLocation(toPos, this.draggedNodePos.pos, this.view)
        toPos = start as number
        fromPos = end as number

        transaction.delete(this.draggedNodePos.pos, this.draggedNodePos.pos + currentNode.nodeSize)
        transaction.insert(toPos, currentNode)
        this.addMarkAtLocationInTransaction(toPos + currentNode.nodeSize, fromPos, this.view, transaction, currentNode.attrs.id, `${this.type}-hl`)
      } else if (fromPos < toPos) {
        if (otherNodePos && toPos > otherNodePos) {
          this.cleanUp(fromPos)
          return
        }
        // pill shortened from start
        const nodeAtToPos = this.view.state.doc.nodeAt(toPos)
        if (!nodeAtToPos) return
        let { start } = getSnappedSelectionForLocation(toPos, toPos + nodeAtToPos.nodeSize, this.view)
        toPos = start as number
        transaction.insert(toPos, currentNode)
        this.removeMarkAtLocationInTransaction(this.draggedNodePos.pos + currentNode.nodeSize, toPos, this.view, transaction)
        transaction.delete(this.draggedNodePos.pos, this.draggedNodePos.pos + currentNode.nodeSize)
        toPos = toPos - currentNode.nodeSize
      }
      transaction.setSelection(new TextSelection(transaction.doc.resolve(toPos)))
    } else if (currentNode?.attrs.type === `end${this.type}`) {
      // Getting other node data to calculate final start and end position
      const otherElm = this.pillElements.startPill?.getBoundingClientRect()
      if (otherElm) {
        otherNodePos = this.view.posAtCoords({ left: otherElm.left, top: otherElm.top })?.pos
        if (otherNodePos && otherNodePos === toPos) {
          this.cleanUp(fromPos)
          return
        }
      }
      if (fromPos < toPos) {
        // pill extended from end
        let { end } = getSnappedSelectionForLocation(toPos, toPos + 1, this.view)
        toPos = end as number
        const lastChildPos = this.view.state.doc.content.size - (this.view.state.doc.lastChild?.lastChild ? this.view.state.doc.lastChild.lastChild.nodeSize : 0)
        if (toPos > lastChildPos) {
          toPos = lastChildPos
        }
        this.addMarkAtLocationInTransaction(fromPos,
          toPos - currentNode.nodeSize,
          this.view, transaction, currentNode.attrs.id, `${this.type}-hl`
        )
        transaction.insert(toPos, currentNode)
        transaction.delete(this.draggedNodePos.pos, this.draggedNodePos.pos + this.draggedNode.nodeSize)
        // times two because it is size of start pill + pill that already existed in the middle
        toPos = toPos - (2 * currentNode.nodeSize + 2)
      } else if (toPos < fromPos) {
        // pill shortened from end
        if (otherNodePos && toPos < otherNodePos) {
          this.cleanUp(fromPos)
          return
        }
        const nodeAtToPos = this.view.state.doc.nodeAt(toPos)
        if (!nodeAtToPos) return
        let { start } = getSnappedSelectionForLocation(toPos, toPos + nodeAtToPos.nodeSize, this.view)
        toPos = start as number
        transaction.delete(this.draggedNodePos.pos, this.draggedNodePos.pos + currentNode.nodeSize)
        transaction.insert(toPos, currentNode)
        this.removeMarkAtLocationInTransaction(
          toPos, this.draggedNodePos.pos + currentNode.nodeSize,
          this.view, transaction
        )
        toPos = toPos - currentNode.nodeSize - 2
      }
      transaction.setSelection(new TextSelection(transaction.doc.resolve(toPos)))
    }
    if (otherNodePos === undefined || !currentNode) return

    let endPos = Math.max(toPos, otherNodePos)
    let startPos = Math.min(toPos, otherNodePos)

    this.view.dispatch(this.metafyTransaction(transaction))
    this.eventBus.emit(`${this.type}-edit.${this.uid}`, null, startPos, endPos, this.uid)
    this.cleanUp()
  }

  public cleanUp(resetSelection: number | null = null) {
    this.draggedNodePos = null
    this.dragStarted = false
    this.cursorElement = null
    this.cursorPos = null
    this.resetElements()
    this.setupListeners()
    if (resetSelection) {
      const transaction = this.view.state.tr.setSelection(new TextSelection(this.view.state.doc.resolve(resetSelection)))
      this.view.dispatch(this.metafyTransaction(transaction))
    }
  }

  public makeSelection = (type: string, toPos: number) => {
    let fromPos = this.draggedNodePos?.pos
    const doc = this.view.state.doc
    const state = this.view.state
    const dispatch = this.view.dispatch
    const transaction = this.view.state.tr
    if (!fromPos) return
    let draggedNodeSize = this.view.state.doc.nodeAt(fromPos)?.nodeSize
    if (!draggedNodeSize) draggedNodeSize = 0
    let startResolvedPos = null
    let endResolvedPos = null
    if (type === `start${this.type}`) {
      if (toPos < fromPos) {
        // const { start } = getSnappedSelectionForLocation(toPos, fromPos, this.view)
        // toPos = start
        startResolvedPos = doc.resolve(toPos)
        endResolvedPos = doc.resolve(fromPos)
        dispatch(this.metafyTransaction(transaction.setSelection(new TextSelection(endResolvedPos, startResolvedPos))))
      } else if (fromPos < toPos) {
        // const nodeAtToPos = doc.nodeAt(toPos)
        // let { start } = getSnappedSelectionForLocation(toPos, toPos + (nodeAtToPos ? nodeAtToPos.nodeSize : 0), this.view)
        // toPos = start
        startResolvedPos = doc.resolve(fromPos)
        endResolvedPos = doc.resolve(toPos)
        dispatch(this.metafyTransaction(transaction.setSelection(new TextSelection(startResolvedPos, endResolvedPos))))
      }
    } else if (type === `end${this.type}`) {
      if (fromPos < toPos) {
        // let { end } = getSnappedSelectionForLocation(toPos, toPos + 1, this.view)
        // toPos = end
        startResolvedPos = doc.resolve(fromPos)
        endResolvedPos = doc.resolve(toPos)
        dispatch(this.metafyTransaction(transaction.setSelection(new TextSelection(startResolvedPos, endResolvedPos))))
      } else if (toPos < fromPos) {
        // const nodeAtToPos = doc.nodeAt(toPos)
        // let { start } = getSnappedSelectionForLocation(toPos, toPos + (nodeAtToPos ? nodeAtToPos.nodeSize : 0), this.view)
        // toPos = start
        startResolvedPos = doc.resolve(fromPos)
        endResolvedPos = doc.resolve(toPos)
        dispatch(this.metafyTransaction(transaction.setSelection(new TextSelection(startResolvedPos, endResolvedPos))))
      }
    }
  }

  public setCursor = (pos: number | null, elm: HTMLElement) => {
    if (this.cursorPos && pos === this.cursorPos) return
    this.cursorPos = pos
    if (this.cursorPos === null) {
      this.cursorElement?.remove()
      this.cursorElement = null
    } else {
      let rect: {
        left: number,
        top: number,
        right: number,
        bottom: number
      } | null = null
      let $pos = this.view.state.doc.resolve(this.cursorPos)
      if (!$pos.parent.inlineContent) {
        let before = $pos.nodeBefore
        let after = $pos.nodeAfter
        if (before || after) {
          let node = this.view.nodeDOM(this.cursorPos - (before ? before.nodeSize : 0)) as Element
          let nodeRect = node.getBoundingClientRect()
          let top = before ? nodeRect.bottom : nodeRect.top
          if (before && after) {
            const cursorPosNode = this.view.nodeDOM(this.cursorPos) as Element
            top = (top + cursorPosNode.getBoundingClientRect().top) / 2
          }
          rect = { left: nodeRect.left, right: nodeRect.right, top: top - elm.offsetWidth / 2, bottom: top + elm.offsetWidth / 2 }
        }
      }
      if (!rect) {
        let coords = this.view.coordsAtPos(this.cursorPos)
        rect = { left: coords.left - elm.offsetWidth / 2, right: coords.left + elm.offsetWidth / 2, top: coords.top, bottom: coords.bottom }
      }

      let parent = this.view.dom.offsetParent
      if (!parent) return
      if (!this.cursorElement) {
        this.cursorElement = parent.appendChild(elm.cloneNode(true)) as HTMLElement
        this.cursorElement.style.opacity = '1'
        this.cursorElement.style.cssText = 'position: absolute; z-index: 50; pointer-events: none; background-color: none; transition: left 0.1s ease'
      }
      let parentRect: DOMRect | {
        left: number,
        top: number
      } = ((parent === document.body) && (getComputedStyle(parent).position === 'static') ? { left: scrollX, top: scrollY } : parent.getBoundingClientRect())
      this.cursorElement.style.left = (rect.left - parentRect.left + this.getCursorVal()) + 'px'
      this.cursorElement.style.top = (rect.top - parentRect.top - 6) + 'px'
      this.cursorElement.style.width = (rect.right - rect.left) + 'px'
      this.cursorElement.style.height = (rect.bottom - rect.top + 4) + 'px'
    }
  }

  private getCursorVal() {
    const { type } = this
    if (this.draggedNode?.attrs.type === `end${type}`) {
      return 15
    } else if (this.draggedNode?.attrs.type === `start${type}`) {
      return -15
    } else return 0
  }

  public removeMarkAtLocationInTransaction = (start: number, end: number, view: EditorView, transaction: Transaction) => {
    if (view.state.doc.nodeSize === end + 1) {
      return
    }
    return transaction.removeMark(start, end, this.view.state.schema.marks[`${this.type}Highlight`])
  }

  private addMarkAtLocationInTransaction(from: number, to: number, view: EditorView, transaction: Transaction, uid: string, className: string) {
    const { type } = this
    const newHighlightMark = view.state.schema.mark(`${type}Highlight`, {
      class: className,
      [`${type}hl_uid`]: `${type}hl-${getUidFromPillElementId(uid) as string}`
    })
    const { start, end } = getSnappedSelectionForLocation(from, to, view)
    transaction.addMark(start, end, newHighlightMark)
  }

  public resetElements() {
    this.pillElements = {
      startPill: document.getElementById(`start${this.type}-${this.uid}`),
      endPill: document.getElementById(`end${this.type}-${this.uid}`)
    }
  }

  public metafyTransaction(tr: Transaction) {
    return tr.setMeta('clipper', true)
  }
}
