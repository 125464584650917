// we have only class attribute here.
// how is this mark similar to dynamic silenceNode?
// revisit later
export const silence = {
  attrs: {
    class: { default: 'silence' }
  },
  parseDOM: [{
    tag: 'span.silenceT'
  },
  {
    tag: 'span.silence'
  }],
  toDOM: (mark) => {
    if (window.mode === 'editTranscript') {
      mark.attrs.class = 'silenceT'
    } else {
      mark.attrs.class = 'silence'
    }
    return ['span', mark.attrs]
  }
}
