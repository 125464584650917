<template>
  <div :class="containerClass">
    <div
      v-if="!forceHideGap && (focused || showGap)"
      :class="titleClass"
    >
      {{focused ? title : '' }}<span>&#8203;</span>
    </div>
    <input
      v-if="vmask"
      v-mask="vmask"
      :class="inputClass"
      :value="value"
      :placeholder="focused ? placeholder : title"
      @input="$emit('input', $event.target.value)"
      @focus="focused=true"
      @blur="focused=false"
      ref="inputref"
    />
    <input
      v-else
      :class="inputClass"
      :value="value"
      :placeholder="focused ? placeholder : title"
      :autofocus="autofocus"
      @input="$emit('input', $event.target.value)"
      @focus="focused=true"
      @blur="handleTextInputBlur"
      ref="inputref2"
    />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  data: function() {
    return {
      focused: false
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    vmask: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    titleClass: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    showGap: {
      type: Boolean,
      default: false
    },
    forceHideGap: {
      type: Boolean,
      default: false
    }
  },
  directives: { mask },
  mounted: function() {
    if (this.autofocus) {
      this.$refs.inputref.focus()
    }
  },
  methods: {
    handleTextInputBlur() {
      this.focused = false
      this.$emit('inputBlur')
    }
  },
  watch: {
    autofocus(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        if (this.vmask) {
          this.$refs.inputref.focus()
        } else {
          this.$refs.inputref2.focus()
        }
      }
    }
  }
}
</script>

<style>

</style>
