import Analytics from 'analytics'
import amplitudePlugin from '@analytics/amplitude'
import intercomPlugin from '@analytics/intercom'
import googleAnalytics from '@analytics/google-analytics'
import store from '@/services/store'

let CONFIG = {}
if (process.env.NODE_ENV === 'production') {
  CONFIG = {
    'GA': {
      trackingId: 'UA-116196965-1'
    },
    'AMP': {
      apiKey: 'be5b7dcdebcff49fee6de394de0c9aa1'
    },
    'Intercom': {
      appId: 'dwbeav27'
    }

  }
} else {
  CONFIG = {
    'GA': {
      trackingId: 'UA-71889740-6'
    },
    'AMP': {
      apiKey: '856664201e217330200af07e89a16c01',
      options: {
        forceHttps: true
      }
    },
    'Intercom': {
      appId: 'q7fektes'
    }
  }
}

const analytics = Analytics({
  app: 'spext',
  plugins: [
    amplitudePlugin(CONFIG.AMP),
    intercomPlugin(CONFIG.Intercom),
    googleAnalytics(CONFIG.GA)
  ]
})

window.analytics = {
  ...analytics,
  track: (eventName, payload) => analytics.track(eventName, {
    ...payload,
    url: window.location.href,
    user: store && store.state && store.state.app && store.state.app.user ? store.state.app.user.uid : null
  })
}
