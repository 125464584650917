import store from '@/services/store'
import {
  deleteWordAtLocations, getNextWords, getPreviousFewWords, multipleSearchHighlightInDoc,
  multipleSearchInDoc,
  restoreWordAtLocations
} from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
import * as firebase from 'firebase'
import { isSilenceSymbol, smartEditSilenceThreshold } from '@/view/voiceEditor/proseEditor/CONSTANTS'
import { LocalTextSearchResultItem } from '@/models/Search/LocalTextSearchResultItem'
import { isNoteSymbol, myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
import { getInfoMarkFromTextNode } from '@/view/voiceEditor/proseEditor/Nodes/textNode'

const fillerWords = /\b(uh|um)\s/ig

function isRestored(marks) {
  let truth = false
  marks.forEach(mark => {
    if (mark.type.name === 'restored') {
      truth = true
    }
  })
  return truth
}

function ifUserDeleted(marks) {
  let truth = false
  marks.forEach(mark => {
    if (mark.type.name === 'deleted') {
      if (mark.attrs.type === 'user') {
        truth = true
      }
    }
  })
  return truth
}
function ifDeleted(marks) {
  let truth = false
  marks.forEach(mark => {
    if (mark.type.name === 'deleted') {
      truth = true
    }
  })
  return truth
}
function ifSmartDeleted(marks) {
  let truth = false
  marks.forEach(mark => {
    if (mark.type.name === 'deleted') {
      if (mark.attrs.type === 'smart') {
        truth = true
      }
    }
  })
  return truth
}

function record (result, from, to, marks) {
  result.push({ from, to, marks })
}
export function searchFillerWordInDoc () {
  return multipleSearchInDoc(store.state.smartEditConfig.fillerWords.words).filter(u => !u.isRestored)
}
export function highlightFillerWordInDoc () {
  return multipleSearchHighlightInDoc(store.state.smartEditConfig.fillerWords.words).filter(u => !u.isRestored)
}
export function searchContinuousSilence () {
  let doc = myProseEditor.view.state.doc
  let continuousSecondsThreshold = store.state.smartEditConfig.silenceUpto
  let result = []
  let tempStart = -1
  let tempEnd = -1
  let tempSilenceDuration = 0
  let continuousSilence = 0
  let isSmartDeleted = false
  // For each node in the document
  doc.descendants((node, pos) => {
    // skip words restored by user
    if (node.isText && !isRestored(node.marks)) {
      let infoMark = getInfoMarkFromTextNode(node).attrs
      // filter for silence symbols
      if (isSilenceSymbol(node.text)) {
        // skip user deleted silence
        if (!ifUserDeleted(node.marks) && !isNoteSymbol(node.marks)) {
          let incrementWith = 0.1
          if (infoMark) {
            incrementWith = infoMark.aend - infoMark.astart
          }
          continuousSilence += incrementWith
          if (continuousSilence >= continuousSecondsThreshold) {
            if (tempStart === -1) {
              tempStart = pos
              tempEnd = pos
            }
            tempEnd = pos + node.text.length
            tempSilenceDuration += incrementWith
          }
        }
        if (ifSmartDeleted(node.marks)) {
          isSmartDeleted = true
        }
      } else { // if it is non silence word, reset the silence counter
        if (!ifDeleted(node.marks)) {
          if (tempStart >= 0 && tempEnd >= 0) {
            let resultItem = LocalTextSearchResultItem(
              ' ----- ',
              getPreviousFewWords(tempStart, 1) + ' ----- ' + getNextWords(tempEnd, 1).text,
              isSmartDeleted,
              false,
              {
                start: tempStart,
                end: tempEnd
              },
              { silenceDurationInSeconds: tempSilenceDuration }
            )
            // console.log('silence result', resultItem)
            result.push(resultItem)
          }
          isSmartDeleted = false
          continuousSilence = 0
          tempStart = -1
          tempEnd = -1
          tempSilenceDuration = 0
        }
      }
    }
  })
  // console.log('searchContinuousSilence', result)
  return result
}

function searchSmartEditAppliedSilence (doc) {
  let result = []

  // For each node in the document
  doc.descendants((node, pos) => {
    // skip words restored by user
    if (node.isText && !isRestored(node.marks)) {
      // filter silence deleted by smartEdit
      if (isSilenceSymbol(node.text) && ifSmartDeleted(node.marks)) {
        record(result, pos, pos + node.text.length, node.marks)
      }
    }
  })
  return result
}
export function disableFillerWordsWithResult(podId, ownerId, result) {
  if (myProseEditor) {
    deleteWordAtLocations(result.map(u => u.location))
    store.commit('smartEditConfig/setFillerWordCount', result.length)
    let update = {
      fillerWords: store.state.smartEditConfig.fillerWords
    }
    updatePodcastInfo(update, podId, ownerId)
  }
}

export function enableFillerWordsWithResult(podId, ownerId, result) {
  if (myProseEditor) {
    restoreWordAtLocations(result.map(u => u.location))
    store.commit('smartEditConfig/setFillerWordCount', result.length)
    let update = {
      fillerWords: store.state.smartEditConfig.fillerWords
    }
    updatePodcastInfo(update, podId, ownerId)
  }
}
export function disableFillerWords(podId, ownerId) {
  if (myProseEditor) {
    // let state = myProseEditor.view.state
    let result = searchFillerWordInDoc()
    deleteWordAtLocations(result.map(u => u.location))
    // let transaction = state.tr
    // result.forEach(prob => {
    //   transaction.addMark(prob.from, prob.to, state.schema.marks.deleted.create())
    // })
    // myProseEditor.view.dispatch(transaction)
    store.commit('smartEditConfig/setFillerWordCount', result.length)
    let update = {
      fillerWords: store.state.smartEditConfig.fillerWords
    }
    updatePodcastInfo(update, podId, ownerId)
  }
}

export function enableFillerWords(podId, ownerId) {
  if (myProseEditor) {
    let state = myProseEditor.view.state
    let result = searchFillerWordInDoc()
    restoreWordAtLocations(result.map(u => u.location))
    // let transaction = state.tr
    // result.forEach(prob => {
    //   transaction.removeMark(prob.from, prob.to, state.schema.marks.deleted.create())
    // })
    // myProseEditor.view.dispatch(transaction)
    // store.commit('smartEditConfig/setFillerWordCount', 0)
    store.commit('smartEditConfig/setFillerWordCount', result.length)

    let update = {
      fillerWords: store.state.smartEditConfig.fillerWords
    }
    updatePodcastInfo(update, podId, ownerId)
  }
}
export function trimSilenceWithResult(podId, ownerId, result) {
  if (myProseEditor) {
    let state = myProseEditor.view.state
    let transaction = state.tr
    result.forEach(resultItem => {
      // console.log(resultItem)
      transaction.addMark(resultItem.location.start, resultItem.location.end, state.schema.marks.deleted.create({ type: 'smart' }))
    })
    myProseEditor.view.dispatch(transaction)
    store.commit('smartEditConfig/setTrimmedSilenceDurationInMs', result.reduce((a, b) => {
      // console.log(a, b)
      return a + b.meta.silenceDurationInSeconds
    }, 0))
    let update = {
      trimSilence: store.state.smartEditConfig.trimSilence
    }
    updatePodcastInfo(update, podId, ownerId)
  }
}

export function unTrimSilenceWithResult(podId, ownerId, result) {
  if (myProseEditor) {
    let state = myProseEditor.view.state
    let transaction = state.tr
    result.forEach(prob => {
      transaction.removeMark(prob.from, prob.to, state.schema.marks.deleted.create({ type: 'smart' }))
    })
    myProseEditor.view.dispatch(transaction)
    // store.commit('smartEditConfig/setTrimmedSilenceDurationInMs', 0)
    let update = {
      trimSilence: store.state.smartEditConfig.trimSilence
    }
    updatePodcastInfo(update, podId, ownerId)
  }
}
export function trimSilence(podId, ownerId) {
  if (myProseEditor) {
    let state = myProseEditor.view.state
    let result = searchContinuousSilence()
    let transaction = state.tr
    result.forEach(resultItem => {
      console.log(resultItem)
      transaction.addMark(resultItem.location.start, resultItem.location.end, state.schema.marks.deleted.create({ type: 'smart' }))
    })
    myProseEditor.view.dispatch(transaction)
    store.commit('smartEditConfig/setTrimmedSilenceDurationInMs', result.reduce((a, b) => {
      console.log(a, b)
      return a + b.meta.silenceDurationInSeconds
    }, 0))
    let update = {
      trimSilence: store.state.smartEditConfig.trimSilence
    }
    updatePodcastInfo(update, podId, ownerId)
  }
}

export function unTrimSilence(podId, ownerId) {
  if (myProseEditor) {
    let state = myProseEditor.view.state
    let result = searchSmartEditAppliedSilence(state.doc)
    let transaction = state.tr
    result.forEach(prob => {
      transaction.removeMark(prob.from, prob.to, state.schema.marks.deleted.create({ type: 'smart' }))
    })
    myProseEditor.view.dispatch(transaction)
    // store.commit('smartEditConfig/setTrimmedSilenceDurationInMs', 0)
    let update = {
      trimSilence: store.state.smartEditConfig.trimSilence
    }
    updatePodcastInfo(update, podId, ownerId)
  }
}

export function updatePodcastInfo (update, podId, ownerId, next = () => null) {
  firebase.database().ref(ownerId + '/podcast-info/' + podId + '/smartEditConfig/').update(update).then(function () {
    console.log('Updated podcast info')
    next()
  })
}
