import chunk from 'lodash/chunk'
import CONSTANTS from '@/constants/CONSTANTS'
import { isSilenceSymbol, silenceSymbol } from '@/view/voiceEditor/proseEditor/CONSTANTS'

const uuidv1 = require('uuid/v1')
// Converts to json and performs chunking
export function format (transcript, chunkify, podId) {
  // console.log('string is', transcript)
  console.time('format')
  if (typeof transcript === 'string') {
    // console.log('type is string')
    transcript = JSON.parse(transcript)
  } else if (typeof transcript === 'object' && transcript.content && transcript.content[0] && typeof transcript.content[0] === 'string') {
    // each para was saved as string
    transcript = parsePara(transcript)
    // console.log('final', transcript)
  }
  if (chunkify) {
    transcript = chunk(transcript, 300)
  }
  // console.log(transcript)
  // Raw Prosemirror format
  if (transcript.type === 'doc') {

  } else {
    transcript = transcodeToProse(transcript, podId)
  }
  console.timeEnd('format')
  return { doc: transcript, k: -1 }
}

function transcodeToProse (bigList, podId) {
  let doc = { content: [], type: 'doc' }
  let index = 0
  for (let i in bigList) {
    let x = { content: [], type: 'paragraph' }
    let list = bigList[i]
    for (let w in list) {
      let marks = marksFirebaseToProse(list[w], podId, index)
      x['content'].push({
        marks: marks,
        text: list[w][0] === '_ ' ? silenceSymbol : list[w][0], // transform underscore as silence from backend to silenceSymbol
        type: 'text'
      })
      index += 1
    }
    doc['content'].push(x)
  }
  if (CONSTANTS.ADD_EMPTY_PARAGRAPH) {
    addEmptyParagraph(doc, 0)
    addEmptyParagraph(doc, doc.content.length)
  }
  return doc
}

function marksFirebaseToProse (word, podId, index) {
  // console.log(word)
  let marks = []
  while (word[4].length > 0) {
    let mark = word[4].pop()
    if (mark === '_o') {
      if (isSilenceSymbol(word[0])) {
        marks.push({ type: 'nonText', attrs: { class: 'nonText' } })
      }
      marks.push(
        {
          attrs: {
            aend: word[2],
            astart: word[1],
            uid: podId + '-' + index,
            asource: word[5] || podId
          },
          type: 'info'
        }
      )
    } else if (mark === '_c') {
      marks.push({ type: 'deleted', attrs: { class: 'deleted' } })
    } else if (mark === '_p') {
      marks.push({ type: 'pasted', attrs: { class: 'pasted' } })
    } else if (mark === '_s') {
      marks.push({ type: 'silence', attrs: { class: 'silence' } })
      marks.push({ type: 'info', attrs: { uid: 'silence-' + uuidv1(), astart: 0, aend: 0.1 } })
    }
  }
  // console.log(marks)
  return marks
}

function addEmptyParagraph (doc, index) {
  doc.content.splice(index, 0, { content: [], type: 'paragraph' })
}

export function formatToProse (htmlString, podId) {
  let el = document.createElement('html')
  el.innerHTML = htmlString
  // console.log(el)
  let paras = el.childNodes[1].childNodes
  // console.log(paras)
  let doc = { content: [], type: 'doc' }
  paras.forEach(function (para) {
    // console.log(para)
    let x = { content: [], type: 'paragraph' }
    let list = para.childNodes
    list.forEach(function (word) {
      let res = tagToSpan(word, podId, [], 0, 0, uuidv1())
      // console.log('res', res)
      x['content'] = x['content'].concat(res)
    })
    doc['content'].push(x)
  })
  return { doc, k: -1 }
}

function spanToMarks (word, podId, localMarks, start, end, uid) {
  // console.log('spanToMarks', word, podId, localMarks)
  let marks = []
  marks.push(
    {
      attrs: {
        aend: end || 0,
        astart: start || 0,
        uid: podId + '-' + uid,
        asource: podId
      },
      type: 'info'
    }
  )
  let nMarks = generateFormatMarks(localMarks)
  marks = marks.concat(nMarks)
  let span = {}
  span['marks'] = marks
  span['text'] = word.textContent
  span['type'] = 'text'
  // console.log(word)
  return span
}

function generateFormatMarks (localMarks) {
  // console.log('localMarks', localMarks)
  let marks = []
  if (localMarks.indexOf('B') >= 0) {
    marks.push({ type: 'strong' })
  }
  if (localMarks.indexOf('I') >= 0) {
    marks.push({ type: 'em' })
  }
  if (localMarks.indexOf('U') >= 0) {
    marks.push({ type: 'underline', class: { default: 'underline' } })
  }
  if (localMarks.indexOf('M') >= 0) {
    marks.push({ type: 'highlight', class: { default: 'highlight' } })
  }
  // console.log('marks are', marks)
  return marks
}

function tagToSpan (word, podId, localMarks, start, end, uid) {
  let spans = []
  // console.log('tagName', word.tagName)
  switch (word.tagName) {
    case 'U':
      // console.log('underline')
      localMarks.push('U')
      break
    case 'B':
      // console.log('bold')
      localMarks.push('B')
      break
    case 'MARK':
      // console.log('mark')
      localMarks.push('M')
      break
    case 'I':
      // console.log('em')
      localMarks.push('I')
      break
    case 'SPAN':
      start = parseFloat(word.dataset.start)
      end = parseFloat(word.dataset.end)
      uid = uuidv1()
      break
    case undefined:
      // TEXT
      if (word.length > 0) {
        spans.push(spanToMarks(word, podId, localMarks, start, end, uid))
      // console.log('spans is', spans)
      }
      return spans
  }
  // console.log('before spans,marks', spans, localMarks)
  word.childNodes.forEach(function (node) {
    // console.log('node is', node)
    let res = tagToSpan(node, podId, localMarks.slice(), start, end, uid)
    // console.log('res is', res)
    spans = spans.concat(res)
  })
  // console.log('after', spans, localMarks)
  return spans
}

function parsePara (docc) {
  docc.content = docc.content.map(function (para) { return JSON.parse(para) })
  return docc
}
