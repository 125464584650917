import { doc, music, paragraph, text, image } from '../Nodes'
import {
  deleted,
  pasted,
  silence,
  info,
  strong,
  em,
  underline,
  highlight,
  note,
  nonText,
  transcript,
  hideTranscript,
  comment,
  restored,
  searchHighlight, customTextSelect, musicNotAvailable, searchSelection, imageMark
} from '../Marks'
import { Schema } from 'prosemirror-model'
import { clipPill } from '../CustomNodes'

const nodes = { doc, paragraph, text, music, image, clip: clipPill.getNodeSchema() }
// console.log('nodes', nodes)
const marks = { searchSelection, searchHighlight, imageMark, note, clipHighlight: clipPill.getMark(), clipHoverHighlight: clipPill.getHoverMark(), musicNotAvailable, customTextSelect, comment, transcript, nonText, silence, pasted, hideTranscript, deleted, info, strong, em, underline, highlight, restored }

export const schema = new Schema({ nodes, marks })
