// adding music we just add this note class
export const musicNotAvailable = {
  attrs: {
    class: { default: 'musicNotAvailable' }
  },
  parseDOM: [{ tag: `span.musicNotAvailable` }],
  toDOM: function (mark) {
    return ['span', mark.attrs]
  }
}
