// text that should be hidden *both* in transcript mode & media mode
// different from nonText
// used to hide deleted & cut sections
// for building text-schedule we need non-destructive editing
// this mark makes editing non-destructive
export let showDeleted = true
export const hideTranscript = {
  attrs: {
    class: { default: 'hideTranscript' }
  },
  parseDOM: [{ tag: `span.hideTranscript` }, { tag: `span.hideTranscriptT` }],
  toDOM: (mark) => {
    if (!showDeleted || window.mode === 'editTranscript') {
      mark.attrs.class = 'hideTranscript'
    } else {
      mark.attrs.class = 'hideTranscriptT'
    }
    return ['span', mark.attrs]
  }
}

export function setShowTranscript (showDeletedTruth) {
  showDeleted = showDeletedTruth
}
