<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 4C8.08172 4 4.5 7.58172 4.5 12C4.5 16.4183 8.08172 20 12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4ZM2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2071 9.29289C16.5976 9.68342 16.5976 10.3166 16.2071 10.7071L12.2071 14.7071C11.8166 15.0976 11.1834 15.0976 10.7929 14.7071L8.79289 12.7071C8.40237 12.3166 8.40237 11.6834 8.79289 11.2929C9.18342 10.9024 9.81658 10.9024 10.2071 11.2929L11.5 12.5858L14.7929 9.29289C15.1834 8.90237 15.8166 8.90237 16.2071 9.29289Z" />
  </svg>
</template>

<script>
export default {
  name: 'Check',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
