import moment from 'moment'
import hhmmss from '@streammedev/hhmmss'
import { Document, Packer, Paragraph, TextRun } from 'docx'
import { isSilenceSymbol, silenceSymbol } from '@/view/voiceEditor/proseEditor/CONSTANTS'
import { computeArrangement } from '@/view/voiceEditor/proseEditor/util/transoformationUtility'

const fillerWords = ['uh', 'um', 'basically', 'right', '_', silenceSymbol.trim()]
const nonTextMarks = ['deleted', 'hideTranscript']
const textMarks = ['transcript']
export function humanLength (timeInSec) {
  if (timeInSec >= 86400) {
    var hours = Math.trunc(moment.duration(timeInSec * 1000).asHours())
    return hours + ':' + moment.utc(timeInSec * 1000).format('mm:ss')
  } else if (timeInSec >= 3600) {
    return moment.utc(timeInSec * 1000).format('HH:mm:ss')
  }
  return moment.utc(timeInSec * 1000).format('mm:ss')
}

export function downloadAsFile (text, format = 'txt', title = 'Spext') {
  let blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
  let a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = `${title}.${format}`
  a.target = '_blank'
  a.click()
}

export function downloadBlob ({ blob, title = 'Spext', format = 'doc' }) {
  let a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = `${title}.${format}`
  a.target = '_blank'
  a.click()
}

export function formatAsTXT (json) {
  let simpleList = transcodeToSimpleList(json)
  let result = ''
  simpleList.forEach(function (item) {
    result += item.text
  })
  return result
}
export function formatAsSRT (state) {
  // console.log(transcodeToGrouped(transcodeToSimpleList(json), false))

  let simpleList = transcodeToSimpleListNew(state)
  let groupedJSON = transcodeToGrouped(simpleList)

  let i = 0
  let srt = ''
  while (i < groupedJSON.length) {
    srt += (i + 1) + '\n'
    srt += groupedJSON[i].start + ' --> ' + groupedJSON[i].end + '\n'
    srt += groupedJSON[i].text + '\n\n'
    i += 1
  }
  return srt
}

// For use in FE video editor
export function formatAsSubtitle (json) {
  return transcodeToGrouped(transcodeToSimpleList(json), false)
}

export function formatAsSubtitleNew (state) {
  return transcodeToGrouped(transcodeToSimpleListNew(state), false)
}

export function formatAsVTT (state) {
  // console.log(json)
  let simpleList = transcodeToSimpleListNew(state)
  let groupedJSON = transcodeToGrouped(simpleList)
  console.log(groupedJSON)
  let i = 0
  let vtt = 'WEBVTT\n'
  while (i < groupedJSON.length) {
    vtt += groupedJSON[i].start + ' --> ' + groupedJSON[i].end + '\n'
    vtt += groupedJSON[i].text + '\n\n'
    i += 1
  }
  return vtt
}

export function formatAsDOC ({ json, title, type }) {
  // Create document
  const doc = new Document()
  let paragraphs = []
  let emptyParagraph = new Paragraph('')
  json.content.forEach(function (para) {
    if (para.content) {
      let paragraph = new Paragraph('')
      para.content.forEach(function (text) {
        let dText
        if (isTranscriptWorthy(text)) {
          // console.log(text.text, text.marks)
          dText = new TextRun({
            text: text.text,
            underline: containsMark(text.marks, 'underline') ? {} : '',
            bold: !!containsMark(text.marks, 'strong'),
            italics: !!containsMark(text.marks, 'em')
          })
        }
        if (dText) {
          paragraph.addChildElement(dText)
        }
      })
      paragraphs.push(paragraph)
      paragraphs.push(emptyParagraph)
    }
  })
  doc.addSection({
    children: paragraphs
  })
  // Used to export the file into a .docx file
  Packer.toBlob(doc).then(blob => {
    downloadBlob({ blob: blob, title, type })
    console.log('Document created successfully')
  })
}
export function exportAsFile (state, exportedFileName, exportFileType) {
  if (state) {
    let text = null
    switch (exportFileType) {
      case 'txt':
        text = formatAsTXT(state.doc.toJSON())
        break
      case 'srt':
        text = formatAsSRT(state)
        break
      case 'vtt':
        text = formatAsVTT(state)
        break
      case 'doc':
        formatAsDOC({ json: state.doc.toJSON(), title: exportedFileName, exportFileType })
        break
    }
    if (text) {
      downloadAsFile(text, exportFileType, exportedFileName)
    }
  }
}
// input: prose doc
// output: [{'start':0,'end':0.3,'text':'Thermodynamic'},...,8: {start: 2.4, end: 3.3, text: "thermodynamics. "}]
// required for srt, vtt
// also filter '_ '
export function transcodeToSimpleList (doc) {
  let result = []
  let changedText = ''
  doc.content.forEach(function (para) {
    if (para.content) {
      para.content.forEach(function (text) {
        // console.log(text.text)
        if (isTranscriptWorthy(text)) {
          // console.log(text.text, text.marks)
          text.marks.forEach(function (mark) {
            if (mark.type === 'info') {
              result.push({ start: mark.attrs.astart, end: mark.attrs.aend, text: changedText + text.text })
              changedText = ''
            } else if (textMarks.indexOf(mark.type) >= 0) {
              changedText = text.text
            }
          })
        }
      })
    }
  })
  // console.log('simple list', result)
  return result
}
export function transcodeToSimpleListNew (state) {
  let result = []
  let schdeuleArray = computeArrangement(state)
  schdeuleArray.forEach(node => {
    if (node.id !== 'music)' && node.node.text) {
      if (!isSilenceSymbol(node.node.text)) {
        let end = node.time + node.duration
        result.push({ start: node.time, end: end, text: node.node.text })
      }
    }
  })

  return result
}

// input: simpleList
// output: [0: {start: "00:00:00,100", end: "00:00:03,300", text: "In this house, we obey the laws of thermodynamics. "}]
// required for srt, vtt
export function transcodeToGrouped (json, format = true) {
  // console.log('simpleList', json)
  let groupedJSON = []
  let i = 0
  while (i < json.length) {
    let start = json[i].start
    let end = json[i].end
    let text = json[i].text
    i++
    while ((end - start < 4) && (text.length <= 50) && (i < json.length)) {
      end = json[i].end
      text += json[i].text
      i++
    }
    groupedJSON.push({
      start: format ? formatTime(start) : start,
      end: format ? formatTime(end) : end,
      text: text
    })
  }
  // console.log(groupedJSON)
  return groupedJSON
}

// input: 0.1
// output: 00:00:00,100
// required for srt, vtt
export function formatTime (time) {
  time = String(time)
  let milli = ','
  if (time.split('.').length > 1) {
    milli += (time.split('.')[1] + '000').slice(0, 3)
  } else {
    milli += '000'
  }
  return hhmmss(time) + milli
}
export function statusToPercentage (status, timeElapsed, totalExpectedTime) {
  if (status === 100) {
    status = 199
  }
  let statuses = {
    198: 0,
    199: 0,
    200: 5,
    201: 10,
    202: 20,
    203: 80,
    204: 100,
    205: 100
  }
  // let timeArray = [0, 5, 5, 10, 60, 20]
  let percentage = (timeElapsed / totalExpectedTime)
  let statusProgress = statuses[status - 1] / 100
  let nextProgress = statuses[status] / 100
  if (percentage < 1) {
    return (percentage * (nextProgress - statusProgress)) + statusProgress
  } else {
    return statusProgress
  }
}
export function humanTime (time) {
  /* convert in localtime */
  time = moment(time).format()
  if (moment().startOf('day').format() < time) {
    /* time in minutes '2:42 pm' */
    return moment(time).fromNow()
  } else if (moment().startOf('week').format() < time) {
    /* time in day name 'Thu' */
    return moment(time).fromNow()
  } else if (moment().startOf('year').format() < time) {
    /* time in date Feb 17 */
    return moment(time).fromNow()
  }
  return moment(time).format('L')
}

function containsAnyFillerMark (marks) {
  if (!marks) { return false };
  return marks.some(function (mark) { if (nonTextMarks.indexOf(mark.type) >= 0) return true })
}

function containsMark (marks, searchedMark) {
  if (!marks) { return false };
  return marks.some(function (mark) { if (searchedMark === mark.type) return true })
}

function strip (str) {
  return str.replace(/^\s+|\s+$/g, '')
}

function isTranscriptWorthy (text) {
  // localAudio node
  if (!text.text) {
    return false
  }

  // filler word
  if (fillerWords.indexOf(strip(text.text)) >= 0 || isSilenceSymbol(text.text)) {
    return false
  }

  // deleted mark
  // TODO - Ashu changed here for the bug fix of doc export
  if (text.marks && containsAnyFillerMark(text.marks)) {
    return false
  }

  // in b/w text music (wavefont)
  // https://www.npmjs.com/package/wavefont
  // (0x200-128)=384
  // (0x200+127)=639
  // space is also inserted
  if ((text.text.charCodeAt(0) >= 384 && text.text.charCodeAt(0) <= 639)) {
    return false
  }

  // if it is a space & note mark is added
  if (text.text === ' ' && containsMark(text.marks, 'note')) {
    return false
  }
  // console.log('text', text.text)
  return true
}

export const copyToClipboard = str => {
  const el = document.createElement('textarea') // Create a <textarea> element
  el.value = str // Set its value to the string that you want copied
  el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
  el.style.position = 'absolute'
  el.style.left = '-9999px' // Move outside the screen to make it invisible
  document.body.appendChild(el) // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false // Mark as false to know no selection existed before
  el.select() // Select the <textarea> content
  document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el) // Remove the <textarea> element
  if (selected) { // If a selection existed before copying
    document.getSelection().removeAllRanges() // Unselect everything on the HTML document
    document.getSelection().addRange(selected) // Restore the original selection
  }
}
export const ACTUAL_MODES = {
  'editTranscript': 'Mostly transcription',
  'editMedia': 'Mostly podcast editing'
}

export const EXTRA_MODES = {
  // 'both': 'Both Transcription &amp; Podcast Editing',
  'voiceMemo': 'Editing a voice-over/ voice memo',
  'subtitle': 'Creating Subtitle'
}

export const DEFAULT_MODE = 'editMedia'

export function getElementsByAttribute (attr, val) {
  let nodeList = document.getElementsByTagName('*')
  let nodeArray = []

  // eslint-disable-next-line no-cond-assign
  for (var i = 0, elem; elem = nodeList[i]; i++) {
    switch (typeof val) {
      case 'undefined':
        if (typeof elem.getAttribute(attr) !== 'undefined') nodeArray.push(elem)
        break
      default:
        if (elem.getAttribute(attr) === val) nodeArray.push(elem)
        break
    }
  }

  return nodeArray
}

const uidPalette = [
  '#000228',
  '#E2462C',
  '#52AADB',
  '#F8C450',
  '#EC5B16',
  '#F20D70',
  '#B4C236',
  '#53B745',
  '#8338EC',
  '#AE33D9',
  '#233AC9',
  '#12334B'
]
export function uniqueUidColor(uid) {
  if (!uid) return '#000228'
  let colorIndex = uid.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) % uidPalette.length
  return uidPalette[colorIndex]
}
