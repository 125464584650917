// atleast 1 one block, so using block+
// block is any kind of node
export const doc = {
  content: 'block+',
  attrs: {
    volume: {
      default: 1
    },
    docVersion: {
      default: 2
    }
  }
}
