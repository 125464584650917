// excludes:
// '', therefore multiple marks of same type can coexist,as long as they have different attribute
// (_)  - underscore only one mark on the node
// default - one mark of a type

export const comment = {
  attrs: {
    cid: {} // unique comment id
  },
  inclusive: false,
  excludes: '', // multiple comments can be applied to one node
  group: 'annotation',
  parseDOM: [{
    tag: 'span.comment[data-cid]',
    getAttrs (dom) {
      return {
        cid: dom.dataset.cid
      }
    }
  }],
  toDOM (mark) {
    return ['span', {
      class: 'comment',
      'data-cid': mark.attrs.cid
    }]
  }
}
