import { EditorView } from 'prosemirror-view'
import { v1 as uuidv1 } from 'uuid'
import Pills from '../pills'

// class specific to clip pills. This is the initializer class which can be written for
// a specific pill usecase. For example, clip pills has their own specific way of creating
// pills with specific marks and node definitions. This will be different for music or any
// other pills.
class ClipPill extends Pills {
  public type: string = 'clip'

  constructor() {
    super('clip', true)
  }
  // Create an interaction layer for the pills. Will update the state on the basis of function parameters.
  public createPills(view: EditorView, startLoc: number, endLoc: number, uid = uuidv1()) {
    const { type } = this
    let startPill = view.state.schema.node(type,
      {
        class: `${type}-pill`,
        title: `start${type}`,
        type: `start${type}`,
        id: `start${type}-${uid}`
      },
      view.state.schema.text('start'),
      []
    )

    let endPill = view.state.schema.node(type,
      {
        class: `${type}-pill end`,
        title: `end${type}`,
        type: `end${type}`,
        id: `end${type}-${uid}`
      },
      view.state.schema.text('end'),
      []
    )

    let newPillHighlight = view.state.schema.mark(`${type}Highlight`, {
      class: `${type}-hl`,
      cliphl_uid: `${type}hl-${uid}`
    })

    if (startLoc < 0 || endLoc > view.state.doc.content.size + 1) {
      startLoc = 0
      endLoc = view.state.doc.content.size
    }
    let transaction = view.state.tr.addMark(startLoc, endLoc, newPillHighlight).insert(startLoc, startPill).insert(endLoc + startPill.nodeSize, endPill)

    return this.dispatchPillsAndEvents(view, uid, startPill.nodeSize, transaction)
  }
}

export default ClipPill
