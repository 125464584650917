import store from '@/services/store'

export const defaultState = () => ({
  authToken: undefined
})
export default {
  namespaced: true,
  state: defaultState,
  getters: {
    authToken: state => state.authToken
  },
  mutations: {
    setCustomToken: function(state, payload) {
      state.authToken = payload
    }
  },
  actions: {
    setCustomToken: function({ commit }, payload) {
      commit('setCustomToken', payload)
    }
  }
}
