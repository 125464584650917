// text* is coming from text.js it can be used as inline* ( not sure )
// zero or more text
// group it with other text.js "inline group"
import uuidv1 from 'uuid/v1'

export const music = {
  content: 'text*',
  inline: true, // content will appear next to each other
  attrs: {
    class: { default: 'localaudio' },
    type: { default: 'music' },
    title: { default: 'Audio' },
    uid: { default: uuidv1() },
    fadeIn: { default: {
      start: 0,
      end: 56,
      duration: 0
    } },
    fadeOut: { default: {
      end: 0,
      duration: 0
    } }

  },
  draggable: true,
  group: 'inline',
  selectable: false,
  atom: true, // it can't have directly editable content
  parseDOM: [
    {
      tag: 'text',
      getAttrs: function getAttrs (dom) { // dom should have these attrs for it to qualify as music node
        let ret = {
          class: dom.getAttribute('class'),
          title: dom.getAttribute('title'),
          fadeIn: {
            start: 0,
            end: Number(dom.getAttribute('finend')), // fadeIn.end is written as finend
            duration: Number(dom.getAttribute('finduration'))
          },
          fadeOut: {
            end: 0,
            duration: Number(dom.getAttribute('foutduration'))
          }
        }
        return ret
      }
    }
  ],
  toDOM: function toDOM (node) {
    // this mark the start of music node, we change this using css
    return [
      'span',
      {
        class: 'music-icon',
        finend: node.attrs.fadeIn.end, // when we write it to dom we use variable name as finend and
        finduration: node.attrs.fadeIn.duration, // finduration
        foutduration: node.attrs.fadeOut.duration,
        title: node.attrs.title
      }
    ]

    // return ['text', {class: 'fal fa-music music-icon',
    //   finend: node.attrs.fadeIn.end,
    //   finduration: node.attrs.fadeIn.duration,
    //   foutduration: node.attrs.fadeOut.duration}]
    // return ['i', {class: 'fal fa-music'}, ['text', node.attrs, node.content.content[0].text]]
  }
}
