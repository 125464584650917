import { TextSelection, NodeSelection, EditorState } from 'prosemirror-state'
import { snapSelection } from '../plugins/handleSelection'
import uuidv1 from 'uuid/v1'
import debounce from 'lodash/debounce'
import { createSilenceNode } from '../Nodes/silenceNode'
import { isSilenceSymbol, silenceSymbol } from '@/view/voiceEditor/proseEditor/CONSTANTS'
import { getElementsByAttribute } from '@/utilities/utility'
import store from '@/services/store'
import {
  createTextNode,
  getInfoMarkFromTextNode,
  getNonInfoMarksFromTextNode
} from '@/view/voiceEditor/proseEditor/Nodes/textNode'
import { Node } from 'prosemirror-model'
import { schema } from '@/view/voiceEditor/proseEditor/Schema/schema'
export const marksHiddenInEditMedia = ['deleted', 'hideTranscript']
const magicNumber = 100

export let myProseEditor = null
export function initProsEditor(proseEditorInstance) {
  myProseEditor = proseEditorInstance
}
// when using this util, make sure you get pos after calling this
export function moveCursorToBoundary (view) {
  /**
   * We want to move cursor to word boundry, not in the middle
   * This function change the user selection to select the boundry
   */
  const doc = view.state.doc
  const anchorPos = view.state.selection.anchor // place where you start the selection
  const headPos = view.state.selection.head // place where you end the selection
  let newPosition = null
  let temp = null
  // console.log(anchor, head);
  if (anchorPos - headPos === 0) {
    // * user has not selected any text
    temp = doc.resolve(anchorPos)
    if (temp.textOffset === 0) {
      //* user is already at boundary
      return
    } else {
      //* user is inside node; resolve to right boundary
      newPosition = doc.resolve(temp.pos + temp.nodeAfter.nodeSize)
    }
  } else {
    // * user has selected text
    if (anchorPos < headPos) {
      // * selecting forwards; paste after right boundary(headPos)
      newPosition = doc.resolve(headPos)
    } else {
      // * selecting forwards; paste after right boundary(anchorPos)
      newPosition = doc.resolve(anchorPos)
    }
  }
  view.dispatch(
    // this will not make any selection, it will just jump at the end of the word
    view.state.tr.setSelection(new TextSelection(newPosition, newPosition))
  )
}
export function cleanDocOfOrphanMusicNotes (view) {
  /**
   * When you click on the music icon and hit delete
   * This function remove  the music nodes  from the doc(attached to this icon)
   * This is for background music
   */
  let sizeTravelled = 0
  let anchorLocation = 0
  let seenMusicNode = false
  let orphanMtextStartLoc = -1
  let orphanMtextEndLoc = -1
  if (!document._isPillBeingDragged) {
    view.state.doc.content.forEach(paraNode => {
      anchorLocation = sizeTravelled
      sizeTravelled += 2
      paraNode.content.forEach(textNode => {
        anchorLocation = sizeTravelled
        sizeTravelled += textNode.nodeSize
        let token = false
        let endMusicNodeToken = false
        textNode.marks.every(mark => {
          if (mark.type.name === 'deleted') {
            token = true
            return false
          }
          if (mark.attrs != null && mark.attrs.uid != null && mark.attrs.uid.includes('endmus-')) {
            if (!seenMusicNode) {
              // console.log('endMusicNodeToken', anchorLocation, anchorLocation + textNode.nodeSize, textNode)

              endMusicNodeToken = true
            }
          } else if (mark.attrs != null && mark.attrs.uid != null && mark.attrs.uid.includes('music-')) {
            token = true
            seenMusicNode = true
            orphanMtextStartLoc = -1
            orphanMtextEndLoc = -1
          } else if (mark.type.name === 'note') {
            token = true
            if (!seenMusicNode) {
              if (orphanMtextStartLoc === -1) {
                orphanMtextStartLoc = anchorLocation
              }
            }
            orphanMtextEndLoc = anchorLocation
          }
          return true
        })
        if (!token) {
          seenMusicNode = false
          if (orphanMtextStartLoc > -1 && orphanMtextEndLoc > -1) {
            view.dispatch(
              // we don't allow undo on this deletion - addToHistory
              view.state.tr.setMeta('addToHistory', false).removeMark(orphanMtextStartLoc - 1, orphanMtextEndLoc, view.state.schema.marks['note'])
            )
            orphanMtextStartLoc = -1
            orphanMtextEndLoc = -1
          }
        }
        if (endMusicNodeToken) {
          // let endMusicPosition = view.posAtDOM(getElementsByAttribute('uid', 'endmus-' + textNode.attrs.uid)[0])
          // console.log('endMusicNodeToken', endMusicPosition)
          // view.dispatch(
          //   view.state.tr.setMeta('addToHistory', false).delete(anchorLocation - 1, anchorLocation + textNode.nodeSize - 1)
          // )
        }
      })
    })
    cleanDocOfOrphanWaveforms(view)
  }
}
function cleanDocOfOrphanWaveforms (view) {
  /** This function remove music fonts
   * This is for inline music with music fonts visible in the doc
  */
  let sizeTravelled = 0
  let anchorLocation = 0
  let orphanMtextStartLoc = -1
  let orphanMtextEndLoc = -1
  view.state.doc.content.forEach(paraNode => {
    anchorLocation = sizeTravelled
    sizeTravelled += 2
    paraNode.content.forEach(textNode => {
      anchorLocation = sizeTravelled
      sizeTravelled += textNode.nodeSize
      let seenNote = false
      let seenMText = false

      textNode.marks.every(mark => {
        if (mark.type.name === 'deleted') {
          // return false
        }
        if (mark.type.name === 'note') {
          seenNote = true
        }
        if (mark.attrs != null && mark.attrs.uid != null && mark.attrs.uid.includes('mtext-')) {
          seenMText = true
        }
        return true
      })
      if (seenMText && !seenNote) {
        if (orphanMtextStartLoc === -1) {
          orphanMtextStartLoc = anchorLocation
        }
        orphanMtextEndLoc = anchorLocation
      } else {
        if (orphanMtextStartLoc > -1 && orphanMtextEndLoc > -1) {
          view.dispatch(
            // not possible to do undo on this operation
            view.state.tr.setMeta('addToHistory', false).delete(orphanMtextStartLoc - 1, orphanMtextEndLoc)
          )
        }
        orphanMtextStartLoc = -1
        orphanMtextEndLoc = -1
      }
    })
  })
}
export function moveCursorToLeftBoundary (view) {
  const doc = view.state.doc
  const anchorPos = view.state.selection.anchor
  const headPos = view.state.selection.head
  let temp = null
  let newPosition = null
  // console.log('here', anchorPos, headPos)
  if (anchorPos - headPos === 0) {
    // * user has not selected any text
    temp = doc.resolve(anchorPos)
    if (temp.textOffset === 0) {
      //* user is already at boundary
      // return
      newPosition = doc.resolve(temp.pos - temp.nodeBefore.nodeSize) // look here for difference in moveCursor functions
    } else {
      //* user is inside node; resolve to right boundary
      newPosition = doc.resolve(temp.pos - temp.textOffset - 2)
    }
  } else {
    // * user has selected text
    if (anchorPos < headPos) {
      // * selecting forwards; paste after right boundary(headPos)
      newPosition = doc.resolve(anchorPos)
    } else {
      // * selecting forwards; paste after right boundary(anchorPos)
      newPosition = doc.resolve(headPos)
    }
  }
  view.dispatch(
    view.state.tr.setSelection(new TextSelection(newPosition, newPosition))
  )
}
let pos = 0 // global variable
function moveCursorInDirection (view) {
  /**
   * helper function for debouncedSelection
   */
  // console.log(view)
  const doc = view.state.doc
  const anchorPos = view.state.selection.anchor
  const headPos = view.state.selection.head
  // console.log('here', anchorPos, headPos)
  if (anchorPos - headPos !== 0 || pos === 0) {
    return
  }
  let newPosition = null
  let prevPosition = null
  // * user has not selected any text
  newPosition = doc.resolve(anchorPos)
  if (pos > 0) {
    for (;pos > 0 && newPosition; pos--) {
      prevPosition = newPosition
      newPosition = getNextNodeWithoutMark(view, newPosition.pos, marksHiddenInEditMedia, 'hide', true)
    }
  } else {
    for (;pos < 0 && newPosition; pos++) {
      prevPosition = newPosition
      newPosition = getPrevNodeWithoutMark(view, newPosition.pos, marksHiddenInEditMedia, 'hide')
    }
  }
  if (newPosition) {
    prevPosition = newPosition
  }
  // Reset the steps user has moved
  pos = 0
  if (prevPosition) {
    view.dispatch(view.state.tr.setSelection(new TextSelection(prevPosition, prevPosition)))
  }
}

export function calculatePositionAndSelect (view, direction) {
  if (direction === 'right') {
    pos += 1
  } else if (direction === 'left') {
    pos -= 1
  }
  debouncedSelection(view)
}
// when you keep pressing the right key/ left key
// check handleCursor.js for the use of this function
let debouncedSelection = debounce(moveCursorInDirection, 50, { 'leading': true })

/**
 * if anchor === head then make selection
 * i.e person has not selected anything but generating cut/other event
 */
export function makeSelection (state, dispatch) {
  const doc = state.doc
  const anchor = doc.resolve(state.selection.anchor)
  const head = doc.resolve(state.selection.head)
  let start = null
  let end = null
  if (anchor.pos === head.pos) {
    start = doc.resolve(anchor.pos - anchor.textOffset)
    if (head.nodeAfter === null) {
      end = doc.resolve(head.pos)
    } else {
      end = doc.resolve(head.pos + head.nodeAfter.nodeSize) // select the word
    }
    dispatch(state.tr.setSelection(new TextSelection(start, end)))
  } else {
  }
}
export function getTextSelectionForLocation (from, to, view) {
  const doc = view.state.doc
  let anchor = doc.resolve(from)
  let head = doc.resolve(to)
  return snapSelection(view, anchor, head)
}

/**
 * calculates the time of audio deleted from the document
 * slice is a fragment, can make json slice by Node.fromJSON
 */
export function calculateTime (slice, sign) {
  let time = 0
  slice.content.content.forEach(function (paraNode) {
    if (paraNode.type.name === 'text') {
      time += calculateTimeFromTextNode(paraNode)
    } else {
      paraNode.content.content.forEach(function (textNode) {
        time += calculateTimeFromTextNode(textNode)
      })
    }
  })
  return time * sign
}

export function calculateTimeFromTextNode (textNode) {
  let time = 0
  textNode.marks.forEach(function (mark) {
    if (mark.type.name === 'info') {
      time += mark.attrs.aend - mark.attrs.astart
    }
  })
  return time
}

/**
 * add deleted mark
 */
export function addMarkDeleted (
  transactionMeta, // json of key and value
  state,
  dispatch,
  startPos,
  endPos
) {
  if (dispatch) {
    dispatch(
      // Adding to transcation and we will catch it in proseEditor.js with callbacks getMeta functions
      state.tr
        .setMeta(transactionMeta.key, transactionMeta.value)
        .addMark(startPos, endPos, state.schema.marks.deleted.create())
    )
  }
}
export function doesMusicExists (slice) {
  let truth = false
  slice.content.content.forEach(function (paraNode) {
    if (paraNode.type.name === 'text') {
      paraNode.marks.forEach(function (mark) {
        if (mark.type.name === 'note') {
          truth = true
        }
      })
    } else {
      paraNode.content.content.forEach(function (textNode) {
        textNode.marks.forEach(function (mark) {
          if (mark.type.name === 'note') {
            truth = true
          }
        })
      })
    }
  })
  return truth
}
export function addMarkNonText (
  state,
  dispatch,
  startPos,
  endPos
) {
  if (dispatch) {
    dispatch(
      state.tr
      // .setMeta(transactionMeta.key, transactionMeta.value)
        .addMark(startPos, endPos, state.schema.marks.nonText.create())
    )
  }
}

/**
 * remove deleted mark
 */
export function removeMarkDeleted (state, dispatch, startPos, endPos) {
  if (dispatch) {
    dispatch(
      state.tr.addMark(startPos, endPos, state.schema.marks.restored.create()).removeMark(startPos, endPos, state.schema.marks.deleted.create())
        .removeMark(startPos, endPos, state.schema.marks.deleted.create({ type: 'smart' }))

    )
  }
}

// Call this to calculate total deleted text duration in a text editor
export function computeDeletedTextDuration (view) {
  let sizeTravelled = 0
  let totalTime = 0
  view.state.doc.content.forEach(function (paraNode) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      sizeTravelled += textNode.nodeSize
      textNode.marks.every(function (mark) {
        if (mark.type.name === 'deleted') { // only for deleted
          textNode.marks.forEach(function (mark) {
            if (mark.type.name === 'info') {
              let duration = mark.attrs.aend - mark.attrs.astart
              // if (mark.attrs.uid.split('-')[0] === 'silence') {
              //   duration = 0.1
              // }
              totalTime += duration
            }
          })
          return true
        }
        return true
      })
    })
  })
  return totalTime
}
export function computeAppliedMusicDuration (currentMusicNodeUid, view) {
  let sizeTravelled = 0
  let tempEndDuration = 0
  let audioduration = 0
  let seenCurrentNoteMark = false
  let seenDeleted = false
  view.state.doc.content.forEach((paraNode) => {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach((textNode) => {
      sizeTravelled += textNode.nodeSize
      seenCurrentNoteMark = false
      seenDeleted = false
      tempEndDuration = 0
      textNode.marks.forEach((mark) => {
        if (!seenDeleted) {
          if (mark.type.name === 'deleted') { // deleted node
            seenDeleted = true
          } else if (mark.type.name === 'note') {
            if (mark.attrs.note_uid === currentMusicNodeUid) {
              seenCurrentNoteMark = true
            }
          } else if (mark.type.name === 'info' && seenCurrentNoteMark) { // normal text
            tempEndDuration = mark.attrs.aend - mark.attrs.astart
          }
        }
      })
      if (seenCurrentNoteMark && !seenDeleted) {
        audioduration += tempEndDuration
      }
    })
  })
  return audioduration
}
export function reComputeMusicReach(view) {
  let transaction = view.state.tr
  let sizeTravelled = 0
  view.state.doc.content.forEach((paraNode) => {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach((node) => {
      sizeTravelled += node.nodeSize
      if (node.attrs.type === 'music') {
        let {
          startPos, endPos
        } = findAllNodesWithCurrentMusic(node, view)
        if (startPos > -1 && endPos > -1) {
          removeMarkAtLocationInTransaction(startPos, endPos, view, transaction, 'musicNotAvailable')
        }
        let {
          nonMusicStartPos, nonMusicEndPos
        } = findNodesWithNoMusicReach(node, view)
        // console.log(nonMusicEndPos, nonMusicStartPos)
        if (nonMusicEndPos > -1 && nonMusicStartPos > -1) {
          addMarkAtLocationInTransaction(nonMusicStartPos, nonMusicEndPos, view, transaction, 'musicNotAvailable', { class: 'musicNotAvailable' })
        }
      }
    })
  })
  view.updateState(view.state.apply(transaction.setMeta('addToHistory', false)))
}
export function reComputeMusicReachForMusicNode(currentMusicNode, view) {
  let transaction = view.state.tr
  let {
    startPos, endPos
  } = findAllNodesWithCurrentMusic(currentMusicNode, view)
  if (startPos > -1 && endPos > -1) {
    removeMarkAtLocationInTransaction(startPos, endPos, view, transaction, 'musicNotAvailable')
  }
  let {
    nonMusicStartPos, nonMusicEndPos
  } = findNodesWithNoMusicReach(currentMusicNode, view)
  if (nonMusicEndPos > -1 && nonMusicStartPos > -1) {
    addMarkAtLocationInTransaction(nonMusicStartPos, nonMusicEndPos, view, transaction, 'musicNotAvailable', { class: 'musicNotAvailable' })
  }
  view.updateState(view.state.apply(transaction))
}
export function findNodesWithNoMusicReach(currentMusicNode, view) {
  let sizeTravelled = 0
  let tempEndDuration = 0
  let audioduration = 0
  let seenCurrentNoteMark = false
  let seenDeleted = false
  let nonMusicStartPos = -1
  let nonMusicEndPos = -1
  view.state.doc.content.forEach((paraNode) => {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach((textNode) => {
      sizeTravelled += textNode.nodeSize
      seenCurrentNoteMark = false
      seenDeleted = false
      tempEndDuration = 0
      textNode.marks.forEach((mark) => {
        if (!seenDeleted) {
          if (mark.type.name === 'deleted') { // deleted node
            seenDeleted = true
          } else if (mark.type.name === 'note') {
            if (mark.attrs.note_uid === currentMusicNode.attrs.uid) {
              seenCurrentNoteMark = true
            }
          } else if (mark.type.name === 'info' && seenCurrentNoteMark) { // normal text
            tempEndDuration = mark.attrs.aend - mark.attrs.astart
          }
        }
      })
      if (seenCurrentNoteMark && !seenDeleted) {
        audioduration += tempEndDuration
        if (audioduration > (currentMusicNode.marks[0].attrs.aend - currentMusicNode.marks[0].attrs.astart)) {
          if (nonMusicStartPos === -1) {
            nonMusicStartPos = sizeTravelled
          }
          nonMusicEndPos = sizeTravelled - textNode.nodeSize
          // console.log(textNode, 'false', audioduration, currentMusicNode.marks[0].attrs.aend)
        } else {
          // console.log(textNode, 'true', audioduration, currentMusicNode.marks[0].attrs.aend)
        }
      }
    })
  })
  return {
    nonMusicStartPos, nonMusicEndPos
  }
}
export function findAllNodesWithCurrentMusic(currentMusicNode, view) {
  let sizeTravelled = 0
  let tempEndDuration = 0
  let seenCurrentNoteMark = false
  let startPos = -1
  let endPos = -1
  view.state.doc.content.forEach((paraNode) => {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach((textNode) => {
      sizeTravelled += textNode.nodeSize
      seenCurrentNoteMark = false
      tempEndDuration = 0
      textNode.marks.forEach((mark) => {
        if (mark.type.name === 'deleted') { // deleted node
          return false
        } else if (mark.type.name === 'note') {
          if (mark.attrs.note_uid === currentMusicNode.attrs.uid) {
            seenCurrentNoteMark = true
          }
        } else if (mark.type.name === 'info' && seenCurrentNoteMark) { // normal text
          tempEndDuration = mark.attrs.aend - mark.attrs.astart
        }
      })
      if (seenCurrentNoteMark) {
        if (startPos === -1) {
          startPos = sizeTravelled
        }
        endPos = sizeTravelled
      }
    })
  })
  return {
    startPos, endPos
  }
}
export function computeTotalDuration (view) {
  /**
   * not counting deleted sections
   */
  let sizeTravelled = 0
  let totalTime = 0
  view.state.doc.content.forEach(function (paraNode) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      sizeTravelled += textNode.nodeSize
      textNode.marks.every(function (mark) {
        if (mark.type.name === 'deleted') { // ignore the deleted
          return false
        }

        if (mark.attrs != null && mark.attrs.uid != null) {
          if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
            let duration = mark.attrs.aend - mark.attrs.astart
            // if (mark.attrs.uid.split('-')[0] === 'silence') {
            //   duration = 0.1
            // }
            totalTime += duration
          }
        }
        return true
      })
    })
  })
  return totalTime
}

// Not using it
export function computeCurrentCursorTime (view) {
  let sizeTravelled = 0
  let totalTime = 0
  view.state.doc.content.forEach(function (paraNode) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      sizeTravelled += textNode.nodeSize
      textNode.marks.every(function (mark) {
        if (mark.type.name === 'deleted') {
          return false
        }

        if (mark.attrs != null && mark.attrs.uid != null) {
          if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
            let duration = mark.attrs.aend - mark.attrs.astart
            // if (mark.attrs.uid.split('-')[0] === 'silence') {
            //   duration = 0.1
            // }
            if (sizeTravelled <= view.state.selection.anchor) {
              totalTime += duration
            }
          }
        }
        return true
      })
    })
  })
  return totalTime
}
export function computeTimeAtPos (pos, view) {
  let sizeTravelled = 0
  let totalTime = 0
  view.state.doc.content.forEach(function (paraNode) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      sizeTravelled += textNode.nodeSize
      if (!textNode.marks || !textNode.marks.length) return
      textNode.marks.every(function (mark) {
        if (mark.type.name === 'deleted') {
          return false
        }

        if (mark.attrs != null && mark.attrs.uid != null) {
          if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
            let duration = mark.attrs.aend - mark.attrs.astart
            // if (mark.attrs.uid.split('-')[0] === 'silence') {
            //   duration = 0.1
            // }
            if (sizeTravelled <= pos) {
              totalTime += duration
            }
          }
        }
        return true
      })
    })
  })
  return totalTime
}

// computeTotalDuration
// computeCurrentCursorTime
// calculateParaFromLocation
// getMusicAndAudioSource
export function computeOnDispatchTransaction (view) {
  /**
   * When any change happens in the doc, we need to compute many things in the doc
   * for example - user cut some part, added new file etc
   * It returns -> [totalTime, currentTime, paraIndex, musicSourceList, commentThreadsAndPositions]
   */
  let sizeTravelled = 0
  let isThisDeletedText = false
  let totalTime = 0

  let currentTime = 0
  let isOverMusic = false
  let paraIndex = -1
  let totalSize = 0
  let { from } = view.state.selection

  let musicSourceList = new Set()
  let imageSourceList = new Set()
  let commentThreadsAndPositions = {}

  view.state.doc.content.forEach(function (paraNode, offset, index) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      isThisDeletedText = false
      textNode.marks.every(function (mark) {
        // find comments and show their correct position
        if (mark.type.name === 'comment' && mark.attrs.cid && !commentThreadsAndPositions[mark.attrs.cid]) {
          commentThreadsAndPositions[mark.attrs.cid] = window.pageYOffset + view.coordsAtPos(sizeTravelled).top - magicNumber + 5 // set posiiton
        }

        if (mark.type.name === 'deleted') {
          // return false
          isThisDeletedText = true
        }

        if (mark.attrs != null && mark.attrs.uid != null) {
          if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
            let duration = mark.attrs.aend - mark.attrs.astart
            // if (mark.attrs.uid.split('-')[0] === 'silence') {
            //   duration = 0.1
            // }
            // computeCurrentCursorTime
            if (!isThisDeletedText) {
              if (sizeTravelled <= view.state.selection.anchor) {
                currentTime += duration
              }
              // computeTotalDuration
              totalTime += duration
            }
          }
          if (sizeTravelled <= view.state.selection.anchor) {
            if (mark.attrs.uid.includes('music-')) {
              isOverMusic = true
            }
          }

          if (mark.attrs.asource != null && mark.attrs.mediaType === 'image') {
            imageSourceList.add(mark.attrs.asource)
          } else if (mark.attrs.asource != null) { // getMusicAndAudioSource
            musicSourceList.add(mark.attrs.asource)
          }
        }
        return true
      })
      sizeTravelled += textNode.nodeSize
    })

    // calculateParaFromLocation
    totalSize += paraNode.nodeSize
    if (from <= totalSize && paraIndex === -1) {
      paraIndex = index
    }
  })
  if (paraIndex === -1) {
    paraIndex = 1
  }
  // console.log('sddsd', totalTime, currentTime, paraIndex, musicSourceList, commentThreadsAndPositions)
  return [totalTime, currentTime, paraIndex, musicSourceList, commentThreadsAndPositions, imageSourceList, isOverMusic]
}

/*
make numberOfTextNodesToSkip +ve number to skip text node forward and -ve for backward
Usage:
skipAndSelectNode(5) to select 5th text node forward from current text node
skipAndSelectNode(-5) to select 5th text node backward from current text node

This can be used to skip X words backword or forward while playing.
*/
export function skipAndSelectNode (numberOfTextNodesToSkip, view) {
  let forward = true
  if (numberOfTextNodesToSkip < 0) {
    forward = false
    numberOfTextNodesToSkip = Math.abs(numberOfTextNodesToSkip)
  }
  let doc = view.state.doc
  view.focus()
  let start = doc.resolve(view.state.selection.anchor)
  let end = start
  for (let i = 0; i <= numberOfTextNodesToSkip; i++) {
    start = end

    if (forward) {
      end = selectNodeAfter(start, view).end
    } else {
      end = selectNodeBefore(start, view).end
    }
  }
  try {
    view.dispatch(
      view.state.tr.scrollIntoView().setSelection(new TextSelection(start, end))
    )
  } catch (e) {
    console.log('caught err', e)
  }
}

export function selectNodeAfter (start, view) {
  let doc = view.state.doc
  let node = null
  if (start.nodeAfter != null) { // this means we are inside the word
    node = new NodeSelection(start).node
  } else { // this means we are at the edge
    start = doc.resolve(start.pos + 2) // this will move us inside the word
    node = new NodeSelection(start).node
  }
  let next = 0
  do {
    if (start.nodeAfter == null) {
      next = next + node.nodeSize + 2
      break
    } else { // finding the next non deleted node
      next = next + start.nodeAfter.nodeSize
      let isDeleted = false
      start.nodeAfter.marks.some(function (mark) {
        if (mark.type.name === 'deleted') return (isDeleted = true)
        else return (isDeleted = false)
      })
      if (isDeleted) {
        start = doc.resolve(start.pos + start.nodeAfter.nodeSize)
      } else {
        break
      }
    }
  } while (true)
  return { start: start, end: doc.resolve(start.pos + next) }
}
export function selectAnyNodeAfter (start, view) {
  /**
   * Will select even the deleted nodes
   */
  let doc = view.state.doc
  let node = null
  if (start.nodeAfter != null) {
    node = new NodeSelection(start).node
  } else {
    start = doc.resolve(start.pos + 2)
    node = new NodeSelection(start).node
  }
  let next = 0
  if (start.nodeAfter == null) {
    next = next + node.nodeSize + 2
  } else {
    next = next + start.nodeAfter.nodeSize
  }
  return { start: start, end: doc.resolve(start.pos + next) }
}
export function selectNodeBefore (start, view) {
  /**
   * same as selectNodeAfter but backward direction
   */
  let doc = view.state.doc
  let node = null
  if (start.nodeAfter != null) {
    node = new NodeSelection(start).node
  } else {
    start = doc.resolve(start.pos - 2)
    node = new NodeSelection(start).node
  }
  var previous = 0
  do {
    if (start.nodeBefore == null) {
      previous = previous - node.nodeSize - 2
      break
    } else {
      previous = previous - start.nodeBefore.nodeSize
      let isDeleted = false
      start.nodeBefore.marks.some(function (mark) {
        if (mark.type.name === 'deleted') return (isDeleted = true)
        else return (isDeleted = false)
      })
      if (isDeleted) {
        start = doc.resolve(start.pos - start.nodeBefore.nodeSize)
      } else {
        break
      }
    }
  } while (true)
  return { start: start, end: doc.resolve(start.pos + previous) }
}

export function selectNode (location, view, cursorOnly, autoScroll = true) {
  /**
   * select a node at the location
   * if cursorOnly then it will just move to that location w/o selecting any node
   */
  let doc = view.state.doc
  if (!myProseEditor.isUsedInProject) {
    view.focus()
  }
  // let start = doc.resolve(view.state.selection.head);
  let start = doc.resolve(location)
  // let end = selectNodeAfter(start).end;
  let end = start
  if (start.nodeAfter != null) {
    end = doc.resolve(start.pos + start.nodeAfter.nodeSize)
    if (end.nodeAfter != null && hasMark(end.nodeAfter.marks, ['transcript', 'hideTranscript'])) {
      // middle character is hidden
      end = getNextNodeWithoutMark(view, end.pos, ['transcript', 'hideTranscript'], 'hide', false)
    } else if (hasMark(start.nodeAfter.marks, ['hideTranscript'])) {
      // first character is hidden
      // console.log('I am here')
      end = getNextNodeWithMark(view, end.pos, ['info'], 'hide', false)
      if (start.nodeBefore && hasMark(start.nodeBefore.marks, ['transcript'])) {
        start = doc.resolve(start.pos - start.nodeBefore.nodeSize)
      }
    }
  }
  // in some cases we just want to show cursor
  if (cursorOnly) {
    start = end
  }
  // console.log('start', start)
  // console.log('end', end)
  try {
    // let end = doc.resolve(view.state.selection.head + next);
    // let customTextSelect = view.state.schema.mark('customTextSelect', {
    //   class: 'playbackSelection'
    // })
    let transaction = view.state.tr

    if (autoScroll) {
      transaction = transaction.scrollIntoView()
    }
    transaction = transaction.setMeta('addToHistory', false).setSelection(new TextSelection(start, start))

    if (document._previousStart && document._previousEnd) {
      document._playingSelection = {
        from: 0,
        to: 0
      }
      document._correctTranscriptSelection = {
        from: 0,
        to: 0
      }
      // transaction = transaction.removeMark(document._previousStart, document._previousEnd, view.state.schema.marks['customTextSelect'])
    }
    // transaction = transaction.addMark(start.pos, end.pos, customTextSelect)
    view.dispatch(
      view.state.tr // to trigger decoration
    )
    document._playingSelection = {
      from: start.pos,
      to: end.pos
    }
    view.dispatch(
      transaction
    )
    document._previousStart = start.pos
    document._previousEnd = end.pos
  } catch (e) {
    console.log('caught err', e)
  }
}
export function focusCursorOnLocation (location, proseEditor) {
  /** used in blockmode */
  let view = proseEditor.view
  let doc = view.state.doc
  view.focus() // before any selection
  // let start = doc.resolve(view.state.selection.head);
  let start = doc.resolve(location)
  try {
    // let end = doc.resolve(view.state.selection.head + next);
    view.dispatch(
      view.state.tr
        .scrollIntoView()
        .setSelection(new TextSelection(start, start))
    )
    proseEditor.audioControl.currentSelectionAnchor = start.pos
    proseEditor.audioControl.currentSelectionAnchor = start.pos
    if (proseEditor.audioControl.isPlaying) {
      proseEditor.audioControl.playFromHere()
    }
  } catch (e) {
    console.log('caught err', e)
  }
}
export function goToPara (paraIndex, view) {
  /**
   * seekbar seek to a para location
   * this fn is used for that
   */
  let doc = view.state.doc
  view.focus()
  let location = 0
  location += 2
  doc.content.forEach(function (paraNode, offset, index) {
    if (paraIndex > index) {
      location += paraNode.nodeSize
    }
  })
  let temp = doc.resolve(location)
  let start = doc.resolve(temp.pos - temp.textOffset)
  // let end = doc.resolve(start.pos + start.nodeAfter.nodeSize)
  // todo: sometimes scrolling up, sometimes down
  // by https://discuss.prosemirror.net/t/scrollintoview-control-offset/754
  try {
    view.dispatch(
      view.state.tr.scrollIntoView().setSelection(new TextSelection(start, start))
    )
  } catch (e) {
    console.log('caught err', e)
  }
}

export function calculateParaFromLocation (view) {
  /**
   * get the paraIndex from the location user in
   */
  let doc = view.state.doc
  let { from } = view.state.selection
  view.focus()
  let paraIndex = -1
  let totalSize = 0
  doc.content.forEach(function (paraNode, offset, index) {
    totalSize += paraNode.nodeSize
    if (from <= totalSize && paraIndex === -1) {
      paraIndex = index
    }
  })
  if (paraIndex === -1) {
    paraIndex = 1
  }
  // console.log('index is', paraIndex)
  return paraIndex
}

export function getSnappedSelectionForLocation (from, to, view) {
  /**
   * This is being used in blockmode
   * it will give us the location without any selection etc
   */
  const doc = view.state.doc
  let anchor = doc.resolve(from)
  let head = doc.resolve(to)
  let start = null
  let end = null
  if (anchor.pos <= head.pos) {
    // * selecting forwards
    start = anchor.pos - anchor.textOffset
    if (head.nodeAfter !== null) {
      // * for some cases when nodeAfter is null
      end = head.pos + head.nodeAfter.nodeSize
    } else {
      end = head.pos
    }
  } else {
    //* selecting backwards
    if (anchor.nodeAfter !== null && anchor.textOffset > 0) {
      // * for some cases when nodeAfter is null
      start = anchor.pos + anchor.nodeAfter.nodeSize
    } else {
      start = anchor.pos
    }
    end = head.pos - head.textOffset
  }

  return { start, end }
}
function nodeDuration (textNode) {
  let duration = 0
  textNode.marks.forEach(function (mark) {
    if (mark.type.name === 'deleted') {
      return 0
    }
    if (mark.attrs != null && mark.attrs.uid != null) {
      if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
        duration = mark.attrs.aend - mark.attrs.astart
        // if (mark.attrs.uid.split('-')[0] === 'silence') {
        //   duration = 0.1
        // }
      }
    }
  })
  return duration
}
export function loadImageWithIndex (view, name, asource, position) {
  /**
   * add image in the spext doc both inline and background
   */
  if (view.state.selection.anchor !== view.state.selection.head) {

  } else {
    moveCursorToBoundary(view)
  }
  let insertPos = Math.min(view.state.selection.anchor, view.state.selection.head)
  let doc = view.state.doc
  let uniqueSourceId = uuidv1()
  let imageId = 'image-' + uniqueSourceId
  let silenceCount = 1 / 1
  let transaction
  let newPosition = 0

  // create audio node, starpill
  let imageNode = view.state.schema.node(
    'image',
    {
      title: name,
      type: 'image',
      uid: uniqueSourceId,
      position
    },
    view.state.schema.text(name),
    [
      view.state.schema.mark('info', {
        uid: imageId,
        asource: asource,
        astart: 0,
        aend: 0,
        mediaType: 'image',
        class: 'localimage'
      })
    ]
  )
  // endpill
  let imageEndNode = view.state.schema.node(
    'image',
    {
      title: name,
      type: 'endImage',
      uid: uniqueSourceId,
      position
    },
    null,
    [
      view.state.schema.mark('info', {
        uid: 'endimg-' + uniqueSourceId,
        astart: 0,
        aend: 0,
        class: 'localimageend'
      })
    ]
  )

  // adding it in background (anchor!= head)
  if (view.state.selection.anchor !== view.state.selection.head) {
    let start = doc.resolve(Math.min(view.state.selection.anchor, view.state.selection.head))
    let end = doc.resolve(Math.max(view.state.selection.anchor, view.state.selection.head))
    let selectedDuration = 0
    let i
    for (i = start.pos; selectAnyNodeAfter(doc.resolve(i), view).end.pos < end.pos; i = selectAnyNodeAfter(doc.resolve(i), view).end.pos) {
      let node = getNextNode(i, view)
      if (node == null) { // encountered paragraph
        i = i + 2
        continue
      }
    }
    let newImageMark = view.state.schema.mark('imageMark', {
      class: 'imageMark',
      source: asource,
      image_mark_uid: uniqueSourceId,
      position
    })
    if (i === start.pos) { // fix for when one word is selected
      i = i + 1
    }
    let pos = getSnappedSelectionForLocation(start.pos, end.pos, view)
    // add mark on the nodes
    transaction = view.state.tr.addMark(pos.start, pos.end, newImageMark).insert(insertPos, imageNode).insert(pos.end + imageNode.nodeSize, imageEndNode)

    // when adding in between
  } else {
    newPosition = insertPos + imageNode.nodeSize
    transaction = view.state.tr.insert(insertPos, imageNode)
    // let musicWaveClubFactor = 5
    for (let i = 0; i <= silenceCount; i++) {
      let level = Math.random() * 127
      let soundchar = String.fromCharCode(0x200 + level)
      // for (let j = 0; j <= musicWaveClubFactor; j++) {
      // soundchar += String.fromCharCode(0x200 + level)
      // level = Math.random() * 127
      // }
      let r = Math.random() // we have some gap between sound chars to look not so cramped w/r to its length
      if (Math.round(r * 10) === 1) {
        soundchar = ' '
      }
      // create anew node dynamically and assign the class music
      // let silenceNode = view.state.schema.text(soundchar, [
      let silenceNode = view.state.schema.text(silenceSymbol, [
        view.state.schema.mark('info', {
          uid: 'silence-' + uuidv1(),
          astart: 0,
          aend: 1
        }),
        view.state.schema.mark('imageMark', {
          class: 'imageMark',
          source: asource,
          image_mark_uid: uniqueSourceId,
          position
        })
      ])
      transaction = transaction.insert(newPosition, silenceNode)
      newPosition += silenceNode.nodeSize
    }
    transaction.insert(newPosition, imageEndNode)
  }
  view.updateState(view.state.apply(transaction))
  view.dispatch(
    view.state.tr.setMeta('docEdited', true)
  )
  document._musicJustAdded = true
  hideAddMediaModal()
  store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Adding Audio...' })
}
export function loadAudioWithIndex (view, musicAudioBufferList, name, asource) {
  /**
   * add media in the spext doc both inline and background
   */
  const duration = musicAudioBufferList[asource].buffer.duration
  if (view.state.selection.anchor !== view.state.selection.head) {

  } else {
    moveCursorToBoundary(view)
  }
  let insertPos = Math.min(view.state.selection.anchor, view.state.selection.head)
  let doc = view.state.doc
  let uniqueSourceId = uuidv1()
  let musicId = 'music-' + uniqueSourceId
  let silenceCount = duration / 1
  let transaction
  let newPosition = 0

  // create audio node, starpill
  let audioNode = view.state.schema.node(
    'music',
    {
      title: name,
      type: 'music',
      uid: uniqueSourceId,
      fadeIn: {
        start: 0,
        end: 56,
        duration: duration * 10 / 100
      },
      fadeOut: {
        end: 0,
        duration: duration * 10 / 100
      }
    },
    view.state.schema.text(name),
    [
      view.state.schema.mark('info', {
        uid: musicId,
        asource: asource,
        astart: 0,
        aend: duration,
        class: 'localaudio'
      })
    ]
  )
  // endpill
  let audioEndNode = view.state.schema.node(
    'music',
    {
      title: name,
      type: 'endMusic',
      uid: uniqueSourceId,
      fadeIn: {
        start: 0,
        end: 56,
        duration: duration * 10 / 100
      },
      fadeOut: {
        end: 0,
        duration: duration * 10 / 100
      }
    },
    null,
    [
      view.state.schema.mark('info', {
        uid: 'endmus-' + uniqueSourceId,
        astart: 0,
        aend: duration,
        class: 'localaudioend'
      })
    ]
  )

  // adding it in background (anchor!= head)
  if (view.state.selection.anchor !== view.state.selection.head) {
    let start = doc.resolve(Math.min(view.state.selection.anchor, view.state.selection.head))
    let end = doc.resolve(Math.max(view.state.selection.anchor, view.state.selection.head))
    let selectedDuration = 0
    let i
    for (i = start.pos; selectAnyNodeAfter(doc.resolve(i), view).end.pos < end.pos; i = selectAnyNodeAfter(doc.resolve(i), view).end.pos) {
      let node = getNextNode(i, view)
      if (node == null) { // encountered paragraph
        i = i + 2
        continue
      }
      if ((selectedDuration + nodeDuration(node)) > duration) {
        break
      }
      selectedDuration = selectedDuration + nodeDuration(node)
    }
    audioNode = view.state.schema.node(
      'music',
      {
        title: name,
        type: 'music',
        uid: uniqueSourceId,
        fadeIn: {
          start: 0,
          end: 56,
          duration: selectedDuration * 10 / 100
        },
        fadeOut: {
          end: 0,
          duration: selectedDuration * 10 / 100
        }
      },
      view.state.schema.text(name),
      [
        view.state.schema.mark('info', {
          uid: musicId,
          asource: asource,
          astart: 0,
          aend: duration,
          class: 'localaudio'
        })
      ]
    )
    // endpill
    audioEndNode = view.state.schema.node(
      'music',
      {
        title: name,
        type: 'endMusic',
        uid: uniqueSourceId,
        fadeIn: {
          start: 0,
          end: 56,
          duration: selectedDuration * 10 / 100
        },
        fadeOut: {
          end: 0,
          duration: selectedDuration * 10 / 100
        }
      },
      null,
      [
        view.state.schema.mark('info', {
          uid: 'endmus-' + uniqueSourceId,
          astart: 0,
          aend: duration,
          class: 'localaudioend'
        })
      ]
    )
    let newNoteMark = view.state.schema.mark('note', {
      class: 'note',
      note_uid: uniqueSourceId
    })
    if (i === start.pos) { // fix for when one word is selected
      i = i + 1
    }
    let pos = getSnappedSelectionForLocation(start.pos, end.pos, view)
    // add mark on the nodes
    transaction = view.state.tr.addMark(pos.start, pos.end, newNoteMark).insert(insertPos, audioNode).insert(pos.end + audioNode.nodeSize, audioEndNode)

  // when adding in between
  } else {
    newPosition = insertPos + audioNode.nodeSize
    transaction = view.state.tr.insert(insertPos, audioNode)
    // let musicWaveClubFactor = 5
    for (let i = 0; i <= silenceCount; i++) {
      let level = Math.random() * 127
      let soundchar = String.fromCharCode(0x200 + level)
      // for (let j = 0; j <= musicWaveClubFactor; j++) {
      // soundchar += String.fromCharCode(0x200 + level)
      // level = Math.random() * 127
      // }
      let r = Math.random() // we have some gap between sound chars to look not so cramped w/r to its length
      if (Math.round(r * 10) === 1) {
        soundchar = ' '
      }
      // create anew node dynamically and assign the class music
      // let silenceNode = view.state.schema.text(soundchar, [
      let silenceNode = view.state.schema.text(silenceSymbol, [
        view.state.schema.mark('silence', {
          uid: 'silence-' + uuidv1(),
          astart: 0,
          aend: 0.1
        }),
        view.state.schema.mark('info', {
          uid: 'silence-' + uuidv1(),
          astart: 0,
          aend: 1
        }),
        // view.state.schema.mark('info', {
        //   uid: 'mtext-' + uuidv1(),
        //   astart: 0,
        //   aend: 0.1,
        //   class: 'music'
        // }),
        view.state.schema.mark('note', {
          class: 'note',
          note_uid: uniqueSourceId
        })
      ])
      transaction = transaction.insert(newPosition, silenceNode)
      newPosition += silenceNode.nodeSize
    }
    transaction.insert(newPosition, audioEndNode)
  }
  view.updateState(view.state.apply(transaction))
  view.dispatch(
    view.state.tr.setMeta('docEdited', true)
  )
  document._musicJustAdded = true
  hideAddMediaModal()
  let blockModeConfigObj = updateBlockModeInfo(getElementsByAttribute('uid', 'music-' + uniqueSourceId)[0], getElementsByAttribute('uid', 'endmus-' + uniqueSourceId)[0], view)
  //
  store.commit('blockmode/update', blockModeConfigObj)
  store.commit('blockmode/open')
  pinMusicEndPill(blockModeConfigObj.uid)
  pinMusicStartPill(blockModeConfigObj.uid)
  highlightTextWithPinnedMusic(blockModeConfigObj.uid)
  document._pinnedMusicUid = blockModeConfigObj.uid
  //
  reComputeMusicReach(view)
  store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Adding Audio...' })
}
export function updateBlockModeInfo(musicStartPillElement, musicEndPillElement, view, reset = false) {
  if (musicStartPillElement && musicEndPillElement) {
    let musicPillElement = musicStartPillElement
    let musicNode, asource, location, timeLocations, start, end, duration
    try {
      asource = musicPillElement.getAttribute('asource')
      let endMusicPosition = view.posAtDOM(musicEndPillElement)
      let startMusicPosition = view.posAtDOM(musicStartPillElement)
      musicNode = view.state.doc.nodeAt(startMusicPosition)
      let uid = musicNode.attrs.uid
      location = [startMusicPosition, endMusicPosition]
      timeLocations = [computeTimeAtPos(startMusicPosition, view) * 1000, computeTimeAtPos(endMusicPosition, view) * 1000]
      if (reset) {
        start = 0
      } else {
        start = parseFloat(musicPillElement.getAttribute('astart')) * 1000
      }
      end = start + computeAppliedMusicDuration(uid, view) * 1000
      duration = parseFloat(musicPillElement.getAttribute('aend')) * 1000
      let textBetweenPills = view.state.doc.textBetween(location[0] + musicNode.nodeSize, location[1])
      let blockModeConfigObj = {
        name: musicNode.attrs.title,
        audioText: textBetweenPills,
        uid: uid,
        asource: asource,
        location: location,
        totalMs: duration,
        fromMs: start,
        toMs: end,
        audioTimeMs: timeLocations,
        fadeInMs: musicNode.attrs.fadeIn.duration * 1000,
        fadeOutMs: musicNode.attrs.fadeOut.duration * 1000,
        volume: musicNode.attrs.fadeIn.end
      }
      if (reset) {
        let appliedDuration = blockModeConfigObj.toMs - blockModeConfigObj.fromMs
        blockModeConfigObj.fadeInMs = appliedDuration * 10 / 100
        blockModeConfigObj.fadeOutMs = appliedDuration * 10 / 100
      }
      return blockModeConfigObj
    } catch (err) {
      console.log(err)
    }
    return {}
  }
}
export function replaceAudio (view, musicAudioBufferList, name, asource) {
  /**
   * add media in the spext doc both inline and background
   */
  const duration = musicAudioBufferList[asource].buffer.duration
  let blockmodeConfig = store.state.blockmode
  let appliedDuration = blockmodeConfig.toMs - blockmodeConfig.fromMs
  let fadeDuration = (appliedDuration * 10 / 100) / 1000
  try {
    let cursorPos = view.state.doc.resolve(blockmodeConfig.location[0])
    const transaction = view.state.tr.setNodeMarkup(
      blockmodeConfig.location[0], // For custom node views, this function is passed into the constructor.  It'll return the position of the node in the document.
      undefined, // No node type change
      {
        type: 'music',
        title: name,
        uid: blockmodeConfig.uid,
        fadeIn: {
          start: 0,
          end: blockmodeConfig.volume,
          duration: fadeDuration
        },
        fadeOut: {
          end: 0,
          duration: fadeDuration
        }
      },
      [
        view.state.schema.mark('info', {
          uid: 'music-' + blockmodeConfig.uid,
          asource: asource,
          astart: 0,
          aend: duration,
          class: 'localaudio'
        })
      ]
    ).setNodeMarkup(
      blockmodeConfig.location[1], // For custom node views, this function is passed into the constructor.  It'll return the position of the node in the document.
      undefined, // No node type change
      {
        type: 'endMusic',
        title: name,
        uid: blockmodeConfig.uid,
        fadeIn: {
          start: 0,
          end: blockmodeConfig.volume,
          duration: fadeDuration
        },
        fadeOut: {
          end: 0,
          duration: fadeDuration
        }
      },
      [
        view.state.schema.mark('info', {
          uid: 'endmus-' + blockmodeConfig.uid,
          asource: asource,
          astart: 0,
          aend: duration,
          class: 'localaudioend'
        })
      ]
    ).scrollIntoView().setSelection(
      new TextSelection(cursorPos, cursorPos))
    // this.view.dispatch(transaction)
    view.updateState(view.state.apply(transaction))
    let blockModeConfigObj = {
      name: name,
      audioText: blockmodeConfig.audioText,
      uid: blockmodeConfig.uid,
      asource: asource,
      location: blockmodeConfig.location,
      totalMs: duration * 1000,
      fromMs: 0,
      toMs: blockmodeConfig.toMs,
      audioTimeMs: blockmodeConfig.audioTimeMs,
      fadeInMs: appliedDuration * 10 / 100,
      fadeOutMs: appliedDuration * 10 / 100,
      volume: blockmodeConfig.volume
    }
    store.commit('blockmode/update', blockModeConfigObj)
    pinMusicEndPill(blockmodeConfig.uid)
    pinMusicStartPill(blockmodeConfig.uid)
  } catch (err) {
    console.log(err)
  }
  store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Replacing Audio...' })
}
export function reRenderBlockModeIfOpened(view, transaction) {
  const anchorPos = view.state.selection.anchor // place where you start the selection
  const headPos = view.state.selection.head

  let currentNode = view.state.doc.nodeAt(anchorPos)
  // if (anchorPos !== headPos) {
  //   return
  // }
  if (currentNode && isNoteSymbol(currentNode.marks) && (myProseEditor.audioControl && !myProseEditor.audioControl.isPlaying)) {
    let uid = getNoteId(currentNode.marks)
    let isBlockModeOpen = store.state.editor.panelToShow === 'blockmode'
    if (isBlockModeOpen && document._pinnedMusicUid === uid && !store.state.blockmode.deleted) {
      let musicStartPillElement = getElementsByAttribute('uid', 'music-' + uid)[0]
      let musicEndPillElement = getElementsByAttribute('uid', 'endmus-' + uid)[0]
      if (musicStartPillElement && musicEndPillElement) {
        let musicPillElement = musicStartPillElement
        let musicNode, asource, location, timeLocations, start, end, duration
        try {
          asource = musicPillElement.getAttribute('asource')
          let endMusicPosition = view.posAtDOM(musicEndPillElement)
          let startMusicPosition = view.posAtDOM(musicStartPillElement)
          musicNode = view.state.doc.nodeAt(startMusicPosition)
          let uid = musicNode.attrs.uid
          location = [startMusicPosition, endMusicPosition]
          timeLocations = [computeTimeAtPos(startMusicPosition, view) * 1000, computeTimeAtPos(endMusicPosition, view) * 1000]
          // TODO: improve later
          start = parseFloat(musicPillElement.getAttribute('astart')) * 1000
          // start = 0
          end = start + computeAppliedMusicDuration(uid, view) * 1000
          duration = parseFloat(musicPillElement.getAttribute('aend')) * 1000
          let textBetweenPills = view.state.doc.textBetween(location[0] + musicNode.nodeSize, location[1])
          let blockModeConfigObj = {
            name: musicNode.attrs.title,
            audioText: textBetweenPills,
            uid: uid,
            asource: asource,
            location: location,
            totalMs: duration,
            fromMs: start,
            toMs: end,
            audioTimeMs: timeLocations,
            fadeInMs: musicNode.attrs.fadeIn.duration * 1000,
            fadeOutMs: musicNode.attrs.fadeOut.duration * 1000,
            volume: musicNode.attrs.fadeIn.end
          }
          let appliedDuration = blockModeConfigObj.toMs - blockModeConfigObj.fromMs
          blockModeConfigObj.fadeInMs = appliedDuration * 10 / 100
          blockModeConfigObj.fadeOutMs = appliedDuration * 10 / 100
          updateMusicPillAttributes(blockModeConfigObj, view, transaction)
          store.commit('blockmode/update', blockModeConfigObj)
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
}
export function updateMusicPillAttributes(blockmodeConfig, view, transaction) {
  try {
    transaction.setNodeMarkup(
      blockmodeConfig.location[0], // For custom node views, this function is passed into the constructor.  It'll return the position of the node in the document.
      undefined, // No node type change
      {
        type: 'music',
        title: blockmodeConfig.name,
        uid: blockmodeConfig.uid,
        fadeIn: {
          start: 0,
          end: blockmodeConfig.volume,
          duration: blockmodeConfig.fadeInMs / 1000
        },
        fadeOut: {
          end: 0,
          duration: blockmodeConfig.fadeOutMs / 1000
        }
      },
      [
        view.state.schema.mark('info', {
          uid: 'music-' + blockmodeConfig.uid,
          asource: blockmodeConfig.asource,
          astart: blockmodeConfig.fromMs / 1000,
          aend: blockmodeConfig.totalMs / 1000,
          class: 'localaudio'
        })
      ]
    ).setNodeMarkup(
      blockmodeConfig.location[1], // For custom node views, this function is passed into the constructor.  It'll return the position of the node in the document.
      undefined, // No node type change
      {
        type: 'endMusic',
        title: blockmodeConfig.name,
        uid: blockmodeConfig.uid,
        fadeIn: {
          start: 0,
          end: blockmodeConfig.volume,
          duration: blockmodeConfig.fadeInMs / 1000
        },
        fadeOut: {
          end: 0,
          duration: blockmodeConfig.fadeOutMs / 1000
        }
      },
      [
        view.state.schema.mark('info', {
          uid: 'endmus-' + blockmodeConfig.uid,
          asource: blockmodeConfig.asource,
          astart: blockmodeConfig.fromMs / 1000,
          aend: blockmodeConfig.totalMs / 1000,
          class: 'localaudioend'
        })
      ]
    )
  } catch (err) {
    console.log(err)
  }
}
export function pinMusicStartPill(uid) {
  let el = getElementsByAttribute('uid', 'music-' + uid)
  if (el.length > 0) {
    setTimeout(function () {
      el[0].classList.add('localAudioPinned')
    }, 10)
  }
}
export function pinMusicEndPill(uid) {
  let el = getElementsByAttribute('uid', 'endmus-' + uid)
  if (el.length > 0) {
    setTimeout(function () {
      el[0].classList.add('localAudioEndPinned')
    }, 10)
  }
}
export function highlightTextWithPinnedMusic(noteUid) {
  let el = getElementsByAttribute('data-note_uid', noteUid)
  if (el.length > 0) {
    el.forEach(element => {
      setTimeout(function () {
        element.classList.add('musicPinned')
      }, 10)
    })
  }
}
function hideAddMediaModal () {
  $('.ui.sampleFile.modal').modal('hide')
}

export function getNextNode (position, view) {
  try {
    return view.state.doc.resolve(position).nodeAfter
  } catch (e) {
    return {}
  }
}
export function addMarkAtLocation (from, to, view, markName, attrs = {}) {
  let newMark = view.state.schema.mark(markName, attrs)
  let { start, end } = getSnappedSelectionForLocation(from, to, view)
  view.dispatch(view.state.tr.addMark(start, end, newMark))
}
export function addMarkAtLocationInTransaction (from, to, view, transaction, markName, attrs = {}) {
  let newMark = view.state.schema.mark(markName, attrs)
  let { start, end } = getSnappedSelectionForLocation(from, to, view)
  transaction.addMark(start, end, newMark) // only create the transcation; maybe apply together later
}
export function addNoteMarkAtLocation (from, to, view) {
  let newNoteMark = view.state.schema.mark('note', {
    class: 'note'
  })
  let { start, end } = getSnappedSelectionForLocation(from, to, view)
  view.dispatch(view.state.tr.addMark(start, end, newNoteMark)) // create and apply the transaction
}
export function addNoteMarkAtLocationInTransaction (from, to, view, transaction, uniqueSourceId, cssClass = 'note') {
  let newNoteMark = view.state.schema.mark('note', {
    class: cssClass,
    note_uid: uniqueSourceId
  })
  let { start, end } = getSnappedSelectionForLocation(from, to, view)
  transaction.addMark(start, end, newNoteMark) // only create the transcation; maybe apply together later
}
export function removeMarkAtLocation (start, end, view, markName) {
  view.dispatch(
    view.state.tr.removeMark(start, end, view.state.schema.marks[markName])
  )
}

export function removeMarkAtLocationInTransaction (start, end, view, transaction, markName = 'note') {
  if (view.state.doc.nodeSize === end + 1) {
    return
  }
  // console.log('view', view)
  // console.log('end', end)
  try {
    transaction.removeMark(start, end, view.state.schema.marks[markName])
  } catch (err) {
    console.log('err', err)
  }
}

export function insertSilenceInEditorInTransaction (location, silenceDuration, view, transaction) {
  let pm = view
  let newPosition = location
  let silenceCount = silenceDuration / 0.1
  for (let i = 0; i <= silenceCount; i++) {
    let silenceNode = createSilenceNode(pm)
    transaction = transaction.insert(newPosition, silenceNode)
    newPosition += silenceNode.nodeSize
  }
}
// when user hovers over the view
// & reaches a blank paragraph
// set cursor position to that para
export function showFloatingMenuOnHover (view, event) {
  // console.log('mouse over', view, event)
  let loc = view.posAtCoords({
    left: event.clientX,
    top: event.clientY
  })
  // console.log(loc)
  if (loc.inside >= 0) {
    const currentDom = view.domAtPos(loc.pos)

    const isActive =
      currentDom.node.innerHTML === '<br>' &&
      currentDom.node.tagName === 'P' &&
      currentDom.node.parentNode === view.dom
    // console.log(currentDom)
    // console.log(event, loc, isActive, currentDom)
    if (isActive) {
      let newPos = view.state.doc.resolve(loc.pos)
      if (view.dispatch) {
        view.dispatch(
          view.state.tr.setSelection(new TextSelection(newPos, newPos))
        )
      }
    }
  }
}

export function getAudioSource (view) {
  /**
   * Get link to all the media files except music sources
   * returns a list
   */
  let sizeTravelled = 0
  let audioSourceList = new Set()
  view.state.doc.content.forEach(function (paraNode) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      sizeTravelled += textNode.nodeSize
      textNode.marks.every(function (mark) {
        if (mark.type.name === 'deleted') {
          return false
        }
        if (mark.attrs != null && mark.attrs.uid != null) {
          if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
            if (mark.attrs.asource != null) {
              audioSourceList.add(mark.attrs.asource)
            }
          }
        }
        return true
      })
    })
  })
  return audioSourceList
}
export function getMusicSource (view) {
  /**
   * Get list of all music files
   */
  let sizeTravelled = 0
  let musicSourceList = new Set()
  view.state.doc.content.forEach(function (paraNode) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      sizeTravelled += textNode.nodeSize
      textNode.marks.every(function (mark) {
        if (mark.type.name === 'deleted') {
          return false
        }
        if (mark.attrs != null && mark.attrs.uid != null) {
          if (mark.attrs.uid.includes('music-')) {
            if (mark.attrs.asource != null) {
              musicSourceList.add(mark.attrs.asource)
            }
          }
        }
        return true
      })
    })
  })
  return musicSourceList
}
export function getMusicAndAudioSource (view) {
  /**
   * Get all kind of sources
   */
  let sizeTravelled = 0
  let musicSourceList = new Set()
  view.state.doc.content.forEach(function (paraNode) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      sizeTravelled += textNode.nodeSize
      textNode.marks.every(function (mark) {
        if (mark.type.name === 'deleted') {
          return false
        }
        if (mark.attrs != null && mark.attrs.uid != null) {
          if (mark.attrs.asource != null) {
            musicSourceList.add(mark.attrs.asource)
          }
        }
        return true
      })
    })
  })
  return musicSourceList
}

export function makeSelectionPrevWord (state, dispatch) {
  const doc = state.doc
  const anchor = state.selection.$anchor
  let end = doc.resolve(anchor.pos - anchor.nodeBefore.nodeSize)
  dispatch(state.tr.setSelection(new TextSelection(end, anchor)))
}

// TODO refactor
export function getParagraphMeta (view) {
  /***
   * In seekbar we show if paragraph has some deleted sections
   * This will get the info anout that
   */
  let metaList = []
  let paraPos = 1
  view.state.doc.content.forEach(function (paraNode, offset, index) {
    // console.log(paraNode)
    let meta = []
    meta['labelName'] = paraNode.attrs.labelName
    meta['labelColor'] = paraNode.attrs.labelColor
    meta['paraPos'] = paraPos
    let tparaPos = 0
    if (window.mode === 'editTranscript') {
      tparaPos = getNextNodeWithoutMark(view, paraPos, ['deleted', 'nonText'], 'hide', false)
    } else {
      tparaPos = getNextNodeWithoutMark(view, paraPos, ['deleted'], 'hide', false)
    }
    let coords = null
    if (tparaPos) {
      coords = view.coordsAtPos(tparaPos.pos)
      meta['paraCoords'] = coords
      meta['visible'] = coords.top !== coords.bottom
    } else {
      coords = view.coordsAtPos(paraPos)
      meta['paraCoords'] = coords
      // if paragraph does not contain any text, show it
      // else hide it
      // required for spext new doc
      meta['visible'] = paraNode.textContent === ''
    }
    // console.log(meta['visible'], coords.top !== coords.bottom, paraNode.textContent === '')
    if (window.mode === 'editTranscript') {
      meta['dirty'] = false
    } else {
      meta['dirty'] = isDirty(paraNode)
    }
    metaList.push(meta)
    paraPos += paraNode.nodeSize
  })
  // console.log('metalist', metaList)
  return metaList
}

// TODO remove comments
export function setParagraphMeta (view, pos, meta) {
  console.log(pos, meta)
  // let pMeta = {}
  // if (meta.labelName) {
  //   pMeta['labelName'] = meta.labelName
  // }
  // if (meta.labelColor) {
  //   pMeta['labelName'] = meta.labelColor
  // }
  // for some reason we need to pass pos-1 to set correct node
  view.dispatch(view.state.tr.setNodeMarkup(pos - 1, null, meta))
}

export function addCommentMark (view, id) {
  console.log('add comment id', id)
  let tr = view.state.tr
  // add comment mark on current selection from anchor to head
  tr.addMark(view.state.selection.anchor, view.state.selection.head, view.state.schema.marks.comment.create({ cid: id }))
  tr.setMeta('addToHistory', false)
  view.dispatch(tr)
}

export function removeCommentMark (view, id) {
  console.log('remove comment id', id)
  let tr = view.state.tr
  // remove comment mark on current selection from anchor to head
  tr.removeMark(0, view.state.doc.nodeSize - 2, view.state.schema.marks.comment.create({ cid: id }))
  tr.setMeta('addToHistory', false)
  view.dispatch(tr)
}

export function isTextSelected (view) {
  const anchorPos = view.state.selection.anchor
  const headPos = view.state.selection.head
  if (anchorPos - headPos === 0) {
    return false
  }
  return true
}

// if you don't send any mark, it will send next word
export function getNextNodeWithoutMark (view, pos, searchedMarks, searchedClass, acrossPara) {
  let nextPos = getNextNodeStartPos(view, pos, acrossPara)
  // console.log('nextPos', nextPos)
  while (nextPos) {
    if (searchedMarks.length === 0 || !containsMark(nextPos, searchedMarks, searchedClass)) {
      return nextPos
    }
    nextPos = getNextNodeStartPos(view, nextPos.pos, acrossPara)
  }
}

export function getPrevNodeWithoutMark (view, pos, searchedMarks, searchedClass) {
  let nextPos = getPrevNodeStartPos(view, pos)
  // console.log('nextPos', nextPos)
  while (nextPos) {
    if (searchedMarks.length === 0 || !containsMark(nextPos, searchedMarks, searchedClass)) {
      return nextPos
    }
    nextPos = getPrevNodeStartPos(view, nextPos.pos)
  }
}

export function getNextNodeWithMark (view, pos, searchedMarks, searchedClass, acrossPara) {
  let nextPos = getNextNodeStartPos(view, pos, acrossPara)
  // console.log('nextPos', nextPos)
  while (nextPos) {
    if (searchedMarks.length === 0 || containsMark(nextPos, searchedMarks, searchedClass)) {
      return nextPos
    }
    nextPos = getNextNodeStartPos(view, nextPos.pos, acrossPara)
  }
}

export function containsMark (pos, searchedMarks, searchedClass) {
  let marks = pos.marks()
  let flag = marks.some(function (mark) {
    if (mark.attrs.class === searchedClass || searchedMarks.indexOf(mark.type.name) >= 0) {
      return true
    }
  })
  // console.log(flag)
  return flag
}

export function hasMark (marks, searchList) {
  // console.log('marks', marks)
  // console.log('searchList', searchList)
  let flag = marks.some(function (mark) {
    if (searchList.indexOf(mark.type.name) >= 0) {
      return true
    }
  })
  // console.log('flag', flag)
  return flag
}

export function getNextNodeStartPos (view, pos, acrossPara) {
  let doc = view.state.doc
  let temp = doc.resolve(pos)
  let newPosition = null
  if (temp.nodeAfter) {
    newPosition = doc.resolve(temp.pos + temp.nodeAfter.nodeSize)
  } else if (doc.nodeSize - 3 > temp.pos && acrossPara) {
    newPosition = doc.resolve(temp.pos + 2)
  } else {
  // reached doc end or paraEnd
    return
  }
  return newPosition
}

export function getPrevNodeStartPos (view, pos) {
  let doc = view.state.doc
  let temp = doc.resolve(pos)
  let newPosition = null
  if (temp.nodeBefore) {
    //* user is already at boundary
    // return
    newPosition = doc.resolve(temp.pos - temp.nodeBefore.nodeSize)
  } else if (temp.pos - 2 > 0) {
    //* user is inside node; resolve to right boundary
    newPosition = doc.resolve(temp.pos - 2)
  } else {
    // reached doc start
    return
  }
  return newPosition
}

export function isDirty (paraNode) {
  /**
   * IF there is anything deleted, pasted silenced in  a para its dirty
   */
  let dirty = false
  paraNode.content.forEach(function (textNode) {
    if (dirty) {
      return
    }
    textNode.marks.forEach(function (mark) {
      if (dirty) {
        return
      }
      if (mark.type.name === 'deleted' || mark.type.name === 'pasted' || mark.type.name === 'silence') {
        dirty = true
      }
    })
  })
  return dirty
}

// input: slice
// output: simple list of text nodes
// remove: '_ '
export function getSliceContent (slice) {
  let json = []
  slice.content.content.forEach(function (paraNode) {
    if (paraNode.type.name === 'text') {
      json.push(getInfoFromMark(paraNode))
    } else {
      paraNode.content.content.forEach(function (textNode) {
        // if (textNode.text !== CONSTANTS.silenceSymbol) {
        if (!isSilenceSymbol(textNode.text)) {
          json.push(getInfoFromMark(textNode))
        }
      })
    }
  })
  return json
}

export function getInfoFromMark (text) {
  let res = null
  text.marks.forEach(function (mark) {
    if (mark.type.name === 'info') {
      res = { start: mark.attrs.astart, end: mark.attrs.aend, text: text.text }
    }
  })
  // console.log('res is', res)
  return res
}

export function selectPosition (view, startPos, endPos) {
  let start = null
  let end = null
  try {
    if (startPos) {
      start = view.state.doc.resolve(startPos)
    } else {
      start = view.state.selection.$anchor
    }
    if (endPos) {
      end = view.state.doc.resolve(endPos)
    } else {
      end = view.state.selection.$head
    }
    if (start && end) {
      view.dispatch(view.state.tr.setSelection(new TextSelection(start, end)))
    }
  } catch (e) {
    console.log('e', e)
  }
}

export function computeDeletedTimeInDoc (docInJson) {
  let deletedTimeInMilliSeconds = 0
  if (docInJson.content) {
    docInJson.content.forEach(function (paragraphNode) {
      if (paragraphNode.content) {
        paragraphNode.content.forEach(function (textNode) {
          let flag = false
          if (textNode.marks) {
            textNode.marks.forEach(function (mark) {
              if (mark.type === 'deleted') {
                flag = true
              } else if (mark.type === 'info') {
                if (flag) {
                  deletedTimeInMilliSeconds += (mark.attrs.aend - mark.attrs.astart)
                }
              }
            })
          }
        })
      }
    })
  }
  return deletedTimeInMilliSeconds
}

export function isNoteSymbol(marks) {
  let truth = false
  marks.forEach(mark => {
    if (mark.type.name === 'note') {
      truth = true
      return truth
    }
  })
  return truth
}
export function isMarkDeleted(marks) {
  let truth = false
  marks.forEach(mark => {
    if (mark.type.name === 'deleted') {
      truth = true
    }
  })
  return truth
}

export function ifMusicSymbol(marks) {
  let truth = false
  marks.forEach(mark => {
    if (mark.attrs.uid && mark.attrs.uid.includes('mtext-')) {
      truth = true
      return truth
    }
  })
  return truth
}
export function getNoteId(marks) {
  let truth = null
  marks.forEach(mark => {
    if (mark.type.name === 'note') {
      truth = mark.attrs.note_uid
      return true
    }
  })
  return truth
}
export function scrollToLocation(location) {
  focusCursorOnLocation(location.start, myProseEditor)
}
export function getMediaAttributes(marks) {
  let truth = null
  marks.forEach(mark => {
    if (mark.type.name === 'info') {
      truth = mark.attrs
      return true
    }
  })
  return truth
}

// coorect transcript mode new one
export function setPlayingSelectionHighlight(view, from = 0, to = 0) {
  document._playingSelection = {
    from: from,
    to: to
  }
  document._correctTranscriptSelection = {
    from: 0,
    to: 0
  }
  view.dispatch(
    view.state.tr
  )
}
export function toggleTranscriptMode() {
  let editorSection = document.getElementById('editorSection')
  let editorContainer = document.getElementById('editorContainer')

  if (editorSection && editorContainer) {
    window._spext_transcriptMode = !window._spext_transcriptMode
    store.commit('editor/setTranscriptMode', window._spext_transcriptMode)

    if (window._spext_transcriptMode) {
      // editorSection.style.boxShadow = '0px 0px 10px 10px rgba(0, 140, 255, 0.3)'
      // editorContainer.style.fontStyle = 'italic'

      toggleAddButton(false)
      myProseEditor.showCorrectTranscriptModal()
    } else {
      setPlayingSelectionHighlight(myProseEditor.view)
      // editorSection.style.boxShadow = ''
      // editorContainer.style.fontStyle = ''

      if (document.getElementById('correctTranscriptId')) {
        document.getElementById('correctTranscriptId').remove()
      }
    }
  }
}
export function setupCorrectTranscriptPopup(view, shouldSetPlayingHighlight = false) {
  document.getElementById('selectionToolbar').style.display = 'none'
  function getNode(n, v) {
    n = document.createElementNS('http://www.w3.org/2000/svg', n)
    for (let p in v) { n.setAttributeNS(null, p, v[p]) }
    return n
  }

  if (document.getElementById('correctTranscriptId')) {
    document.getElementById('correctTranscriptId').remove()
  }
  let root = document.createElement('div')
  root.className = 'proto-tooltip'
  root.id = 'correctTranscriptId'
  let inputElement = document.createElement('input')
  inputElement.value = window.getSelection().toString().trim()
  inputElement.className = 'proto-input'
  inputElement.type = 'text'

  let downArrow = document.createElement('div')
  downArrow.className = 'proto-down-arrow'

  let downArrowSVG = getNode('svg', { width: 17, height: 9, viewBox: '0 0 17 9', fill: 'none' })
  downArrowSVG.appendChild(getNode('path', { fill: '#000228', d: 'M8.76417 9L16.9806 0.808527H0L8.76417 9Z' }))
  downArrow.appendChild(downArrowSVG)

  let buttonElement = document.createElement('button')
  buttonElement.className = 'proto-button'
  buttonElement.innerHTML = 'Correct'

  root.appendChild(inputElement)
  root.appendChild(buttonElement)
  root.appendChild(downArrow)

  view.dom.parentNode.appendChild(root)
  let { from, to } = view.state.selection
  if (shouldSetPlayingHighlight) {
    setPlayingSelectionHighlight(view, from, to)
  }
  // These are in screen coordinates
  let start = view.coordsAtPos(from)
  let end = view.coordsAtPos(to)
  let left = Math.min((start.left + end.left) / 2, end.left + 3)
  let top = Math.max((start.top + end.top) / 2, end.top + 3) - 40
  root.style.left = (left - 120) + 'px'
  root.style.top = top + 'px'

  inputElement.select()
  inputElement.focus()

  return { inputElement, buttonElement }
}
export function correctTranscript(view, start, end, inputElement) {
  let state = view.state
  let startTime = -1
  let endTime = -1.0
  if (state.selection.content() && state.selection.content().content &&
    state.selection.content().content.content) {
    state.selection.content().content.content.forEach(node => {
      node.content.forEach(textNode => {
        let infoMark = getInfoMarkFromTextNode(textNode).attrs
        if (infoMark) {
          if (startTime === -1) {
            startTime = infoMark.astart
          }
          endTime = infoMark.aend
        }
      })
    })
  }
  let selectedNode = view.state.doc.nodeAt(state.selection.head)
  if (!selectedNode) {
    selectedNode = view.state.doc.nodeAt(state.selection.anchor)
  }
  let transaction = view.state.tr
  if (selectedNode) {
    let infoMark = getInfoMarkFromTextNode(selectedNode)
    let nonInfoMarks = getNonInfoMarksFromTextNode(selectedNode)

    infoMark = infoMark.attrs

    let duration = endTime - startTime
    let words = inputElement.value.trim().split(' ')
    let noOfWords = words.length
    let durationDistribution = duration / noOfWords
    let newPosition = start
    transaction = transaction.delete(start, end)

    words.forEach((word, index) => {
      let astart = startTime + (index * durationDistribution)
      let aend = startTime + ((index + 1) * durationDistribution)
      let textNode = createTextNode(view, word + ' ', infoMark.uid, infoMark.asource, astart, aend, infoMark.mediaType, infoMark.class)
      transaction = transaction.insert(newPosition, textNode)
      nonInfoMarks.forEach(mark => {
        transaction = transaction.addMark(newPosition, newPosition + textNode.nodeSize, mark)
      })

      newPosition += textNode.nodeSize
    })
  }
  document._correctTranscriptSelection = {
    from: 0,
    to: 0
  }
  view.dispatch(
    transaction.setMeta('correction', true)
  )
  view.focus()
  // replaceWordAtLocation({ start: start, end: end }, inputElement.value)
  if (document.getElementById('correctTranscriptId')) {
    document.getElementById('correctTranscriptId').remove()
  }
}
export function getStateFromJsonDoc(jsonDoc) {
  let ndoc = Node.fromJSON(schema, jsonDoc)
  let state = EditorState.create({
    doc: ndoc
  })
  return state
}

export function toggleAddButton(show) {
  const addbtn = document.getElementById('plusToolbar')
  if (!addbtn) return
  if (show) {
    addbtn.style.display = ''
  } else {
    addbtn.style.display = 'none'
  }
}
export function getUidFromPillElementId(str) {
  return str.substring(str.indexOf('-') + 1)
}

export function checkForAttrInParentNodes(elm, uid) {
  let element = elm
  while (element.parentNode) {
    if (element.getAttribute(uid)) return true
    element = element.parentNode
  }
  return false
}

export function checkForIdInParentNodes(elm, id) {
  let element = elm
  while (element.parentNode) {
    if (element.id.includes(id)) return true
    element = element.parentNode
  }
  return false
}

export function removeAllClipStuff(view) {
  const transaction = view.state.tr
  const pills = document.querySelectorAll('.clip-pill')
  if (!pills.length) return
  let pillCount = 0
  let minPos = 0
  let maxPos = 0
  pills.forEach(pill => {
    const pillDomPos = pill.getBoundingClientRect()
    const pillPos = view.posAtCoords({ left: pillDomPos.left, top: pillDomPos.top })

    if (!pillPos) return
    const pillNode = view.state.doc.nodeAt(pillPos.pos)

    if (!pillNode) return
    if (pillNode.type.name === 'clip') {
      const adjustedPos = pillPos.pos - (pillNode.nodeSize * pillCount) - pillCount * 2
      if (minPos === 0 || adjustedPos < minPos) {
        minPos = adjustedPos
      } else if (adjustedPos > maxPos) {
        maxPos = adjustedPos
      }

      transaction.delete(adjustedPos, adjustedPos + pillNode.nodeSize)
      pillCount += 1
    }
  })

  transaction.removeMark(minPos, maxPos, view.state.schema.marks['clipHighlight'])
  transaction.setMeta('clipper', true)
  view.dispatch(transaction)
}
