import Vue from 'vue'
import Vuex from 'vuex'
import editor from './modules/editorStore'
import comment from './modules/commentStore'
import audio from './modules/audioStore'
import modal from './modules/modalStore'
import doc from './modules/docStore'
import app from './modules/appStore'
import smartEditConfig from './modules/smartEditConfig'
import dialogs from './modules/dialogs'
import blockmode from './modules/blockMode'
import video from './modules/video'
import clipper from './modules/clipStore'
import customAuth from './modules/customAuth'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    comment,
    dialogs,
    editor,
    audio,
    blockmode,
    modal,
    doc,
    app,
    smartEditConfig,
    video,
    clipper,
    customAuth
  }
})
