<template>
  <div class="inset-0 z-100 fixed overflow-y-auto" :class="overlayClass">
    <div class="min-h-screen max-h-screen text-center p-0 px-4 pt-4 pb-20 block items-end justify-center">
      <div class="bg-primary-dark opacity-80 inset-0 transition-opacity fixed"  :class="overlayClass" @click="onClickAway" />
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="bg-white rounded-lg text-left transform transition-all relative inline-block align-middle" :class="containerClass">
        <div @click="close" :class="['absolute top-4 right-4 z-10 cursor-pointer text-primary-500 hover:text-primary-700', closePosition]" >
          <CloseIcon :classname="'h-5 w-5 ' + closeSize" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
// TODO: add this component to storybook
import { mapGetters, mapActions } from 'vuex'
import CloseIcon from '@/components/base/icons/Close.vue'

export default {
  components: {
    CloseIcon
  },
  props: {
    closeSize: {
      type: String,
      default: ''
    },
    closePosition: {
      type: String,
      default: ''
    },
    onClose: {
      type: Function,
      default: () => null
    },
    disableCloseOnClickAway: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: ''
    },
    overlayClass: {
      type: String,
      default: ''
    }
  },
  mounted() {
    const vm = this
    window.addEventListener('keyup', function(e) {
      if (e.key === 'Escape' && vm.openModalName) {
        vm.closeModal()
      }
    })
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal'
    }),
    close: function() {
      this.closeModal()
      this.onClose()
    },
    onClickAway: function (params) {
      if (!this.disableCloseOnClickAway) this.close()
    }
  },
  computed: {
    ...mapGetters({
      openModalName: 'dialogs/modalName'
    })
  },
  beforeDestroy() {
    window.removeEventListener('keyup', () => {})
  }
}
</script>

<style scoped>
  .z-100 {
    z-index: 100;
  }
</style>
