import Vue from 'vue'
import { getURLfromPath } from '@/view/voiceEditor/vue/components/AddFiles/utility'
import store from '@/services/store'

const getDefaultState = () => (
  {
    shareJSON: {},
    selectedFile: null,
    showNotifications: false,
    addFilePopoverConfig: {
      show: false,
      showFullScreen: true,
      transition: 'bounce-center',
      style: {
        width: '',
        margin: 'auto',
        'max-height': '800px'
      },
      closeOnClickOutside: false,
      backdrop: true
    },
    soundMode: false,
    editMediaConfig: {
      show: false,
      isPositionFixed: true,
      closeOnClickOutside: true,
      transition: 'bounce-in-top',
      showFullScreen: true,
      style: {
        width: '750px',
        top: '20%'
      }
    },
    errorMessageConfig: {
      show: false,
      wrapperBackground: 'rgba(200, 200, 200, 0.4)',
      isPositionFixed: true,
      closeOnClickOutside: true,
      transition: 'bounce-in-top',
      showFullScreen: true,
      style: {
        width: 'max-content',
        top: '200px'
      }
    },
    exportMessageConfig: {
      show: false,
      showFullScreen: true,
      transition: 'bounce-center',
      style: {
        width: '750px',
        margin: 'auto',
        'border-radius': '20px'
      },
      closeOnClickOutside: false,
      backdrop: false
    },
    showCredits: false,
    blockPopoverConfig: {
      show: false,
      showFullScreen: true,
      transition: 'bounce-center',
      style: {
        width: '',
        margin: 'auto',
        'border-radius': '20px'
      },
      closeOnClickOutside: false,
      backdrop: true
    },
    currentStep: 'Top Up'
  }
)
export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    showNotifications: state => state.showNotifications
  },
  mutations: {
    setShareJSON (state, payload) {
      Vue.set(state, 'shareJSON', payload)
    },
    setSelectedFile (state, payload) {
      Vue.set(state, 'selectedFile', payload)
    },
    clearSelectedFile (state) {
      state.selectedFile = null
    },
    setStep (state, payload) {
      state.currentStep = payload
    },
    setSoundMode: function(state, payload) {
      state.soundMode = payload
    },
    setShowNotifications: function(state, payload) {
      state.showNotifications = payload
    }
  },
  actions: {
    showBlockMode ({ dispatch }) {
    },
    showBlockPopover ({ state }) {
      state.blockPopoverConfig.show = true
      document.body.setAttribute('style', 'overflow: hidden')
    },
    hideBlockPopover ({ state, dispatch }) {
      state.blockPopoverConfig.show = false
      document.body.setAttribute('style', '')
      // localStorage['onBoardingTrackMode'] = 'shown'
      setTimeout(function () {
        dispatch('showBlockMode')
      }, 0)
    },
    closePopovers ({ dispatch }) {
      dispatch('hideEditMedia')
    },
    showQueueModal ({ commit }) {
      commit('app/setNewPodcast')
      commit('setShowNotifications', true)
      $('body').addClass('overflow-hidden')
    },
    hideQueueModal ({ commit }) {
      commit('setShowNotifications', false)
      $('body').removeClass('overflow-hidden')
    },
    showShareModal ({ commit }, payload) {
      commit('setShareJSON', payload)
      $('.ui.share.modal')
        .modal('show')
    },
    addFile ({ state, dispatch, rootState, commit }, isReplacing = false) {
      let fileType = state.selectedFile['type']
      console.log('adding')
      if (!state.selectedFile) {
        return
      }
      store.dispatch('dialogs/closeModal')
      switch (state.selectedFile['type']) {
        case 'doc':
          console.log('type is doc', state.selectedFile['key'], rootState.app.podcastList[state.selectedFile['key']])
          if (!rootState.app.podcastList[state.selectedFile['key']].length || (rootState.app.podcastList[state.selectedFile['key']].length < 2 * 60 * 60)) {
            if (rootState.app.podcastList[state.selectedFile['key']].owner) {
              store.commit('doc/setNewDoc', `${state.selectedFile['key']}#!@${rootState.app.podcastList[state.selectedFile['key']].owner}`)
            } else {
              store.commit('doc/setNewDoc', state.selectedFile['key'])
            }
            window.analytics.track('drive_fileAdded', { type: fileType })
          } else {
            store.dispatch('dialogs/openModal', { name: 'ErrorMessage' })
          }
          break
        case 'library':
        case 'uploaded_media':
        case 'fresh_upload':
        case 'synthetic_audio':
          store.dispatch('editor/setEditorBlocked', { value: true,
            reason:
              isReplacing ? 'Replacing audio...' : 'Adding audio...' })
          // console.log('type is uploaded_media', state.selectedFile['type'], rootState.app.uploadedMedia[state.selectedFile['key']])
          let url = rootState.app.uploadedMedia[state.selectedFile['key']].url
          let title = rootState.app.uploadedMedia[state.selectedFile['key']].title
          // console.log('url,title', url, title)
          if (rootState.editor.audioControl && url && title) {
            getURLfromPath(url)
              .then(function (url) {
                console.log('url is', url)
                rootState.editor.audioControl.addMedia(url, title, `${state.selectedFile['key']}#!@${rootState.app.user.uid}`, isReplacing)
                store.commit('editor/setFreshDoc', false)
                window.analytics.track('drive_fileAdded', { type: fileType })
                if (!localStorage['firstAddFile']) {
                  window.Intercom('startTour', 58562)
                  localStorage['firstAddFile'] = 'shown'
                }
              })
          }
          break
        default:
          console.log('no type')
      }
    },
    hideAddFile ({ state, commit }) {
      document.body.setAttribute('style', '')
      state.addFilePopoverConfig.show = false
      commit('clearSelectedFile')
    },
    showAddFile ({ state }, payload) {
      state.soundMode = payload || false
      state.addFilePopoverConfig.show = true
      // let editor = document.getElementById('editor')
      var style = {
        left: 270 + 'px',
        width: 700 + 'px',
        height: '70vh'
      }
      Vue.set(state.addFilePopoverConfig, 'style', style)

      // Top to render popover when page scrolled
      state.addFilePopoverConfig.backdropTop = window.scrollY + 'px'

      // Preventing page scroll when popup opens
      document.body.setAttribute('style', 'overflow: hidden')
    },
    showEditMedia ({ state }) {
      state.editMediaConfig.show = true
    },
    hideEditMedia ({ state }) {
      state.editMediaConfig.show = false
    },
    showErrorMessage ({ state }) {
      state.errorMessageConfig.show = true
    },
    hideErrorMessage ({ state }) {
      state.errorMessageConfig.show = false
    },
    setShowCredits ({ state }, payload) {
      state.showCredits = payload
    },
    showExportMessage ({ state }) {
      state.exportMessageConfig.show = true
      state.exportMessageConfig.backdropTop = window.scrollY + 'px'
      document.body.setAttribute('style', 'overflow: hidden')
    }
  }
}
