// this mark is added to text that is added in transcript  -> in transcript mode
// no parse function for this, cause if copied, it's copied as usual
export const transcript = {
  attrs: {
    class: { default: 'pastedTranscript' }
  },
  toDOM: (mark) => {
    return ['span', mark.attrs]
  }
}
