import { ACTUAL_MODES, DEFAULT_MODE } from '../../utilities/utility'
export function getModeFromLocalStorage (fileId) {
  let lastState = localStorage['lastState'] ? JSON.parse(localStorage['lastState']) : null
  if (!lastState) {
    return DEFAULT_MODE
  }
  let file = lastState[fileId] ? lastState[fileId] : null
  if (!file) {
    return DEFAULT_MODE
  }
  let mode = file['mode'] ? file['mode'] : null
  if (!mode) {
    return DEFAULT_MODE
  }
  if (!(mode in ACTUAL_MODES)) {
    return DEFAULT_MODE
  }
  return mode
}

export function setModeInLocalStorage (fileId, mode, dontAsk) {
  let lastState = localStorage['lastState'] ? JSON.parse(localStorage['lastState']) : {}
  let file = lastState[fileId] ? lastState[fileId] : {}
  file['mode'] = mode
  if (dontAsk) file['dontask'] = dontAsk
  lastState[fileId] = file
  localStorage['lastState'] = JSON.stringify(lastState)
}

export function setEditMediaDontAsk (dontAsk) {
  let lastState = localStorage['lastState'] ? JSON.parse(localStorage['lastState']) : {}
  let global = lastState['global'] ? lastState['global'] : {}
  global['editMediaDontAsk'] = dontAsk
  lastState['global'] = global
  localStorage['lastState'] = JSON.stringify(lastState)
}

export function getEditMediaDontAsk () {
  let defaultMode = false
  let lastState = localStorage['lastState'] ? JSON.parse(localStorage['lastState']) : null
  if (!lastState) {
    return defaultMode
  }
  let global = lastState['global'] ? lastState['global'] : null
  if (!global) {
    return defaultMode
  }
  let mode = global['editMediaDontAsk'] ? global['editMediaDontAsk'] : null
  if (!mode) {
    return defaultMode
  }
  return mode
}

export function setExportMessageDontAsk (dontAsk) {
  let lastState = localStorage['lastState'] ? JSON.parse(localStorage['lastState']) : {}
  let global = lastState['global'] ? lastState['global'] : {}
  global['errorMessageDontAsk'] = dontAsk
  lastState['global'] = global
  localStorage['lastState'] = JSON.stringify(lastState)
}

export function getExportMessageDontAsk () {
  let defaultMode = false
  let lastState = localStorage['lastState'] ? JSON.parse(localStorage['lastState']) : null
  if (!lastState) {
    return defaultMode
  }
  let global = lastState['global'] ? lastState['global'] : null
  if (!global) {
    return defaultMode
  }
  let mode = global['errorMessageDontAsk'] ? global['errorMessageDontAsk'] : null
  if (!mode) {
    return defaultMode
  }
  return mode
}

export function setShowTrialMessage (dontAsk) {
  let lastState = sessionStorage['lastState'] ? JSON.parse(sessionStorage['lastState']) : {}
  let global = lastState['global'] ? lastState['global'] : {}
  global['showTrialMessage'] = dontAsk
  lastState['global'] = global
  sessionStorage['lastState'] = JSON.stringify(lastState)
}

export function getShowTrialMessage () {
  let defaultMode = false
  let lastState = sessionStorage['lastState'] ? JSON.parse(sessionStorage['lastState']) : null
  if (!lastState) {
    return defaultMode
  }
  let global = lastState['global'] ? lastState['global'] : null
  if (!global) {
    return defaultMode
  }
  let mode = global['showTrialMessage'] ? global['showTrialMessage'] : null
  if (!mode) {
    return defaultMode
  }
  return mode
}
