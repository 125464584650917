<template>
  <div>
    <div
      v-show="
        !(loadingState === 'status_check' || loadingState === 'logging_in')
      "
      class="text-4xl font-bold text-center text-black font-h mb-s48 pt-s8 pr-s32 pl-s16 leading-tighter"
    >
      Log in or <br />
      Sign up
    </div>
    <div
      v-show="
        !(loadingState === 'status_check' || loadingState === 'logging_in')
      "
      id="firebaseui-auth-container"
    />
    <div
      class="z-10 w-full h-full flex justify-center items-center flex-col"
      v-show="loadingState === 'status_check' || loadingState === 'logging_in'"
    >
      <div
        class="text-4xl font-bold text-center text-black font-h mb-4 pt-16 pr-s32 pl-s16 leading-tighter"
      >
        <ellipses-loading>
          Taking you to the place <br />
          you’re looking for
        </ellipses-loading>
      </div>
      <img
        src="../../assets/images/auth_transition.png"
        alt="person holding a magnifying glass"
      />
    </div>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'
import axios from 'axios'
import uuidv1 from 'uuid/v1'
import CONSTANTS from '@/constants/CONSTANTS'
import { mapActions, mapGetters } from 'vuex'
import EllipsesLoading from '../base/display/EllipsesLoading.vue'
import store from '@/services/store'

require('@/assets/css/firebaseui.css')

export default {
  components: { EllipsesLoading },
  name: 'auth',
  data() {
    return {
      ui: '',
      loadingState: ''
    }
  },

  methods: {
    ...mapActions({
      setCustomToken: 'customAuth/setCustomToken'
    }),
    signInAttemptSuccessful(authResult) {
      if (
        location.hostname === 'localhost' ||
        location.hostname === '127.0.0.1'
      ) {
        return false
      }
      let vm = this
      let data = {
        userId: authResult.user.uid
      }
      let headers = {
        'Content-Type': 'application/json'
      }
      store.commit('app/setUFL', true)
      const env = process.env.NODE_ENV
      axios
        .post(CONSTANTS.CREATE_CUSTOM__AUTH_TOKEN, data, headers)
        .then(response => {
          firebase
            .auth()
            .signInWithCustomToken(response.data.token)
            .then(userCredential => {
              userCredential.user.getIdToken().then(idToken => {
                const cookie = document.cookie.match(
                  '(^|;) ?' + 'csrfToken' + '=([^;]*)(;|$)'
                )
                const csrfToken = cookie ? cookie[2] : null
                const url = `${location.origin}/${
                  env === 'production' ? 'auth' : 'dev/auth'
                }`
                axios({
                  url: url,
                  method: 'POST',
                  data: {
                    idToken: idToken,
                    csrfToken: csrfToken
                  }
                })
                  .then(value => {
                    store.commit('app/setUFL', false)
                    if (value.data.status === 1) {
                      let credential = authResult.credential
                      let provider = ''
                      if (credential) {
                        provider = credential.providerId.replace('.com', '')
                      }
                      vm.emit('closeLogin')
                      window.analytics.track('website_loggedIn', {
                        provider: provider
                      })
                      return false
                    }
                  })
                  .catch(reason => {
                    console.error(reason)
                  })
              })
            })
        })
        .catch(err => {
          console.error('error', err)
        })
    },
    getStringForLoginState(state) {
      switch (state) {
        case 'status_check':
          return 'Please wait...'
        case 'logging_in':
          return 'Logging you in...'
      }
    }
  },
  mounted() {
    let vm = this
    let uiConfig = {}
    uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: vm.signInAttemptSuccessful
      },
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            // Forces account selection even when one account
            // is available.
            prompt: 'select_account'
          }
        },
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true
        }
      ]
    }
    // }
    if (this.ui !== '') {
      this.ui.reset()
    } else {
      this.ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth())
    }
    if (
      location.hostname === 'localhost' ||
      location.hostname === '127.0.0.1'
    ) {
      vm.ui.start('#firebaseui-auth-container', uiConfig)
      return
    }
    this.loadingState = 'status_check'
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    const url = `${location.origin}/${
      process.env.NODE_ENV === 'production' ? 'auth/status' : 'dev/auth/status'
    }`
    axios.get(url, { 'Access-Control-Allow-Origin': '*' }).then(res => {
      if (res.data.status === 1) {
        this.loadingState = 'logging_in'
        let data = {
          userId: res.data.data.uid
        }
        let headers = {
          'Content-Type': 'application/json'
        }
        axios
          .post(CONSTANTS.CREATE_CUSTOM__AUTH_TOKEN, data, headers)
          .then(response => {
            this.setCustomToken(response.data.token)
            firebase
              .auth()
              .signInWithCustomToken(response.data.token)
              .then(userCredential => {
                userCredential.user.getIdToken().then(idToken => {
                  store.commit('app/setUFL', false)

                  if (idToken) {
                    // self.$router.push('success')
                  }
                  this.loadingState = 'login_success'
                })
              })
          })
        vm.ui.start('#firebaseui-auth-container', '')
      } else {
        this.loadingState = ''
        vm.ui.start('#firebaseui-auth-container', uiConfig)
      }
    })
    vm.$cookies.set('csrfToken', uuidv1())
  }
}
</script>

<style lang="css"></style>
