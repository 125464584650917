<template>
  <div id="app">
    <!-- router view is from vue, this does client side navigation, router/index.js -->
    <router-view
      ref="child"
    />
    <!--Notifications -->
    <Queue
      :computedList="computedList"
      :negativeSeconds="negativeSeconds"
      @retryPodcast="retryPodcast"
      ref="queue"
    />
    <!-- Upload modal -->
    <Upload
      v-show="isUploadVisible"
      :podcastListLength="podcastListLength"
      :user="user"
      :minimal="minimalUpload"
      :videoOnly="$route.name === 'project'"
      ref="upload"
      @notifyFileAdded="notifyFileAdded"
      @addToUploadList="addToUploadList"
      @fileAdded="fileAdded"
    />
    <div class="vtoast">
      <VueToastr ref="toastr" />
    </div>
    <Dialog />
    <AccountInfo />
    <Auth v-show="false"/>
  </div>
</template>

<script>
import Vue from 'vue'
import VueTour from 'vue-tour'
import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/messaging'
import 'firebase/functions'

import CONSTANTS from '@/constants/CONSTANTS'

import axios from 'axios'
import store from '@/services/store'
import VueToastr from 'vue-toastr'
import Queue from '@/components/QueueNew'
import Upload from '@/components/modals/FileUpload/index.vue'
import Dialog from '@/components/modals/index.vue'
import AccountInfo from '@/components/modals/AccountInfoModal/index.vue'
import {
  geCurrentUserJSON,
  getUserInfo,
  initAuth,
  isUserEmailVerified,
  reloadUser,
  setOnboardingState,
  updateUserInfo,
  verifyEmail
} from '@/services/api/account'
import { humanTime, statusToPercentage } from '@/utilities/utility'
import {
  myProseEditor,
  setPlayingSelectionHighlight,
  toggleTranscriptMode
} from '@/view/voiceEditor/proseEditor/util/utility'
import { mapActions, mapGetters } from 'vuex'
import Auth from '@/components/auth/Auth'
const uuidv1 = require('uuid/v1')

Vue.use(VueTour)

export default {
  name: 'app',
  components: {
    VueToastr,
    Queue,
    Upload,
    Dialog,
    AccountInfo,
    Auth
  },
  data () {
    return {
      user: {
        displayName: 'Display Name',
        photoURL: null
      },
      loading: true,
      // required by queue
      uploadList: {},
      newPodcast: false,
      negativeSeconds: 0,
      now: Date.now(),
      interval: '',
      offline: false,
      errorCode: CONSTANTS.ERROR_CODES,
      messaging: '',
      emailVerified: false,
      timerId: '',
      retryCount: parseInt(localStorage['retryCount']) || 0,
      countdown: 0,
      countdownId: '',
      isUploadVisible: false,
      minimalUpload: false,
      currentPath: '/'
    }
  },
  watch: {
    computedList: {
      handler: function (val) {
        store.dispatch('app/setComputedList', val)
      },
      deep: true
    },
    balance () {
      if (this.$refs.upload.duration > 0) {
        if (this.$refs.upload.duration <= this.balance) {
          console.log('setting url valid')
          this.$refs.upload.urlValid = true
        }
      }
    },
    uploadingList (oldVal, newVal) {
      console.log('uploading oldval', oldVal)
      console.log('uploading newval', newVal)
      if (Object.keys(newVal).length > 0) {
        console.log('handlingnew', newVal[Object.keys(newVal)[0]].task.state_)
      }
      for (let key in newVal) {
        if (newVal[key].task.state_ === 'success' || newVal[key].task.state_ === 'canceled') {
          store.commit('app/removeFromUploadingList', key)
        }
      }
    },
    isUserAllowed: {
      handler(val) {
        if ((this.currentPath === `/audio/${CONSTANTS.demoUserid}/${CONSTANTS.demoMediaid}`) || CONSTANTS.whitelistedPaths.includes(this.currentPath)) {
          return
        }
        if (!val) {
          this.$router.replace('/shutdown-notice')
        }
      }
    }
  },
  created () {
    window.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.code === 'KeyK' && store.state.editor.editMode === 'editMedia') {
        toggleTranscriptMode()
        event.preventDefault()
      }
    })
    // console.log('created app')
    let vm = this
    let authChangeCallback = function(user) {
      if (user) {
        if (user.isAnonymous) {
          console.log('isAnonymous')
          let nUser = {}
          nUser['displayName'] = 'Guest'
          nUser['uid'] = user.uid
          nUser['photoURL'] = null
          nUser['isAnonymous'] = true
          store.commit('app/setUser', nUser)
        } else {
          if (vm.$route.name === 'index') {
            if (vm.$router.currentRoute.query.redirect) {
              vm.$router.push(vm.$router.currentRoute.query.redirect)
            } else {
              vm.$router.replace({ name: 'drive' })
            }
          }
          store.commit('app/setUser', user)
          // vm.user = user.toJSON()
          user = JSON.parse(JSON.stringify(user))
          if (user.providerData && user.providerData[0] && (user.providerData[0].providerId === 'facebook.com' || user.providerData[0].providerId === 'twitter.com')) {
            user.emailVerified = true
          }
          vm.user = user
          updateUserInfo(user)
          // vm.user = user.toJSON()
          // console.log('app value of user is', user)

          vm.emailVerified = user.emailVerified
          if (!vm.emailVerified && vm.retryCount < 1 && vm.countdown === 0) {
            vm.verifyEmail()
          }
          vm.getPodcastList(user.uid)
          if (vm.emailVerified) {
            vm.setUserOnboardingState(user.uid)
          }
          let userInfoCallback = function(userInfo) {
            store.commit('app/clearUserInfo')
            store.dispatch('app/setUserInfo', userInfo)
          }
          getUserInfo(vm.user.uid, userInfoCallback)
          window.analytics.identify(user.uid, {
            name: user.displayName,
            email: user.email
          })
        }
        store.dispatch('app/getUploadedMedia')
      } else {
        // if (process.env.NODE_ENV === 'production') {//
        //   axios.get(location.origin + '/auth/logout').then((res) => {
        //     if (res.data.status === 1) {
        //       if (window.analytics.reset) window.analytics.reset()
        //       if (vm.$route.name === 'drive' || (vm.$route.name === 'HomeProse' && !vm.$route.query.exportFile)) {
        //         vm.$router.replace({ name: 'index' })
        //         location.reload()
        //       }
        //     }
        //   })
        //     .catch(function (error) {
        //       console.error(error)
        //     })
        // } else {//
        if (window.analytics.reset)window.analytics.reset()
        if (vm.$route.name === 'drive' || (vm.$route.name === 'HomeProse' && !vm.$route.query.exportFile)) {
          vm.$router.replace({ name: 'index' })
          location.reload()
        }
        // }//
      }
    }
    initAuth(authChangeCallback)
  },
  mounted () {
    let vm = this
    window.addEventListener('online', vm.networkChange)
    window.addEventListener('offline', vm.networkChange)
    vm.setIsMobile()
    vm.setBrowser()
    $(document).ready(function () {
      window.onbeforeunload = vm.askConfirm
    })
    vm.messaging = firebase.messaging()
    // Add the public key generated from the console here.
    vm.messaging.usePublicVapidKey(CONSTANTS.PUBLIC_VAPID_KEY)
    navigator.serviceWorker.register('/static/js/firebase-messaging-sw.js')
      .then((registration) => {
        vm.messaging.useServiceWorker(registration)
      })

    window.analytics.track('website_userArrived')
    vm.currentPath = window.location.pathname
  },
  methods: {
    ...mapActions({
      'openModal': 'dialogs/openModal'
    }),
    checkForInvite () {
      if (localStorage['invite']) {
        this.$router.replace({ 'name': 'Invite', 'params': { 'adminid': localStorage['invite'] } })
      } else if (localStorage['teamInvite'] && localStorage['invitedEmail']) {
        this.$router.replace({ 'name': 'TeamInvite', 'query': { 'adminid': localStorage['teamInvite'], 'invitedEmail': localStorage['invitedEmail'] } })
      }
    },
    verifyEmail () {
      let vm = this
      let successCallback = function() {
        vm.showToast(`Email sent`, 'success')
        vm.reloadUserInfo()
        vm.beginCountdown()
      }
      let failureCallback = function() {
        vm.showToast(`Unable to send Email to you.`, 'error')
        vm.retryCount = 100
        localStorage['retryCount'] = vm.retryCount
      }
      verifyEmail(successCallback, failureCallback)
    },
    reloadUserInfo () {
      let vm = this
      if (vm.timerId !== '') {
        clearInterval(vm.timerId)
      }
      vm.timerId = setInterval(checkAgain, 1000)
      function checkAgain () {
        // console.log('checking again')
        reloadUser(a => {})
        if (isUserEmailVerified()) {
          vm.emailVerified = true
          console.log('askdjcnas', vm.userOnboardingState)
          vm.setUserOnboardingState(vm.user.uid)
          clearInterval(vm.timerId)
          updateUserInfo(geCurrentUserJSON())
          vm.checkForInvite()
        }
      }
    },
    beginCountdown () {
      let vm = this
      if (vm.countdownId !== '') {
        clearInterval(vm.countdownId)
      }
      vm.countdown = 20
      vm.countdownId = setInterval(reduceTime, 1000)
      function reduceTime () {
        vm.countdown -= 1
        if (vm.countdown === 0) {
          clearInterval(vm.countdownId)
        }
      }
    },
    askConfirm: function () {
      console.log('confirming')
      if (Object.keys(this.uploadingList).length > 0) {
        return 'Uploads in progress. Please wait'
      }
    },
    setIsMobile: function () {
      store.dispatch('app/setIsMobile')
    },
    setBrowser () {
      store.dispatch('app/setBrowser')
    },
    networkChange (e) {
      if (e.type === 'offline') {
        this.offline = true
      } else {
        this.offline = false
      }
    },
    startQueueProgress () {
      let vm = this
      vm.interval = setInterval(() => {
        if (!vm.offline) {
          console.log('updated now')
          vm.now = Date.now()
        }
      }, 5000)
    },
    stopQueueProgress () {
      let vm = this
      clearInterval(vm.interval)
    },
    getPodcastList (uid) {
      // console.log(uid)
      let vm = this
      firebase.functions().httpsCallable('getSharedWithMe')().then(function (res) {
        // console.log(res)
        let sharedWithMe = res.data
        for (let podid in sharedWithMe) {
          store.dispatch('app/addToPodcastList', { key: podid, value: sharedWithMe[podid] })
        }
        vm.loading = false
      }).catch(function () {
        vm.loading = false
      })
      firebase.database().ref(uid + '/podcast-info/').on('child_added', function (childSnapshot) {
        // console.log('child_added')
        store.dispatch('app/addToPodcastList', { key: childSnapshot.key, value: childSnapshot.val() })
      })
      firebase.database().ref(uid + '/podcast-info/').on('child_changed', function (childSnapshot) {
        // console.log('child_changed')
        store.dispatch('app/addToPodcastList', { key: childSnapshot.key, value: childSnapshot.val() })
        vm.loading = false
      })
      vm.loading = false
    },
    newPodcastAvailable (time) {
      let vm = this

      if (vm.latestTime === '' || (new Date(vm.latestTime) < new Date(time))) {
        // console.log('time set to', time)
        store.commit('app/setNewPodcast', time)
      }
    },
    retryPodcast (forced, podId, length) {
      console.log(length)
      if (this.balance < length) {
        this.showToast(`Balance is less`, 'error')
        return
      }
      let vm = this
      if (this.user.uid !== '' && podId !== '') {
        if (vm.podId === podId) {
          if (vm.observer) {
            console.log('removing observer')
            vm.observer.disconnect()
          }
          vm.list = []
          vm.listHtml = ''
          vm.podId = ''
        }
        console.log('retrying: ' + podId)
        if (forced) {
          firebase.database().ref(vm.user.uid + '/podcast-info/' + podId).update({
            retry: uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1'),
            status: {
              code: 100,
              msg: 'waiting'
            },
            trial: false,
            transcribed: false,
            updated: Date().toString()
          })
            .then(function () {
              console.log('retrying, forced')
              let logRef = firebase.database().ref(vm.user.uid + '/podcast-info/' + podId + '/log').push()
              return logRef.set({
                status: 'waiting',
                timestamp: Date().toString()
              })
            })
            .catch(function () {
              console.log('cant retry')
            })
        } else {
          axios(
            {
              method: 'get',
              url: CONSTANTS.RETRY,
              params: {
                id: vm.user.uid,
                name: podId
              }
            }).then(function (res) {
            console.log('retrying, not forced', res)
          }).catch(function (err) {
            console.log('err', err)
          })
        }
      }
    },
    setUserOnboardingState (uid) {
      const vm = this
      firebase.database().ref(uid + '/userInfo/onboarding/state').on('value', function (snapshot) {
        const val = snapshot.val()
        if (val) store.commit('app/setOnboardingState', val)
        if (val === '1') {
          if (vm.isUserAllowed) {
            vm.$router.push('/onboarding')
          }
        }
      })
    },
    addToUploadList (podId, file, id) {
      console.log('--------------------')
      console.log('podId', podId)
      console.log('file', file)
      console.log('id', id)
      console.log('--------------------')
      let vm = this
      let metadata = {
        'contentType': file.type
      }
      let storageRef = firebase.storage().ref('podcast_audio/' + this.user.uid + '/uploaded_Audio/' + podId + '/' + id)
      if (Object.keys(vm.uploadList).indexOf(podId) < 0) {
        this.uploadList[podId] = {}
      } else {
        if (this.uploadList[podId][id] !== undefined) {
          this.uploadList[podId][id].cancel()
          storageRef.delete()
        }
      }
      // upload file
      let task = storageRef.put(file, metadata)
      this.uploadList[podId][id] = task
    },
    showToast: function(msg = '', type = 'info') {
      this.$refs.toastr.Add({
        msg,
        type,
        title: type === 'info' ? '💡' : '',
        position: 'toast-bottom-center',
        progressbar: false,
        preventDuplicates: true,
        timeout: 4000
      })
    },
    notifyFileAdded (podid) {
      // if (this.$refs.child.showToast) this.$refs.child.showToast(`File Added`, 'success')
      let purpose = localStorage['purpose']
      if (!purpose) store.commit('app/setNewFileID', podid)
    },
    fileAdded: async function (getFile, title, language, engine) {
      let vm = this
      const contentType = getFile.type
      let metadata = { 'contentType': contentType }
      console.log('route', vm.$route.name)
      // set storage ref
      let podId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      vm.notifyFileAdded(podId)
      let storageRef = firebase.storage().ref('podcast_audio/' + this.user.uid + '/uploaded_Audio/' + podId)
      // upload file
      let task = storageRef.put(getFile, metadata)
      if (vm.$route.name === 'audiogram') {
        store.commit('app/setProjectDocId', { title, uid: podId })
        vm.showToast(`You can close this tab and revisit it once the upload is complete. We will notify you via email.`)
      }
      if (vm.$route.name === 'project') {
        if (contentType.startsWith('video')) {
          store.commit('app/setProjectDocId', { title, uid: podId })
          vm.showToast(`You can close this tab and revisit it once the upload is complete. We will notify you via email.`)
        } else vm.showToast(`Please upload a video file.`, 'error')
      }
      if (vm.$route.name === 'onboarding') {
        // Once user is done uploading after onboarding page, redirect them to drive and close the modal.
        await setOnboardingState(vm.user.uid, '2')
        vm.closeUploadModal('/drive')
      }
      store.commit('app/addToUploadingList', { 'title': title, 'task': task, podId: podId })
      let trial = !!vm.billing.trial
      task.then(function (snapshot) {
        console.log('snapshot', snapshot)
        if (['project', 'audiogram'].includes(vm.$route.name)) store.commit('app/setProjectDocId', null)
        store.commit('app/removeFromUploadingList', podId)

        // let url = snapshot.downloadURL
        let url = snapshot.ref.fullPath
        console.log(url)
        return firebase.database().ref(vm.user.uid + '/podcast-info/' + podId).set({
          url,
          title,
          language,
          engine,
          created: Date().toString(),
          updated: Date().toString(),
          status: {
            code: 100,
            msg: 'waiting'
          },
          log: {
            1: {
              status: 'waiting',
              timestamp: Date().toString()
            }
          },
          trial,
          index: vm.podcastListLength,
          version: 2,
          opened: Date.now()
        })
      })
        .then(function () {
        })
        .catch(function (error) {
          console.error('Upload failed:', error)
        })
    },
    urlExists (url) {
      // Not being used anywhere
      let exists = false
      let vm = this
      Object.keys(vm.podcastList).forEach(function (pkey) {
        if (vm.podcastList[pkey]['url'] && vm.podcastList[pkey]['url'].toLowerCase() === url.toLowerCase()) {
          exists = true
        }
      })
      return exists
    },
    titleExists (title) {
      console.log(title)
      let exists = false
      let vm = this
      Object.keys(vm.podcastList).forEach(function (pkey) {
        // skip the ones which are deleted
        if (!(vm.podcastList[pkey]['deleted'] && vm.podcastList[pkey]['deleted'] === true)) {
          if (vm.podcastList[pkey]['title'] && vm.podcastList[pkey]['title'].toString().toLowerCase() === title.toLowerCase()) {
            exists = true
          }
        }
      })
      return exists
    },
    showUploadModal: function(minimal = false) {
      window.analytics.track('drive_uploadButtonClicked')
      this.$refs.upload.clear()
      this.isUploadVisible = true
      this.minimalUpload = minimal
      $('body').addClass('overflow-hidden')
    },
    closeUploadModal (redirect = null) {
      this.isUploadVisible = false
      // If someone closes the upload modal on /onboarding, we need to open the WelcomeModal again.
      if (redirect) this.$router.push(redirect)
      else if (window.location.pathname === '/onboarding') this.openModal({ name: 'WelcomeModal' })
      $('body').removeClass('overflow-hidden')
    }
  },
  computed: {
    ...mapGetters({
      userOnboardingState: 'app/onboardingState',
      isUserAllowed: 'app/isUserAllowed'
    }),
    isMobile () {
      return store.state.app.isMobile
    },
    balance () {
      return store.state.app.userInfo.billing.seconds
    },
    billing () {
      return store.state.app.userInfo.billing
    },
    browserIs () {
      return store.state.app.browserIs
    },
    latestTime () {
      return store.state.app.latestTime
    },
    uploadingList () {
      return store.state.app.uploadingList
    },
    podcastList () {
      return store.state.app.podcastList
    },
    podcastListLength () {
      return Object.keys(store.state.app.podcastList).length
    },
    isCompatibleBrowser () {
      if (this.browserIs === 'chrome' && CONSTANTS.COMPATIBLE_BROWSERS['chrome']) {
        return true
      } else if (this.browserIs === 'safari' && CONSTANTS.COMPATIBLE_BROWSERS['safari']) {
        return true
      } else if (this.browserIs === 'firefox' && CONSTANTS.COMPATIBLE_BROWSERS['firefox']) {
        return true
      }
      return false
    },
    computedList: function () {
      // console.log('computing')
      let list = []
      let vm = this
      vm.negativeSeconds = 0
      Object.keys(this.podcastList).forEach(function (pkey) {
        let rawPodcast = vm.podcastList[pkey]
        if (rawPodcast.deleted) {
          return
        }
        // Remove useless podcasts here
        if (pkey.indexOf('watson') >= 0 ||
          rawPodcast.status === undefined ||
          rawPodcast.status.code === undefined) {
          return
        }

        let podcast = {}

        // key
        podcast['key'] = pkey
        if (rawPodcast.owner) {
          // console.log('owner is', rawPodcast.owner)
          podcast['owner'] = rawPodcast.owner
        } else {
          podcast['owner'] = vm.user.uid
        }
        // created
        if (rawPodcast.created) {
          podcast['created'] = rawPodcast.created
        } else {
          podcast['created'] = Date().toString()
        }

        // updated
        if (rawPodcast.updated) {
          podcast['updated'] = rawPodcast.updated
        } else if (rawPodcast.created) {
          podcast['updated'] = podcast['created']
        } else {
          podcast['updated'] = Date().toString()
        }

        // opened
        podcast['opened'] = rawPodcast.opened || false
        // is a video?
        podcast['isVideo'] = Boolean(rawPodcast.video_info)
        podcast['isClip'] = Boolean(rawPodcast.isClip)

        // audio_url
        if (rawPodcast.audio_url) {
          podcast['audio_url'] = rawPodcast.audio_url
        }
        if (vm.browserIs === 'chrome' && rawPodcast.audio_url_op) {
          podcast['audio_url'] = rawPodcast.audio_url_op
        } else if (vm.browserIs === 'safari') {
          if (rawPodcast.audio_url_safari) {
            podcast['audio_url'] = rawPodcast.audio_url_safari
          } else if (rawPodcast.audio_url_mp3) {
            podcast['audio_url'] = rawPodcast.audio_url_mp3
          }
        }

        // title
        if (rawPodcast.title) {
          podcast['title'] = rawPodcast.title
        } else {
          podcast['title'] = 'Default Title'
        }

        // url TODO
        if (rawPodcast.url) {
          podcast['url'] = rawPodcast.url
        } else {
          podcast['url'] = 'No URL'
          if (pkey === 'Demo') {
            podcast['url'] = 'Demo'
          }
        }

        // trial
        if (rawPodcast.trial === true) {
          podcast['trial'] = true
        }

        // length
        if (rawPodcast.length && rawPodcast.length > 0) {
          podcast['length'] = Math.round(rawPodcast.length)
        } else {
          podcast['length'] = 0
        }

        // status
        // code
        if (rawPodcast.status && rawPodcast.status.code) {
          if (rawPodcast.status.code === 501 && rawPodcast.length) {
            vm.negativeSeconds += rawPodcast.length
          }

          if (rawPodcast.status.code in vm.errorCode) {
            podcast['status'] = vm.errorCode[rawPodcast.status.code]
          } else if (rawPodcast.status.code > 400) {
            rawPodcast.status.code = 503
            podcast['status'] = vm.errorCode[rawPodcast.status.code]
          } else if (rawPodcast.status.code === 205) {
            if (rawPodcast['invited']) {
              podcast['status'] = `Uploaded ${humanTime(podcast['updated'])}. Shared with ${Object.keys(rawPodcast['invited']).length} others`
            } else {
              podcast['status'] = `Uploaded ${humanTime(podcast['created'])}`
            }
            if (!localStorage['lastSeen'] || new Date(localStorage['lastSeen']) < new Date(podcast['updated'])) {
              vm.newPodcastAvailable(podcast['updated'])
            }
          } else if (rawPodcast.status.code > 205) {
            if (rawPodcast['invited']) {
              podcast['status'] = `Edited ${humanTime(podcast['updated'])}. Shared with ${Object.keys(rawPodcast['invited']).length} others`
            } else {
              podcast['status'] = `Edited ${humanTime(podcast['updated'])}`
            }
          } else {
            podcast['status'] = 'Transcribing'
          }
          podcast['code'] = rawPodcast.status.code
        } else {
          podcast['code'] = 200
          podcast['status'] = 'Processing'
        }

        // Progress
        let audioLengthMS = rawPodcast['length'] * 1000
        const twentyMinMS = 20 * 60 * 1000
        let timeElapsed = vm.now - Date.parse(rawPodcast['created'])
        let totalExpectedTime = 1
        if (audioLengthMS > twentyMinMS) {
          totalExpectedTime = audioLengthMS
        } else {
          totalExpectedTime = twentyMinMS
        }
        if (rawPodcast.status.code < 200) {
          podcast['progress'] = 0
        } else if (rawPodcast.status.code >= 200 && rawPodcast.status.code <= 204) {
          podcast['progress'] = statusToPercentage(rawPodcast.status.code, timeElapsed, totalExpectedTime)
        } else if (rawPodcast.status.code > 204) {
          podcast['progress'] = 1
        }

        // needs_review
        if (rawPodcast.needs_review) {
          podcast['needs_review'] = rawPodcast.needs_review
        } else {
          podcast['needs_review'] = 0
        }

        // o_video
        if (rawPodcast.o_video) {
          podcast['o_video'] = rawPodcast.o_video
        } else {
          podcast['o_video'] = false
        }

        // audio_editing enable
        // audio_editor_progress
        if (rawPodcast.audio_editor === true) {
          podcast['audio_editor'] = true
        } else if (rawPodcast.audio_editor === false) {
          podcast['audio_editor'] = false
        } else if (rawPodcast.audio_editor === 'processing') {
          podcast['audio_editor'] = 'processing'
        }
        if (podcast['audio_editor'] === 'processing') {
          if (rawPodcast.align_status && rawPodcast.seg_count) {
            let progress = 0
            for (let i in rawPodcast.align_status) {
              progress += rawPodcast.align_status[i]
            }
            let percentageProgress = progress / rawPodcast.seg_count
            podcast['audio_editor_progress'] = (parseFloat(percentageProgress) * 50 + podcast.progress * 50)
          } else {
            podcast['audio_editor_progress'] = (podcast.progress * 50)
          }
        }
        if (rawPodcast.audio_editor_actions) {
          podcast['audio_editor'] = 'processing'
        }

        // publish
        let publish = {}
        if (rawPodcast.publish && rawPodcast.publish.status) {
          publish['status'] = rawPodcast.publish.status
          publish['title'] = rawPodcast.publish.title
          publish['channel'] = rawPodcast.publish.channel_id
          if (rawPodcast.publish.owner && rawPodcast.publish.media_id) {
            publish['link'] = `https://publish.spext.co/publish/${rawPodcast.publish.owner}/${rawPodcast.publish.media_id}`
            publish['date'] = rawPodcast.publish.pub_date
          }
          podcast['publish'] = publish
        }

        // Exported List
        podcast['exportedList'] = {}
        if (rawPodcast.exported_files) {
          let keys = Object.keys(rawPodcast.exported_files)
          let exportFormats = ['mp4', 'mp3', 'wav', 'flac', 'ogg', 'snippet', 'fcpxml', 'docx', 'post_production', 'sesx']
          let exportedFiles = {}
          for (let i = 0; i < keys.length; i++) {
            if (exportFormats.indexOf(keys[i]) >= 0) {
              if (!exportedFiles['old']) {
                exportedFiles['old'] = {}
              }
              exportedFiles['old'][keys[i]] = rawPodcast.exported_files[keys[i]]
            } else {
              exportedFiles[keys[i]] = rawPodcast.exported_files[keys[i]]
            }
          }
          // show latest file
          let latestExportedFiles = {}

          for (let id in exportedFiles) {
            for (let type in exportedFiles[id]) {
              if (!(type in latestExportedFiles) || (exportedFiles[id][type]['timeFinish'] && latestExportedFiles[type]['time'] && Date.parse(latestExportedFiles[type]['time']) < Date.parse(exportedFiles[id][type]['timeFinish'])) ||
                (exportedFiles[id][type]['timestamp'] && latestExportedFiles[type]['time'] && Date.parse(latestExportedFiles[type]['time']) < Date.parse(exportedFiles[id][type]['timestamp']))) {
                latestExportedFiles[type] = exportedFiles[id][type]
                if (typeof (latestExportedFiles[type]) === 'object') {
                  if (!(type in latestExportedFiles)) {
                    latestExportedFiles[type] = {}
                  }
                  latestExportedFiles[type]['time'] = exportedFiles[id][type]['timeFinish'] || exportedFiles[id][type]['timestamp']
                }
              }
            }
          }
          podcast['exportedList'] = latestExportedFiles
        } else {
          if (rawPodcast.pdf_file) {
            if (typeof (rawPodcast.pdf_file) === 'object') {
              podcast['exportedList']['pdf'] = rawPodcast.pdf_file[Object.keys(rawPodcast.pdf_file)[Object.keys(rawPodcast.pdf_file).length - 1]]
            } else {
              podcast['exportedList']['pdf'] = rawPodcast.pdf_file
            }
          }
          if (rawPodcast.cut_video) {
            podcast['exportedList']['cut_video'] = rawPodcast.cut_video
          }

          if (rawPodcast.audio_editor_export && typeof (rawPodcast.audio_editor_export) === 'object') {
            podcast['exportedList']['audio_editor_export'] = rawPodcast.audio_editor_export
          }
          if (rawPodcast.post_production_url) {
            podcast['exportedList']['post_production'] = rawPodcast.post_production_url
          }
          if (rawPodcast.redact_audio) {
            podcast['exportedList']['redact_audio'] = rawPodcast.redact_audio
          }
          if (rawPodcast.redact_video) {
            podcast['exportedList']['redact_video'] = rawPodcast.redact_video
          }
        }

        // doc_status
        // contact us
        if (rawPodcast.status.code in vm.errorCode) {
          podcast['doc_progress'] = 0
        } else {
          if (podcast['audio_editor']) {
            if (podcast['audio_editor_progress'] < 100) {
              podcast['doc_progress'] = podcast['audio_editor_progress']
            } else {
              podcast['doc_progress'] = 100
            }
          } else {
            if (podcast['progress'] < 1) {
              podcast['doc_progress'] = (podcast['progress'] * 100).toFixed(2)
            } else {
              podcast['doc_progress'] = 100
            }
          }
        }
        // Queued
        if (rawPodcast.status.code === 200) {
          podcast['doc_progress'] = 1
        }

        list.push(podcast)
      })

      // Needed to keep recent files on top
      list.sort(function (first, second) {
        return Date.parse(second['updated']) - Date.parse(first['updated'])
      })

      // list.sort(function (first, second) {
      //   return Date.parse(second['created']) - Date.parse(first['created'])
      // })
      return list
    }
  }
}

</script>

<style lang="scss">

.vtoast {
  .toast-container {
    position: absolute;
    display: flex;
    justify-content: center;

    div {
      width: unset !important;
      max-width: unset !important;
    }

    &:hover>div {
      box-shadow: 0 0 12px #999;
    }

    .toast {
      padding: 5px 12px 5px 8px !important;
      background-image: none !important;

      &:not(:last-child) {
        display: none !important;
      }
    }
  }

  .toast-bottom-center {
    bottom: 48px !important;
  }

  .toast-message {
    white-space: nowrap;
  }
}
</style>
