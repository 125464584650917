// adding music we just add this note class
import uuidv1 from 'uuid/v1'

export const note = {
  attrs: {
    class: { default: 'note' },
    note_uid: { default: uuidv1() }

  },
  parseDOM: [
    {
      tag: 'span.note[data-note_uid]',
      getAttrs (dom) {
        return {
          note_uid: dom.dataset.note_uid
        }
      }
    }
  ],
  toDOM: function (mark) {
    return ['span', {
      class: 'note',
      'data-note_uid': mark.attrs.note_uid ? mark.attrs.note_uid : 'asasd'
    }]
  }
}

// import { createNoteMark } from 'prosemirror-noting'
//
// export const note = createNoteMark(
//   {
//     note: 'span.note'
//   },
//   meta => ({
//     class: meta.hidden ? 'note--collapsed' : '',
//     title: '',
//     contenteditable: !meta.hidden
//   })
// )

// import uuidv1 from "uuid/v1";
