<template>
  <div
    :class="containerClass"
  >
    <span
      :class="computedClass"
      :disabled="disabled"
    />
    <label class="flex label items-center" :for="name">
      <div :class="indicatorClass" />
      <span v-show="!!label" class="ml-2 text-primary-900 block">{{label}}</span>
    </label>
  </div>
</template>

<script>
import { radioTheme as theme } from '../theme'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classname: {
      type: String,
      default: ''
    }
  },

  computed: {
    containerClass() {
      return ['relative flex items-center cursor-pointer', this.classname]
    },
    computedClass() {
      return [
        'radio',
        theme.base,
        this.invalid ? theme.invalid : theme.normal,
        this.disabled ? theme.disabled : ''
      ]
    },
    indicatorClass() {
      return [
        this.checked ? 'checked indicator' : 'indicator',
        theme.indicator
      ]
    }
  }
}
</script>

<style scoped>
.radio {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  opacity: 1e-5;
  width: 100%;
  height: 100%;
}

.indicator {
  position: relative;
  height: 18px;
  width: 18px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator::after {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 100%;
}

.checked.indicator::after {
  background-color: #000228;
}

</style>
