import { MarkSpec, NodeSpec, AttributeSpec, MarkType, Mark, Node } from 'prosemirror-model'
import { Selection, TextSelection, Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { v1 as uuidv1 } from 'uuid'
import { PillEvents } from './events'

export default class Pills {
  private schema: NodeSpec = {}
  private mark: MarkSpec = {}
  private hoverMark: MarkSpec = {}

  // keeping record of pill elements in order to avoid multiple dom lookups
  public PillEvents: Record<string, PillEvents> = {}
  public isHovered: boolean
  public lastHoveredData: {
    from: number,
    to: number,
    markType: MarkType
  } | null = null
  public pillNodeSize: number = 0
  public type: string
  private isHoverable: boolean

  constructor(type: string, isHoverable = false) {
    this.type = type
    this.isHoverable = isHoverable
    const data: Omit<NodeSpec, 'attrs'> = {
      selectable: true
    }
    const extraAttrs: Record<string, AttributeSpec> = {
      class: { default: `${type}-pill` },
      type: { default: type },
      title: { default: type },
      id: { default: `${type}-${uuidv1()}` }
    }
    this.initPillSchema(data, extraAttrs)
    this.initPillMark(type)
    if (isHoverable) {
      this.initHoverMark(`${type}Hover`)
    }
    this.isHovered = false
  }

  public metafyTransaction(tr: Transaction) {
    return tr.setMeta('clipper', true)
  }

  // dispatches the generated transaction and creates event listener class
  public dispatchPillsAndEvents(
    view: EditorView,
    uid: string,
    nodeSize: number,
    transaction: Transaction
  ): string {
    view.dispatch(this.metafyTransaction(transaction))
    this.pillNodeSize = nodeSize
    this.PillEvents[uid] = new PillEvents(view, uid, this.type)
    this.PillEvents[uid].setupListeners()
    return uid
  }

  public deletePillsByUid(view: EditorView, uid: string) {
    this.PillEvents[uid]?.resetElements()
    const transaction = view.state.tr
    view.dispatch(transaction.setSelection(new TextSelection(transaction.doc.resolve(0))))
    const spLoc = this.PillEvents[uid].pillElements.startPill?.getBoundingClientRect()
    const epLoc = this.PillEvents[uid].pillElements.endPill?.getBoundingClientRect()
    if (!spLoc || !epLoc) return
    let spPosAtCoords = view.posAtCoords({ left: spLoc.left, top: spLoc.top })
    let epPosAtCoords = view.posAtCoords({ left: epLoc.left, top: epLoc.top })
    let spPos = spPosAtCoords?.inside || spPosAtCoords?.pos
    let epPos = epPosAtCoords?.inside || epPosAtCoords?.pos
    console.log('these are the positions', { spPosAtCoords, epPosAtCoords, epLoc, spLoc })
    if (!spPos || !epPos) return
    let sp = view.state.doc.nodeAt(spPos)
    let ep = view.state.doc.nodeAt(epPos)
    if (!sp || !ep) return
    if (sp.type.name !== this.type) {
      const spResolvedPos = view.state.doc.resolve(spPos)
      if (spResolvedPos.nodeAfter?.type.name === this.type) {
        sp = spResolvedPos.nodeAfter
        spPos = spResolvedPos.pos
      } else if (spResolvedPos.nodeBefore?.type.name === this.type) {
        sp = spResolvedPos.nodeBefore
        spPos = spResolvedPos.pos
      }
    }
    if (ep.type.name !== this.type) {
      const epResolvedPos = view.state.doc.resolve(epPos)
      if (epResolvedPos.nodeAfter?.type.name === this.type) {
        console.log(epResolvedPos.nodeAfter)
        ep = epResolvedPos.nodeAfter
        epPos = epResolvedPos.pos
      } else if (epResolvedPos.nodeBefore?.type.name === this.type) {
        ep = epResolvedPos.nodeBefore
        epPos = epResolvedPos.pos
      }
    }
    transaction.removeMark(spPos + sp.nodeSize, epPos, view.state.schema.marks[`${this.type}Highlight`])
    const newLoc = epPos - sp.nodeSize
    console.log(sp, ep)
    transaction.delete(spPos, spPos + sp.nodeSize)
    transaction.delete(newLoc, newLoc + ep.nodeSize)
    view.dispatch(this.metafyTransaction(transaction))
    delete this.PillEvents[uid]
  }

  public createPillPreviewHover(view: EditorView, from: number, to: number) {
    if (!this.isHoverable) return
    this.isHovered = true
    const transaction = view.state.tr
    const pillHoverHighlight = view.state.schema.mark(`${this.type}HoverHighlight`)
    // handles an edge case where the to position is saved to be greater than doc length by 1 unit.
    if (to > view.state.doc.content.size) to = view.state.doc.content.size
    transaction.addMark(from, to, pillHoverHighlight)
    this.lastHoveredData = {
      from, to, markType: pillHoverHighlight.type
    }
    view.dispatch(this.metafyTransaction(transaction))
  }

  public removePillPreviewHover(view: EditorView) {
    if (!this.isHoverable) return
    if (!this.lastHoveredData || !view) return
    this.isHovered = false
    const transaction = view.state.tr
    const { from, to, markType } = this.lastHoveredData
    // taking some extra grey area to avoid any extra conditions
    // this is probably not ideal and we should work upon making this precise
    transaction.removeMark(from - 5, to, markType)
    view.dispatch(this.metafyTransaction(transaction))
  }

  protected initPillSchema(data?: Omit<NodeSpec, 'attrs'>, extraAttrs?: Record<string, AttributeSpec>) {
    // Every part of this schema can be modified as per the use case
    this.schema = {
      content: 'text*',
      inline: true,
      attrs: {
        class: { default: 'pills' },
        type: { default: 'pill' },
        title: { default: 'Pill' },
        id: { default: `pill-${uuidv1()}` },
        ...extraAttrs
      },
      group: 'inline',
      atom: true, // it can't have directly editable content
      draggable: true,
      parseDOM: [
        {
          tag: 'text',
          getAttrs (aDom) { // dom should have these attrs for it to qualify as music node
            const dom = aDom as HTMLElement
            let ret = {
              class: dom.getAttribute('class'),
              title: dom.getAttribute('title'),
              id: dom.getAttribute('id')
            }
            return ret
          }
        }
      ],
      toDOM (node) {
        return [
          'span',
          {
            class: node.attrs.class,
            title: node.attrs.title,
            id: node.attrs.id
          }
        ]
      },
      ...data
    }
  }

  protected initPillMark(type: string, data?: Omit<MarkSpec, 'attrs'>, extraAttrs?: Record<string, AttributeSpec>) {
    // The highlight is usually not going to have any extensive customisation as its just a highlight
    this.mark = {
      attrs: {
        class: { default: `${type}-hl` },
        [`${type}hl_uid`]: { default: `${type}hl-${uuidv1()}` },
        ...extraAttrs
      },
      parseDOM: [
        {
          tag: `span.${type}-hl[data-${type}hl_uid]`,
          getAttrs (aDom) {
            const dom = aDom as HTMLElement
            return {
              [`${type}hl_uid`]: dom.dataset[`${type}hl_uid`]
            }
          }
        }
      ],
      toDOM: function (mark) {
        return ['span', {
          class: mark.attrs.class,
          [`data-${type}hl_uid`]: mark.attrs[`${type}hl_uid`]
        }]
      },
      ...data
    }
  }

  // initialises hover mark for a specific type of pill interface
  protected initHoverMark(type: string) {
    this.hoverMark = {
      attrs: {
        class: { default: `${type}Highlight` }
      },
      parseDOM: [{ tag: `span.${type}Highlight` }],
      toDOM: function (mark) {
        return ['span', mark.attrs]
      }
    }
  }

  public getNodeSchema() {
    return this.schema
  }

  public getMark() {
    return this.mark
  }

  public getHoverMark() {
    return this.hoverMark
  }
}
