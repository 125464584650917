import { Step, StepResult } from 'prosemirror-transform'
/** used for adding doc node attributes
* step is to create a transcation
* proseEditor.js uses this function
* -> master volume */

export class SetDocAttr extends Step {
  constructor (key, value, stepType = 'SetDocAttr') {
    super()
    this.stepType = stepType
    this.key = key
    this.value = value
  }
  apply (doc) {
    // console.log('doc.attrs', doc.attrs)
    this.prevValue = doc.attrs[this.key]
    // if (doc.attrs === doc.type.defaultAttrs) doc.attrs = Object.assign({}, doc.attrs)
    doc.attrs[this.key] = this.value
    return StepResult.ok(doc)
  }
  invert () {
    return new SetDocAttr(this.key, this.prevValue, 'revertSetDocAttr')
  }
  map () {
    return null
  }
  toJSON () {
    return {
      stepType: this.stepType,
      key: this.key,
      value: this.value
    }
  }
  static fromJSON (json) {
    return new SetDocAttr(json.key, json.value, json.stepType)
  }
}
Step.jsonID('SetDocAttr', SetDocAttr)
