import uuidv1 from 'uuid/v1'

export function createTextNode (view, text, uid, asource, astart, aend, mediaType, nodeClass) {
  return view.state.schema.text(text, [
    view.state.schema.mark('info', {
      uid: uuidv1(),
      asource: asource,
      astart: astart,
      aend: aend,
      mediaType: mediaType,
      class: nodeClass
    })
  ])
}
export function getInfoMarkFromTextNode (textNode) {
  let truth = false
  textNode.marks.forEach(mark => {
    if (mark.type.name === 'info') {
      truth = mark
    }
  })
  return truth
}

export function getNonInfoMarksFromTextNode (textNode) {
  let truth = []
  textNode.marks.forEach(mark => {
    if (mark.type.name !== 'info') {
      truth.push(mark)
    }
  })
  return truth
}
