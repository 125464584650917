import * as firebase from 'firebase/app'
import 'firebase/auth'
import Vue from 'vue'
import Router from 'vue-router'
import CONSTANTS from '@/constants/CONSTANTS'

const LandingPage = () => import(/* webpackChunkName: "LandingPage" */ '@/view/LandingPage/index.vue')
const ShutDownNotice = () => import(/* webpackChunkName: "ShutDownNotice" */ '@/view/ShutDownNotice/index.vue')
const BrandKit = () => import(/* webpackChunkName: "BrandKit" */ '@/view/BrandKit/index.vue')
const Drive = () => import(/* webpackChunkName: "drive" */ '../components/Drive/Drive')
const Security = () => import(/* webpackChunkName: "security" */ '../components/Security')
const Privacy = () => import(/* webpackChunkName: "Privacy" */ '../components/Privacy')
const Invite = () => import(/* webpackChunkName: "Invite" */ '../components/Invite')
const TeamInvite = () => import(/* webpackChunkName: "TeamInvite" */ '../components/TeamInvite')
const HomeProse = () => import(/* webpackChunkName: "HomeProse" */ '../view/voiceEditor/vue/components/HomeProse')
const Play = () => import(/* webpackChunkName: "HomeProse" */ '../view/voiceEditor/vue/components/Play')
const ClipEditor = () => import(/* webpackChunkName: "ClipEditor" */ '@/view/Workflows/ClipEditor.vue')
const Audiogram = () => import(/* webpackChunkName: "ClipEditor" */ '@/view/Workflows/Audiogram.vue')
const SpextPlayer = () => import(/* webpackChunkName: "SpextPlayer" */ '@/view/SpextPlayer/index.vue')
const Onboarding = () => import(/* webpackChunkName: "Onboarding" */ '@/view/Onboarding/index.vue')
Vue.use(Router)

const router = new Router({
  // Enabling this will not allow email sign ups
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/audio/:userId/:podId',
      name: 'HomeProse',
      component: HomeProse,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/player/:userId/:fileId',
      name: 'SpextPlayer',
      component: SpextPlayer,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:userId/projects/:projectId',
      name: 'project',
      component: ClipEditor,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:userId/audiogram/:projectId',
      name: 'audiogram',
      component: Audiogram,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/invite/:adminid',
      name: 'Invite',
      component: Invite,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/teaminvite',
      name: 'TeamInvite',
      component: TeamInvite,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shutdown-notice',
      name: 'shutdownnotice',
      component: ShutDownNotice,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/drive',
      name: 'drive',
      component: Drive,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/brandkit',
      name: 'brandkit',
      component: BrandKit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/',
      name: 'index',
      component: LandingPage
    },
    {
      path: '/security',
      name: 'security',
      component: Security
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '/play',
      name: 'play',
      component: Play,
      meta: {
        requiresAuth: true
      }
    }
  ]
})
router.beforeEach(async (to, from, next) => {
  // if page exists
  if (to.query.ref) {
    localStorage['ref'] = to.query.ref
  }
  if (to.query.coupon) {
    localStorage['coupon'] = to.query.coupon
  }
  if (to.name) {
    if (to.name === 'Invite' && to.params.adminid) {
      localStorage['invite'] = to.params.adminid
    }
    if (to.name === 'TeamInvite' && to.query.adminid && to.query.invitedEmail) {
      localStorage['teamInvite'] = to.query.adminid
      localStorage['invitedEmail'] = to.query.invitedEmail
    }
    $('.modals').remove()
    sessionStorage['lastVisited'] = to.name
  }

  let currentUser = firebase.auth().currentUser || to.query.token
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) {
    if (['HomeProse', 'SpextPlayer'].includes(to.name)) {
      console.log('signing in anonymously')
      try {
        await firebase.auth().signInAnonymously()
        currentUser = firebase.auth().currentUser
        console.log('log: currentUser', currentUser)
      } catch (error) {
        console.log('err', error)
      }
      next()
    } else {
      next({
        name: 'index'
      })
    }
  } else if (!requiresAuth && currentUser) {
    console.log('isAnonymous', currentUser.isAnonymous)
    if (currentUser.isAnonymous) {
      await firebase.auth().signOut()
      if (to.query.redirect) {
        next({
          name: 'index',
          query: {
            redirect: to.query.redirect
          }
        })
      } else {
        next({
          name: 'index'
        })
      }
    } else if (!currentUser.isAnonymous && currentUser.displayName) {
      next({
        name: 'drive'
      })
    }
  } else {
    if (requiresAuth && (!currentUser || !currentUser.displayName) && to.path !== `/audio/${CONSTANTS.demoUserid}/${CONSTANTS.demoMediaid}`) {
      if (!currentUser.isAnonymous) {
        next({
          name: 'index'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
})
export default router
