import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
export function checkResource (resource, type) {
  return new Promise((resolve, reject) => {
    var video = document.createElement('video')
    video.preload = 'metadata'
    video.onloadedmetadata = function () {
    //   console.log(this.src, video.duration)
      resolve('Ok')
    }
    video.onerror = function () {
      window.URL.revokeObjectURL(this.src)
      //   console.log('err:' + this.src)
      reject(new Error('not a video'))
    }
    if (type === 'file') {
    //   console.log('file duration check')
      video.src = URL.createObjectURL(resource)
    } else {
    //   console.log('url duration check')
      video.src = resource
    }
  })
}

export function writeToDBAboutMedia (uid, title, link, resourceId, type) {
  if (type === 'file') {
    return firebase.database().ref(uid + '/uploaded_media/' + resourceId).set({
      'url': link,
      'title': title
    })
  } else {
    // enter link, firebase function downloads it
    return firebase.database().ref(uid + '/uploaded_media/' + resourceId).set({
      'uploaded_link': link,
      'title': title
    })
  }
}

export function uploadResourceToDB (uid, file, resourceId) {
  let storageRef = firebase.storage().ref('podcast_audio/' + uid + '/uploaded_media/' + resourceId)
  var metadata = {
    'contentType': file.type
  }
  return storageRef.put(file, metadata)
}

export function uploadImagePublish (uid, channel, file) {
  console.log('channel', channel)
  console.log('file', file)
  let storageRef = firebase.storage().ref('published/' + uid + '/channels/' + channel + '/profileImage')
  var metadata = {
    'contentType': file.type
  }
  return storageRef.put(file, metadata)
}

export function getURLfromPath (path) {
  var httpsReference = firebase.storage().ref(path)
  return httpsReference.getDownloadURL().then(function (newUrl) {
    return newUrl
  }).catch(function (err) {
    console.log('Storage Error:', path, err)
  })
}
