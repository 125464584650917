// italic marks
export const em = {
  parseDOM: [{
    tag: 'i'
  }, {
    tag: 'em'
  }, {
    style: 'font-style=italic'
  }],
  toDOM () {
    return ['em', 0]
  }
}
