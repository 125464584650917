import { isSilenceSymbol } from '@/view/voiceEditor/proseEditor/CONSTANTS'
import {
  focusCursorOnLocation,
  ifMusicSymbol,
  isMarkDeleted,
  myProseEditor,
  toggleAddButton
} from '@/view/voiceEditor/proseEditor/util/utility'
import { LocalTextSearchResultItem } from '@/models/Search/LocalTextSearchResultItem'
// export let myProseEditor = null
// export function initProsEditor(proseEditorInstance) {
//   myProseEditor = proseEditorInstance
// }

export function getNoteIndex(marks) {
  let finalIndex = -1
  let index = -1
  marks.forEach(mark => {
    index++
    if (mark.type.name === 'note') {
      finalIndex = index
    }
  })

  return finalIndex
}
export function getNextWords (location, noOfWords = 4, skipDeleted = true) {
  let view = myProseEditor.view
  let result = ''
  let counter = 0
  let nextNode = view.state.doc.resolve(location).nodeAfter
  let nextLocation = location
  while (nextNode !== null && counter <= noOfWords) {
    if (nextNode.isText && !isSilenceSymbol(nextNode.text) && (skipDeleted ? !isMarkDeleted(nextNode.marks) : true) && !ifMusicSymbol(nextNode.marks)) {
      result += nextNode.text
      counter++
    }
    nextLocation = nextLocation + nextNode.nodeSize
    nextNode = view.state.doc.resolve(nextLocation).nodeAfter
  }
  return { text: result, endLocation: nextLocation }
}
export function getPreviousFewWords (location, noOfWords = 4, skipDeleted = true) {
  let view = myProseEditor.view
  let result = ''
  let counter = 0
  let previousNode = view.state.doc.resolve(location).nodeBefore
  let previousLocation = location
  while (previousNode !== null && counter <= noOfWords) {
    if (previousNode.isText && !isSilenceSymbol(previousNode.text) && (skipDeleted ? !isMarkDeleted(previousNode.marks) : true) && !ifMusicSymbol(previousNode.marks)) {
      result = previousNode.text + result
      counter++
    }
    previousLocation = previousLocation - previousNode.nodeSize
    previousNode = view.state.doc.resolve(previousLocation).nodeBefore
  }
  return result
}
export function searchInDoc (searchStrings = []) {
  let view = myProseEditor.view
  let sizeTravelled = 0
  let totalTimePassed = 0
  let anchorLocation = 0
  let results = []
  view.state.doc.content.forEach(paraNode => {
    anchorLocation = sizeTravelled
    sizeTravelled += 2
    paraNode.content.forEach(textNode => {
      anchorLocation = sizeTravelled
      sizeTravelled += textNode.nodeSize
      let resultItem = LocalTextSearchResultItem('', '',
        false, false, {
          start: anchorLocation - 1,
          end: sizeTravelled - 1
        })
      textNode.marks.forEach(mark => {
        if (mark.type.name === 'deleted') {
          resultItem.isDeleted = true
        }
        if (mark.type.name === 'restored') {
          resultItem.isRestored = true
        }
        if (!resultItem.isDeleted && mark.attrs != null && mark.attrs.uid != null) {
          if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
            let duration = mark.attrs.aend - mark.attrs.astart
            // if (mark.attrs.uid.split('-')[0] === 'silence') {
            //   duration = 0.1
            // }
            totalTimePassed += duration
          }
        }
        resultItem.atTime = totalTimePassed
      })
      searchStrings.forEach(searchWords => {
        let searchWord = searchWords.trim().toLowerCase().replace(',', '').replace('.', '').replace('!', '').replace('?', '')
        let tempResult = getNextWords(sizeTravelled - 1, searchWord.split(' ').length - 2, false)
        let tempResultString = (textNode.text + tempResult.text).trim().toLowerCase().replace(',', '').replace('.', '').replace('!', '').replace('?', '')
        if (tempResultString === searchWord) {
          resultItem.searchWord = searchWords
          resultItem.resultString = searchWords + ' ' + getNextWords(sizeTravelled - 1).text.replace(searchWords.replace(searchWords.split(' ')[0] + ' ', '') + ' ', '')
          resultItem.location.end = tempResult.endLocation
          results.push(resultItem)
        }
      })
    })
  })

  results.sort(function(a, b) {
    return a.location.start - b.location.start
  })
  return results
}
export function multipleSearchInDoc (searchStringArray) {
  let results = []
  if (searchStringArray) {
    results = searchInDoc(searchStringArray)
    // searchStringArray.forEach(word => {
    //   searchInDoc(word).forEach(result => {
    //     results.push(result)
    //   })
    // })
  }
  results.sort(function(a, b) {
    return a.location.start - b.location.start
  })
  return results
}
export function searchHighlightInDoc(searchString) {
  let results = searchInDoc([searchString])
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr
  results.forEach(resultItem => {
    let location = resultItem.location
    transaction.setMeta('addToHistory', false).addMark(location.start, location.end, state.schema.marks.searchHighlight.create())
  })
  view.dispatch(
    transaction
  )
  results.sort(function(a, b) {
    return a.location.start - b.location.start
  })
  return results
}
export function searchHighlightInDocWithResults(searchString, results) {
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr
  document._searchSelection = []
  results.forEach(resultItem => {
    let location = resultItem.location
    // document._searchSelection.push({
    //   from: location.start,
    //   to: location.end
    // })
    transaction.setMeta('addToHistory', false).addMark(location.start, location.end, state.schema.marks.searchHighlight.create())
  })
  view.dispatch(
    transaction
  )
  results.sort(function(a, b) {
    return a.location.start - b.location.start
  })
  return results
}

export function multipleSearchHighlightInDoc(searchStringArray) {
  let results = multipleSearchInDoc(searchStringArray)
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr
  results.forEach(resultItem => {
    let location = resultItem.location
    transaction.setMeta('addToHistory', false).addMark(location.start, location.end, state.schema.marks.searchHighlight.create())
  })
  view.dispatch(
    transaction
  )
  results.sort(function(a, b) {
    return a.location.start - b.location.start
  })
  return results
}

export function removeSearchHighlightsInDoc(searchString) {
  let results = searchInDoc([searchString])
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr
  results.forEach(resultItem => {
    let location = resultItem.location
    transaction.setMeta('addToHistory', false).removeMark(location.start, location.end, view.state.schema.marks['searchHighlight'])
  })
  view.dispatch(
    transaction
  )
}
export function removeMultipleSearchHighlightsInDoc(searchStringArray) {
  let results = multipleSearchInDoc(searchStringArray)
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr
  results.forEach(resultItem => {
    let location = resultItem.location
    transaction.setMeta('addToHistory', false).removeMark(location.start, location.end, view.state.schema.marks['searchHighlight'])
  })
  view.dispatch(
    transaction
  )
}
export function deleteWordAtLocation(location) {
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  view.dispatch(
    state.tr.setMeta('addToHistory', true).addMark(location.start, location.end, state.schema.marks.deleted.create())
  )
}
export function restoreWordAtLocation(location) {
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  view.dispatch(
    state.tr.setMeta('addToHistory', true).removeMark(location.start, location.end, view.state.schema.marks['deleted'])
  )
}
export function deleteWordAtLocations(locations) {
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr
  locations.forEach(location => {
    transaction.setMeta('addToHistory', true).addMark(location.start, location.end, state.schema.marks.deleted.create())
  })
  view.dispatch(
    transaction
  )
}
export function restoreWordAtLocations(locations) {
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr
  locations.forEach(location => {
    transaction.setMeta('addToHistory', true).removeMark(location.start, location.end, view.state.schema.marks['deleted'])
  })
  view.dispatch(
    transaction
  )
}
export function replaceWordAtLocation(location, toWord) {
  console.log(location, toWord)
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr

  transaction.insertText(toWord + ' ', location.start, location.end)

  view.dispatch(
    transaction
  )
}
export function replaceWordAtLocations(locations, toWord) {
  let view = myProseEditor.view
  let state = myProseEditor.view.state
  let transaction = state.tr
  locations.forEach(location => {
    transaction.insertText(toWord + ' ', location.start, location.end)
  })
  view.dispatch(
    transaction
  )
}
export function replaceAll(fromWord, toWord) {
  let view = myProseEditor.view
  let result = searchInDoc([fromWord])[0]
  while (result !== undefined) {
    view.dispatch(
      myProseEditor.view.state.tr.insertText(toWord + ' ', result.location.start, result.location.end)
    )
    result = searchInDoc([fromWord])[0]
  }
}
export function removeAllSearchHighlights () {
  document._searchSelection = []
  removeAllMarks('searchHighlight')
}
export function removeAllSelections() {
  toggleAddButton(false)
  removeAllMarks('customTextSelect')
  removeAllMarks('searchSelection')
  removeAllMarks('playbackSelection')
}
export function removeAllMarks (markName = 'customTextSelect') {
  let view = myProseEditor.view
  let sizeTravelled = 0
  let anchorLocation = 0

  let orphanMtextStartLoc = -1
  let orphanMtextEndLoc = -1
  let transaction = view.state.tr
  view.state.doc.content.forEach(paraNode => {
    anchorLocation = sizeTravelled
    sizeTravelled += 2
    paraNode.content.forEach(textNode => {
      anchorLocation = sizeTravelled
      sizeTravelled += textNode.nodeSize
      let token = false
      textNode.marks.every(mark => {
        if (mark.type.name === markName) {
          token = true
          orphanMtextStartLoc = anchorLocation - 1
          orphanMtextEndLoc = sizeTravelled - 1
        }
        return true
      })
      if (token) {
        if (orphanMtextStartLoc > -1 && orphanMtextEndLoc > -1) {
          // console.log(orphanMtextStartLoc, orphanMtextEndLoc)
          // we don't allow undo on this deletion - addToHistory
          transaction.setMeta('addToHistory', false).removeMark(orphanMtextStartLoc - 1, orphanMtextEndLoc, view.state.schema.marks[markName])

          orphanMtextStartLoc = -1
          orphanMtextEndLoc = -1
        }
      }
    })
  })
  view.dispatch(
    transaction
  )
}

export function addCustomHighlight(startPos, endPos, className, markName = 'customTextSelect') {
  let view = myProseEditor.view
  try {
    // let end = doc.resolve(view.state.selection.head + next);
    let customMark = view.state.schema.mark(markName, {
      class: className
    })
    view.dispatch(
      view.state.tr.setMeta('addToHistory', false).addMark(startPos, endPos, customMark)
    )
  } catch (e) {
    console.log('caught err', e)
  }
}
export function removeCustomHighlight(startPos, endPos, markName = 'customTextSelect') {
  let view = myProseEditor.view
  try {
    view.dispatch(
      view.state.tr.setMeta('addToHistory', false).removeMark(startPos, endPos, view.state.schema.marks[markName])
    )
  } catch (e) {
    console.log('caught err', e)
  }
}
