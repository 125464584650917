<template lang="html">
  <div v-if="open" class="inset-0 z-50 fixed overflow-y-auto">
    <div class="min-h-screen max-h-screen text-center p-0 px-4 pt-4 pb-20 block items-end justify-center">
      <div class="bg-primary-dark opacity-80 inset-0 transition-opacity fixed" @click="close" />
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="bg-white rounded-lg text-left transform transition-all relative inline-block align-middle">
        <div @click="close" class="cursor-pointer top-4 right-4 text-primary-500 absolute hover:text-primary-700" >
          <CloseIcon class="h-5 w-5" />
        </div>

        <div class="flex flex-col p-4 root">
          <div class="font-h font-semibold text-primary text-3xl">
            Notifications
          </div>

          <div class="border rounded border-primary-300 mt-4 pr-2.5 notifications">
            <div class="h-full overflow-y-auto">
              <div
                v-for="podcast in searchableList"
                :key="podcast.key"
                class="border-b border-primary-200 mr-4 ml-2 py-4 px-2 last:border-transparent"
              >
                <!-- type podcast -->
                <div v-if="podcast.type==='podcast'" class="flex items-center justify-between">
                  <div class="flex relative">
                    <div
                      class="rounded-full h-2 mr-2.5 top-1.5 w-2 relative"
                      :class="[podcast.code in errorCode ? 'bg-red' : 'bg-green']"
                    />

                    <div>
                      <div class="font-semibold text-sm text-primary">{{podcast.title}}</div>
                      <div class="mt-0.5 text-sm text-primary-600">
                        Uploaded {{humanTime(podcast.created)}}
                      </div>
                    </div>
                  </div>

                  <div class="text-sm text-primary-600">{{humanLength(podcast.length)}}</div>
                </div>

                <!-- type upload -->
                <div v-if="podcast.type==='upload'" class="flex items-center justify-between">
                  <div class="flex relative">
                    <div
                      class="bg-green rounded-full h-2 mr-2.5 top-1.5 w-2 relative"
                    />

                    <div>
                      <div class="font-semibold text-sm text-primary">{{podcast.title}}</div>
                      <div class="mt-0.5 text-sm text-primary-600">
                        Updated {{humanTime(podcast.updated)}}
                      </div>
                    </div>
                  </div>

                  <div class="text-sm text-primary-600">
                    {{((podcast.task.snapshot.bytesTransferred/podcast.task.snapshot.totalBytes)*100).toFixed(2)}}%
                  </div>
                </div>

                <!-- type export -->
                <div v-if="podcast.type==='export'" class="flex items-center justify-between">
                  <div class="flex relative">
                    <div
                      class="rounded-full h-2 mr-2.5 top-1.5 w-2 relative"
                      :class="[podcast.status==='error' ? 'bg-red' : 'bg-green']"
                    />

                    <div>
                      <div class="font-semibold text-sm text-primary">
                        [Export] {{podcast.title}}
                      </div>
                      <div class="mt-0.5 text-sm text-primary-600">
                        <div v-if="podcast.status==='start'" class="loadingDots">
                          Processing {{podcast.exportType}} {{timeInPercentage15(podcast.updated)}}%
                        </div>
                        <div v-else-if="podcast.status==='done'">
                          {{podcast.exportType}} file is ready to download.
                        </div>
                        <div v-else>
                          Error while exporting. <a class="text-red hover:text-red hover:underline" onclick="window.Intercom('showNewMessage', 'I am having trouble in exporting.')">Contact Us</a> for support.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- type publish -->
                <div v-if="podcast.type==='publish'" class="flex items-center justify-between">
                  <div class="flex relative">
                    <div
                      class="rounded-full h-2 mr-2.5 top-1.5 w-2 relative"
                      :class="[podcast.data.status==='error' ? 'bg-red' : 'bg-green']"
                    />

                    <div>
                      <div class="font-semibold text-sm text-primary">
                        [Publish] {{podcast.data.title}}
                      </div>
                      <div class="mt-0.5 text-sm text-primary-600">
                        <div v-if="podcast.data.status==='start'">
                          Processing Episode
                        </div>
                        <div v-if="podcast.data.status==='start'">
                          Processing Episode
                        </div>
                        <div v-else-if="podcast.data.status==='done'">
                          Episode is ready. <a class="text-blue hover:text-blue hover:underline" @click="openLink(podcast.data.link)">Check</a>
                        </div>
                        <div v-else>
                          Error while publishing. <a class="text-red hover:text-red hover:underline" onclick="window.Intercom('showNewMessage', 'I am having trouble in exporting.')">Contact Us</a> for support.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

          <div class="flex mt-6 text-xs mb-2 items-center justify-center">
            <div class="bg-green rounded-full h-2 mr-1.5 w-2" />
            <div>New</div>

            <div class="bg-red rounded-full h-2 mr-1.5 ml-4 w-2" />
            <div>Error</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import CONSTANTS from '@/constants/CONSTANTS'
import * as firebase from 'firebase/app'
import 'firebase/storage'
import store from '@/services/store'
import {
  humanLength
} from '@/utilities/utility'

import CloseIcon from '@/components/base/icons/Close.vue'

export default {
  name: 'Queue',
  props: {
    computedList: {
      type: Array,
      default: function () {
        return []
      }
    },
    negativeSeconds: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  components: {
    CloseIcon
  },
  computed: {
    ...mapGetters({
      open: 'modal/showNotifications'
    }),
    uploadingList () {
      return store.state.app.uploadingList
    },
    searchableList: function () {
      var vm = this
      var list = []
      var inProgress = 0
      vm.computedList.forEach(function (rawPodcast) {
        rawPodcast['type'] = 'podcast'
        list.push(rawPodcast)
        if ((rawPodcast['code'] < 205 || rawPodcast['audio_editor'] === 'processing') && ((new Date() - new Date(rawPodcast.updated)) < 36000000)) {
          inProgress += 1
        }
        // insert publish
        if (rawPodcast['publish']) {
          var pub = {}
          pub['type'] = 'publish'
          pub['data'] = rawPodcast['publish']
          list.push(pub)
        }
        for (var j in rawPodcast.exportedList) {
          // console.loog('export',j)

          if (typeof (rawPodcast.exportedList[j]) === 'object') {
            // file.url = rawPodcast.exportedList[j]
            // file.status = 'done'
            // file['updated']=new Date()
            // }else{
            var file = {}

            file = JSON.parse(JSON.stringify(rawPodcast.exportedList[j]))
            file['key'] = j + ' ' + rawPodcast.title
            file['title'] = rawPodcast.title
            file['type'] = 'export'
            file['exportType'] = j.toUpperCase()
            file['updated'] = new Date(rawPodcast.exportedList[j].timestamp + 'Z')
            if (file['status'] !== 'start' && (!localStorage['lastSeen'] || new Date(localStorage['lastSeen']) < new Date(file['updated']))) {
              vm.$parent.newPodcastAvailable(rawPodcast.exportedList[j].timestamp + 'Z')
            } else if (file['status'] === 'start' && file['updated'] && ((new Date() - new Date(file['updated'])) < 36000000)) {
              inProgress += 1
            }
            list.push(file)
          }
        }
      })
      for (var i in vm.uploadingList) {
        var podcast = vm.uploadingList[i]
        podcast['key'] = i
        podcast['type'] = 'upload'
        podcast['updated'] = new Date()
        if (podcast.task.state_ === 'running') {
          inProgress += 1
        }
        list.push(podcast)
      }
      store.commit('app/setInProgress', inProgress)
      list.sort(function (first, second) {
        return Date.parse(second['updated']) - Date.parse(first['updated'])
      })
      const uniqueKeyList = []
      list.forEach(item => {
        if (uniqueKeyList.findIndex(x => x.key === item.key) <= -1) uniqueKeyList.push(item)
      })
      return uniqueKeyList
    }
  },
  data () {
    return {
      now: Date.now(),
      errorCode: CONSTANTS.ERROR_CODES
    }
  },
  mounted () {
    var vm = this
    window.setInterval(() => {
      if (!vm.$parent.offline) {
        this.now = Date.now()
      }
    }, 2000)
    window.addEventListener('keyup', function(e) {
      if (e.key === 'Escape' && vm.open) {
        vm.close()
      }
    })
  },
  methods: {
    ...mapActions({
      close: 'modal/hideQueueModal',
      removeNewPodcast: 'app/removeNewPodcast'
    }),
    timeInPercentage (time) {
      var vm = this
      var timeElapsed = vm.$parent.now - Date.parse(time)
      var percentage = (timeElapsed / (30 * 60 * 1000)) * 100
      if (percentage < 0) {
        return 0
      } else if (percentage > 100) {
        return 100
      }
      return percentage.toFixed(2)
    },
    timeInPercentage15 (time) {
      var vm = this
      var timeElapsed = vm.$parent.now - Date.parse(time)
      var percentage = (timeElapsed / (15 * 60 * 1000)) * 100
      if (percentage < 0) {
        return 0
      } else if (percentage > 100) {
        return 100
      }
      if (Number(percentage)) return percentage.toFixed(2)
      return 0
    },
    openLink (link) {
      window.open(link)
    },
    humanLength (timeInSec) {
      return humanLength(timeInSec)
    },
    humanTime (time) {
      /* convert in localtime */
      time = moment(time).format()
      if (moment().startOf('day').format() < time) {
        /* time in minutes '2:42 pm' */
        return moment(time).fromNow()
      } else if (moment().startOf('week').format() < time) {
        /* time in day name 'Thu' */
        return moment(time).fromNow()
      } else if (moment().startOf('year').format() < time) {
        /* time in date Feb 17 */
        return moment(time).fromNow()
      }
      return moment(time).format('L')
    }
  },
  watch: {
    open: function(val, oldVal) {
      if (val && val !== oldVal) this.removeNewPodcast()
    }
  }
}
</script>

<style lang="css" scoped>
.root {
  width: 780px;
  height: 450px;
}

.notifications {
  height: calc(100% - 100px);
}
</style>
