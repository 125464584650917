export const strong = {
  // when we copy -> if we encounter following tags we conside it strong mark
  // different browsers show diff marks diffrently
  // copying from other sources such as medium etc, this is useful
  // what html element should be considered as a logical entitty inside prosemirror
  parseDOM: [{
    tag: 'strong'
  },
  {
    tag: 'b',
    getAttrs: node => node.style.fontWeight !== 'normal' && null
  },
  {
    style: 'font-weight',
    getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null
  }
  ],
  toDOM () {
    return ['strong', 0]
  }
}
