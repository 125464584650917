<template lang="html">
  <div>
  <Modal
    v-show="modalName === 'AccountInfo'"
    :closePosition="currentTab.startsWith('Cancel') ? 'hidden' : 'top-6 right-3.5'"
    :disableCloseOnClickAway="true"
  >
    <div>
      <div v-show="!currentTab.startsWith('Cancel')" class="relative flex flex-col p-4 root">
        <div v-if="status in errorCode" class="absolute left-0 w-full px-4 py-3 rounded-lg bg-red -top-14">
          <div class="text-sm text-white" v-html="errorCode[status]"/>
        </div>
        <div v-if="status in successCode" class="absolute left-0 w-full px-4 py-3 rounded-lg bg-green -top-14">
          <div class="text-sm text-white" v-html="successCode[status]"/>
        </div>

        <div class="text-3xl font-semibold font-h text-primary">
          Account Details
        </div>

        <div class="flex-grow mt-4 border rounded border-primary-300">
          <div class="flex items-center w-full border-b border-primary-300">
            <div
              v-for="key in Object.keys(tabmap)"
              :key="key"
              class="flex flex-col items-center justify-center w-full text-sm leading-4 border-b-4 border-transparent cursor-pointer h-11 mr-9 last:mr-0"
              :class="{'font-semibold border-red text-primary': currentTab === key, 'text-primary-700': currentTab !== key}"
              @click="currentTab = key;"
            >
              <div>{{tabmap[key]}}</div>
              <div v-if="key === 'Top Up'">(Top Ups)</div>
            </div>
          </div>

          <div v-if="currentTab === 'Profile'" class="flex flex-col items-center justify-center pt-16">
            <div class="flex items-center justify-center">
              <div class="flex items-center">
                <div class="border-red rounded-full flex border-4 h-16 mr-2.5 w-16 items-center justify-center">
                  <Avatar :size="56" :src="user.photoURL || ''" :username="user.displayName || ''"/>
                </div>
                <div>
                  <div class="text-primary">{{user.displayName}}</div>
                  <div class="mt-1 text-xs text-primary-700">{{user.email}}</div>
                </div>
              </div>

              <div class="h-16 border-l border-primar-300 mx-9"></div>

              <div class="flex items-center">
                <div v-if="billing.trial" class="content">
                  <div class="text-xs text-primary-700">Trials Left</div>
                  <div class="mt-0.75 text-primary">{{Math.floor(billing.seconds/900)}}</div>
                </div>
                <div v-else class="content">
                  <div class="text-xs text-primary-700">Balance</div>
                  <div class="mt-0.75 text-primary">{{balanceHuman}}</div>
                </div>
              </div>

              <div class="h-16 border-l border-primar-300 mx-9"></div>

              <div>
                <div class="text-xs text-primary-700">Current Plan</div>
                <div class="mt-0.75 text-primary">{{planName}}</div>
              </div>

              <div v-show="!needsSubscription" class="h-16 border-l border-primar-300 mx-9"></div>

              <div v-show="!needsSubscription">
                <div class="text-xs text-primary-700">Next Billing</div>
                <div class="mt-0.75 text-primary">{{subscriptions.current_period_end}}</div>
              </div>
            </div>

            <Button
              variant="secondary"
              v-show="!needsSubscription"
              @click="setTab('Cancel1')"
              class="mt-32"
            >
              Cancel Subscription
            </Button>
          </div>

          <Plans v-if="currentTab === 'Plans'" @addPlanToCart="addPlanToCart" />

          <div v-if="currentTab=='Top Up'" class="flex flex-col items-center justify-center">
            <div v-if="needsSubscription" class="mt-6 mb-8 text-sm text-center">
              Please subscribe to a plan to activate bulk discounts.
            </div>
            <div v-else class="h-5 mt-6 mb-8"/>

            <div class="flex items-center">
              <div
                v-for="(topup,index) in topups"
                :key="index"
                class="w-40 mr-4 overflow-hidden border rounded border-primary-300 hover:border-red last:mr-0"
              >

                <div class="flex flex-col justify-center p-3 bg-primary-100">
                  <div class="text-base font-semibold text-center">{{topup.hours}}</div>
                  <div class="mt-0.5 text-xs text-center">${{topup.cost}}</div>
                </div>

                <div class="mt-2 text-xs text-center list-inside">
                  {{topup.rate}}
                </div>

                <div class="flex justify-center mt-4 mb-6">
                  <Button v-if="needsSubscription" variant="secondary" @click='subscribeFirst'>GET</Button>
                  <Button v-else variant="secondary" @click='addTopupToCart(topup)'>GET</Button>
                </div>
              </div>
            </div>

            <div class="mt-8 text-sm text-center">
              All unused minutes roll over to the next month. <a class="cursor-pointer text-blue hover:text-blue hover:underline" onclick="window.Intercom('showNewMessage', '')">Need more hours?</a>
            </div>
          </div>

          <div v-show="currentTab=='Billing'" class="flex items-center justify-around pt-4">
            <!-- Card info -->
            <div class="mt-8">
              <StripeForm
                v-show='sources.length==0'
                @sourceAdded="sourceAdded"
                @sourceError="sourceError"
                :customerId="customerId"
              />
              <!-- mock card -->
              <div v-show='sources.length > 0' class="m-auto border rounded border-primary-300 w-72">
                <div class="p-5 text-base font-semibold border-b bg-primary-100 border-primary-300">
                  Credit/Debit Card
                </div>

                <div class="p-3">
                  <div class="left ui icon fluid transparent input">
                    <i class="credit card alternative icon" />
                    <input :value="hiddenCardInfo"
                      type="text"
                      disabled=""
                      tabindex="-1"
                      class="text-sm"
                    >
                  </div>
                </div>

                <div class="flex justify-end p-3">
                  <Button @click='sources=[]'>Change</Button>
                </div>
              </div>
            </div>

            <div v-show="cart.name!=''" class="border rounded border-primary-300 checkout">
              <div class="p-5 text-base font-semibold border-b bg-primary-100 border-primary-300">
                Order Summary
              </div>

              <div class="px-5 py-3">
                <div class="description">
                  <div class="border rounded bg-primary-100 border-primary-200">
                    <div class="flex items-center justify-between px-2 py-1 text-xs leading-8">
                      <div class="font-bold">Order</div>
                      <div class="font-bold">Cost</div>
                    </div>

                    <div class="flex items-center justify-between px-2 py-1 text-xs leading-8 border-t border-primary-200">
                      <div>{{cart.name}}</div>
                      <div v-if="cart.name=='Pay as you go'">${{cart.cost}}/min</div>
                      <div v-else>${{cart.cost}}</div>
                    </div>

                    <div v-if="coupon" class="flex items-center justify-between px-2 py-1 text-xs leading-8 border-t border-primary-200">
                      <div>{{coupon.name}}</div>
                      <div>-{{coupon.percent_off}}%</div>
                    </div>

                    <div v-if="coupon" class="flex items-center justify-between px-2 py-1 text-xs leading-8 border-t border-primary-200">
                      <div>Total</div>
                      <div>${{cart.cost - 0.01*cart.cost*coupon.percent_off}}</div>
                    </div>

                    <div v-if="cart.name=='Pay as you go'" class="flex items-center justify-between px-2 py-1 text-xs leading-8 border-t border-primary-200">
                      <div>
                        <div>
                          Hours:
                        <select v-model="hrDuration" style='float:right;width:30%;margin:auto;background: transparent;border: 0px;border-bottom: 1px solid #5ac7ec;border-radius: 0px;'>
                          <option :key="i" style="color:black;" v-for="i in 101" :value="i-1">{{i-1}}</option>
                        </select>
                        </div>
                        <br>
                        <div>
                          Minutes:
                        <select v-model="duration" style='float:right;width:30%;margin:auto;background: transparent;border: 0px;border-bottom: 1px solid #5ac7ec;border-radius: 0px;'>
                          <option :key="i" style="color:black;" v-for="i in 61" :value="i-1">{{i-1}}</option>
                        </select>
                        </div>
                      </div>
                      <div>${{totalCost}}</div>
                    </div>

                  </div>

                  <div v-if="showPromo" class="flex items-center mt-2">
                    <input
                      v-model="promo"
                      @input="status=0"
                      @keyup.enter="getCoupon"
                      type="text"
                      placeholder="Promocode"
                      class="flex-grow block py-3 pl-3 pr-10 text-sm border-none rounded-tr bg-primary-100 text-primary placeholder-primary-500 focus:ring-0"
                    >

                    <div
                      v-if="!coupon"
                      @click="getCoupon"
                      class="cursor-pointer text-primary ml-2.5 hover:text-green"
                    >
                      <CheckIcon class="w-6 h-6" />
                    </div>
                    <div
                      v-else
                      @click="coupon=null;promo=''"
                      class="cursor-pointer text-primary ml-2.5 hover:text-red"
                    >
                      <CloseIcon class="w-6 h-6" />
                    </div>

                  </div>
                </div>
              </div>

              <div class="flex justify-end p-3 mt-1">
                <Button v-show="confirm==''" @click='confirmOrder'>
                  Confirm Order
                </Button>
                <Button variant="disabled" v-show="confirm=='confirming'">Processing...</Button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- Cancel steps -->
      <div v-show="currentTab.startsWith('Cancel')" class="relative p-6 cancelModal">
        <div v-if="currentTab==='Cancel1'" class="text-2xl font-semibold font-h text-primary">
          😔 Sad to see you go
        </div>
        <div v-if="currentTab==='Cancel'" class="text-2xl font-semibold font-h text-primary">
          Cancellation
        </div>

        <div v-if="currentTab==='Cancel1'">
          <div class="mt-2 mb-6 text-xs text-primary">
            Before you cancel, please tell us the reason
          </div>

          <div class="flex justify-between">
            <div class="">
              <div
              v-for="(option, i) in cancelReasons"
              :key="i"
              class="cursor-pointer text-xs mb-3 py-1.5 px-2.5" @click="feedbackOption=option"
              >
                <Radio name="option" :checked="feedbackOption === option" :label="option" />
              </div>
            </div>

            <div class="w-64">
              <textarea
                placeholder="Any other feedback? please let us know"
                v-model="feedbackMsg"
                class="w-full p-4 text-sm border rounded-lg resize-none bg-primary-100 border-primary-200 h-28 text-primary placeholder-primary-500 focus:ring-primary focus:border-primary-200 focus:ring-2"
              />

              <Button
                class="mt-2.5 w-full justify-center cancelConfirmButton"
                variant="secondary"
                @click="submitFeedback();setTab('Cancel')"
              >
                Cancel Subscription
              </Button>
              <Button
                class="mt-2.5 w-full justify-center cancelConfirmButton"
                @click="setTab('Profile')"
              >
                I don't want to cancel
              </Button>

              <div class="flex mt-3.5 w-full justify-center">
                <a
                  class="text-blue text-2xs hover:text-blue"
                  onclick="window.Intercom('showNewMessage', '')"
                >
                  Contact Support
                </a>
              </div>

            </div>
          </div>
        </div>

        <div v-if="currentTab==='Cancel'">
          <div class="mt-2 mb-4 text-base text-primary">
            Are you sure you want to cancel your subscription?
          </div>
          <div class="mt-4 mb-4 text-sm text-primary">
            If you select Yes, you will stil be able to use your unused minutes.
          </div>

          <div class="flex justify-end">
            <Button
              size="small"
              v-show="confirm==''"
              @click="cancel"
              variant="secondary"
            >
              Yes
            </Button>
            <Button
              size="small"
              v-show="confirm=='canceling'"
              variant="disabled"
            >
              Processing...
            </Button>
            <Button
              size="small"
              @click="setTab('Profile')"
              class="ml-2.5"
            >
              No
            </Button>
          </div>
        </div>

      </div>
    </div>
  </Modal>
  <EnterpriseForm />
  </div>
</template>

<script>
import camelCase from 'lodash/camelCase'
import { mapActions, mapGetters } from 'vuex'
import Avatar from 'vue-avatar'
import EnterpriseForm from './Enterprise.vue'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import axios from 'axios'
import moment from 'moment'
import CONSTANTS from '@/constants/CONSTANTS'
import * as firebase from 'firebase/app'
import 'firebase/database'
import store from '@/services/store'
import { humanLength } from '@/utilities/utility'

import CloseIcon from '@/components/base/icons/CloseCircle.vue'
import CheckIcon from '@/components/base/icons/Check.vue'
import Radio from '@/components/base/inputs/Radio'

const tabmap = {
  Profile: 'Profile',
  Plans: 'Plans',
  'Top Up': 'Bulk Discount',
  Billing: 'Billing'
}

const cancelReasons = [
  'I don’t need it anymore',
  'It’s slow &amp; buggy!',
  'I found something better',
  'It’s too expensive',
  'Didn’t get good support'
]

export default {
  components: {
    StripeForm: () => import(/* webpackChunkName: "Form" */'./StripeForm'),
    Plans: () => import(/* webpackChunkName: "PlansTab" */'./Plans'),
    Modal,
    Avatar,
    Button,
    CloseIcon,
    CheckIcon,
    Radio,
    EnterpriseForm
  },
  computed: {
    ...mapGetters({
      modalName: 'dialogs/modalName',
      modalProps: 'dialogs/modalProps'
    }),
    customerId () {
      return store.state.app.user.uid
    },
    balanceHuman () {
      return humanLength(this.billing.seconds)
    },
    user () {
      return store.state.app.user
    },
    showPromo () {
      return this.coupon || this.selectedOption !== 'topup'
    },
    userInfo () {
      return store.state.app.userInfo
    },
    billing () {
      return store.state.app.userInfo.billing
    },
    planName () {
      for (var i = 0; i < this.plans.length; i++) {
        // here we are using the plans list fron data
        if (this.plans[i]['id'] === store.state.app.userInfo.billing.subscribed) {
          console.log(this.plans[i])
          return this.plans[i].name
        }
      }
      return 'Free'
    },

    hiddenCardInfo () {
      if (this.sources.length > 0) {
        var last4 = ''
        var month = ''
        var year = ''
        last4 = this.sources[0].last4
        if (this.sources[0].exp_month < 10) {
          month = '0' + this.sources[0].exp_month
        } else {
          month = this.sources[0].exp_month
        }
        year = this.sources[0].exp_year % 100
        return 'XXXX XXXX XXXX ' + last4 + '   ' + month + '/' + year
      }
      return ''
    },
    needsSubscription () {
      if (this.subscriptions.plan_name === '-' || store.state.app.userInfo.billing.subscribed === 'free2020') {
        return true
      }
      return false
    }
  },
  data () {
    return {
      cancelReasons,
      tabmap,
      currentTab: 'Profile', // 'Top Up', 'Billing', 'Plans'
      totalCost: 0,
      totalTime: 0,
      coupon: null,
      promo: '',
      feedbackOption: '',
      feedbackMsg: '',
      hrDuration: 1,
      duration: 30,
      customer: '',
      subscriptions: {
        plan_name: '-',
        current_period_end: '-',
        id: '',
        amount: 0
      },
      sources: [],
      steps: ['Profile', 'Plans'],
      plans: [
        {
          id: 'PayAsYouGo',
          code: 'transcript_plan',
          name: 'Pay as you go',
          cost: 0.35,
          amount: 0,
          hours: '4',
          rate: '$6',
          duration: 'minute',
          features: [
            '<b>No monthly committment</b>',
            'Automated Transcript',
            'Audio Editor is Free!',
            'Export as .srt, .vtt, .doc, .txt',
            'Audio Post Production - Noise reduction, levelling.',
            'Search for keywords across files'
          ]
        },
        {
          id: 'plan_CfmUsDYmhceRCm',
          code: 'transcript_plan',
          name: 'Transcript Plan',
          cost: 39.99,
          amount: 3999,
          hours: '4',
          rate: '$6',
          duration: 'month',
          features: [
            'Upload <b>4</b> hrs / month',
            'Automated Transcript',
            'Take Easy Notes',
            'Sharing as Video',
            'Export as .srt, .vtt, .doc, .txt',
            'Search for keywords across files',
            'Transcript turn around time is : 10 min for 1 hour.'
          ]
        },
        {
          id: 'plan_DaHereYDMfqIMf',
          code: 'audio_editor_plan',
          name: 'Voice Plan',
          cost: 49.99,
          amount: 4999,
          hours: '4',
          rate: '$12.5',
          duration: 'month',
          features: [
            'Audio Editor at <b>$10/month</b>',
            'Transcript at <b>$10/hr</b>.',
            'Upload <b>2</b> hrs / month',
            'Edit Audio Easily',
            'Audio Post Production - Noise reduction, levelling.',
            'Export as mp3, wav, flac, ogg',
            // 'Monthly Roll over on unused minutes',
            '<b>All benefits of Transcript Plan</b>'
          ]
        },
        {
          id: 'plan_DaHa3NiVUA3m90',
          code: 'audio_transcript_premium',
          name: 'Value Plan',
          cost: 54.99,
          amount: 5499,
          hours: '5',
          rate: '$6',
          duration: 'month',
          features: [
            'Audio Editor is <b>Free!</b>',
            'Transcript at <b>$10/hr</b>.',
            'Upload <b>5</b> hrs / month',
            '<b>All benefits of Transcript &amp; Audio Editor Plan</b>'
          ]
        },
        {
          id: 'plan_D6MEaZldl3pHaO',
          code: 'legal_plan',
          name: 'Legal Plan',
          cost: 79.99,
          amount: 7999,
          hours: '4',
          rate: '$20',
          duration: 'month',
          features: [
            '<b>Upload <b>4</b> hrs / month<b>',
            'Automated Transcript',
            'Take Easy Notes',
            'Redact and Export as .pdf in Legal format',
            'Search for keywords across files',
            'Edit Audio Easily',
            'Audio Post Production - Noise reduction, levelling.',
            'Export as mp3, wav, flac, ogg'
          ]
        },
        // This is being used in addPlanToCart method
        {
          name: 'Transcriber Plan',
          cost: 15,
          amount: 1500,
          id: 'transcriber_2021'
        },
        {
          name: 'Producer Plan',
          cost: 19,
          amount: 1900,
          id: 'producer_2021'
        }
      ],
      topups: [
        { name: 'Topup - 5 hours', hours: '5 hrs', cost: 54.99, rate: '($11/hour)', amount: 5499 },
        { name: 'Topup - 10 hours', hours: '10 hrs', cost: 89.99, rate: '($9/hour)', amount: 8999 },
        { name: 'Topup - 25 hours', hours: '25 hrs', cost: 174.99, rate: '($7/hour)', amount: 17499 },
        { name: 'Topup - 50 hours', hours: '50 hrs', cost: 299.99, rate: '($6/hour)', amount: 29999 }
      ],
      cart: {
        id: '',
        name: '',
        cost: ''
      },
      status: '',
      confirm: '',
      app_id: CONSTANTS.APP_ID,
      page_id: CONSTANTS.PAGE_ID,
      errorCode: {
        500: 'Please add Credit/Debit card',
        501: '',
        502: 'Unable to subscribe, please try later',
        503: 'Unable to topup, please try later',
        504: 'Unable to cancel subscription, please try later',
        600: 'Subscribe to a plan to get bulk discounts',
        601: 'Please add minutes greater than 0',
        602: 'Invalid Coupon'
      },
      successCode: {
        200: 'Great! Card added.',
        201: '',
        202: 'Great! Subscribed to the plan.',
        203: 'Great! Topup added.',
        204: 'Subscription cancelled.',
        205: 'Coupon Applied'
      },
      selectedOption: ''
    }
  },
  created: function() {
    if (this.modalProps) this.currentTab = this.modalProps.tab || 'Profile'
  },
  watch: {
    customerId: function(val, oldVal) {
      if (val !== oldVal) this.getCustomerInfoNow()
    },
    modalName: function(val, oldVal) {
      if (val !== oldVal) {
        if (val === 'AccountInfo') {
          // at open
          if (this.modalProps && this.modalProps.tab) this.currentTab = this.modalProps.tab
        } else {
          // at close
          this.currentTab = 'Profile'
        }
      }
    },
    currentTab: function (val) {
      var vm = this
      window.analytics.track(`popup_controlPanelTab_${camelCase(val)}`)
      if (val === 'Plans') {
        setTimeout(function () {
          $('.ui.billing.modal').modal('refresh')
        }, 0)
        vm.feedbackOption = ''
        vm.feedbackMsg = ''
      }
      if (val !== 'Billing') {
        vm.clearCoupon()
      }
    }
  },
  mounted () {
    this.getCustomerInfoNow()
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal',
      openModal: 'dialogs/openModal'
    }),
    setTab (tab) {
      this.currentTab = tab
    },
    clearCoupon () {
      this.promo = ''
      this.coupon = null
    },
    prefillCoupon (coupon) {
      let vm = this
      axios.get(CONSTANTS.GET_COUPON, { params: {
        couponID: coupon
      } }).then(function (res) {
        console.log('res', res)
        vm.promo = coupon
        vm.coupon = res.data
        vm.status = 205
        vm.timeout()
      }).catch(function (err) {
        console.log('err', err)
        vm.coupon = null
      })
    },
    getCoupon () {
      let vm = this
      axios.get(CONSTANTS.GET_COUPON, { params: {
        couponID: vm.promo
      } }).then(function (res) {
        console.log('res', res)
        vm.coupon = res.data
        vm.status = 205
        vm.timeout()
      }).catch(function (err) {
        console.log('err', err)
        vm.coupon = null
        vm.status = 602
        vm.timeout()
      })
    },
    submitFeedback () {
      var vm = this
      firebase.database().ref('feedback/' + vm.user.uid).push().set({ 'reason': vm.feedbackOption, 'msg': vm.feedbackMsg }).then(function () {
        console.log('Written Feeback to Firebase')
      }).catch(function (err) {
        console.log('Unable to write feedback', err)
      })
    },
    subscribeFirst () {
      console.log('subscribe first')
      var vm = this
      vm.status = 600
      vm.timeout()
    },
    timeout () {
      var vm = this
      setTimeout(function () {
        vm.status = 0
      }, 5000)
    },
    sourceError (error) {
      var vm = this
      if (error === '') {
        vm.status = 0
        return
      }
      this.errorCode[501] = error
      this.status = 501
      window.analytics.track('popup_controlPanel_BillingError', { msg: error })
      vm.timeout()
    },
    sourceAdded (source) {
      var vm = this
      if (source === 501) {
        this.status = 501
        vm.timeout()
        return
      }
      this.status = 200
      window.analytics.track('popup_billingPaymentInfoAdded')
      vm.timeout()
      this.sources = source
    },
    getCustomerInfoNow () {
      // console.log('watching customerId')
      if (this.customerId === '') {
        return
      }
      // const customerId = 'cus_AliBaJCMTL9kn5'
      var vm = this
      axios.get(`${CONSTANTS.BASE_LINK}/cus`, {
        params: {
          id: this.customerId
        }
      })
        .then(function (response) {
          vm.customer = response.data.customer
          if (vm.customer && vm.customer.subscriptions.data && vm.customer.subscriptions.data.length > 0) {
            vm.subscriptions.plan_name = vm.customer.subscriptions.data[0].plan.name
            // vm.subscriptions.current_period_end=vm.customer.subscriptions.data[0].current_period_end
            vm.subscriptions.current_period_end = moment.unix(vm.customer.subscriptions.data[0].current_period_end).format(`Do MMM 'YY`)
            vm.subscriptions.id = vm.customer.subscriptions.data[0].plan.id
            vm.subscriptions.amount = vm.customer.subscriptions.data[0].plan.amount
          } else {
            vm.subscriptions.plan_name = '-'
            vm.subscriptions.current_period_end = '-'
            vm.subscriptions.id = ''
            vm.subscriptions.amount = 0
          }
          if (vm.customer && vm.customer.sources.data && vm.customer.sources.data.length > 0) {
            vm.sources = vm.customer.sources.data
          } else {
            vm.sources = []
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    addPlanToCart ({ planId, title }) {
      window.analytics.track('popup_billingPlanAdded', { planId, name: title })
      if (planId === 'enterprise_2022') {
        this.closeModal()
        this.openModal({ name: 'EnterpriseForm' })
      } else {
        for (var i = 0; i < this.plans.length; i++) {
        // here we are using the plans list fron data
          if (this.plans[i]['id'] === planId) {
            console.log(this.plans[i])
            this.cart = this.plans[i]
          }
        }
        this.setTab('Billing')
        // console.log(this.userInfo.accountInfo)
        if (planId === 'producer_2019' && this.userInfo.accountInfo.coupon) {
          this.prefillCoupon(this.userInfo.accountInfo.coupon)
        }
      }

      this.selectedOption = 'plan'
    },
    addTopupToCart ({ name, amount }) {
      window.analytics.track('popup_topUpPlanAdded', { name, amount })
      for (var i = 0; i < this.topups.length; i++) {
        if (this.topups[i]['amount'] === amount) {
          console.log(this.topups[i])
          this.cart = this.topups[i]
        }
      }
      this.setTab('Billing')
      this.selectedOption = 'topup'
    },
    subscribe (planId) {
      var vm = this
      // const customerId = 'cus_AliBaJCMTL9kn5'
      const customerId = vm.customerId
      var planChange = ''
      console.log('customerId:' + customerId)
      if (vm.subscriptions.plan_name === 'Basic') {
        planChange = 'upgrade'
      } else if (vm.subscriptions.plan_name === 'Premium') {
        planChange = 'downgrade'
      } else {
        planChange = 'subscribe'
      }
      var query = `${CONSTANTS.BASE_LINK}/${planChange}`
      console.log('query', query)
      if (customerId) {
        axios.get(query, {
          params: {
            id: customerId,
            plan: planId,
            coupon: this.promo
          }
        })
          .then(function (response) {
            console.log(response)
            if (response.status === 200) {
              if (response.data.subscriptions_obj) {
                vm.subscriptions.plan_name = response.data.subscriptions_obj.plan.name
                // vm.subscriptions.current_period_end=response.data.subscriptions_obj.current_period_end
                vm.subscriptions.current_period_end = moment.unix(response.data.subscriptions_obj.current_period_end).format('Do MMM, YY')
              } else if (response.data.subscription) {
                vm.subscriptions.plan_name = response.data.subscription.plan.name
                vm.subscriptions.current_period_end = moment.unix(response.data.subscription.current_period_end).format('Do MMM, YY')
              } else if (response.data.customer && response.data.customer.subscriptions.data.length > 0) {
                vm.subscriptions.plan_name = response.data.customer.subscriptions.data[0].plan.name
                vm.subscriptions.current_period_end = moment.unix(response.data.customer.subscriptions.data[0].current_period_end).format('Do MMM, YY')
              } else {
                vm.subscriptions.plan_name = '-'
                vm.subscriptions.current_period_end = '-'
              }

              window.analytics.track('popup_billingPurchaseComplete', { plan: planId, orderValue: vm.cart && vm.cart.cost ? vm.cart.cost : 0 })

              vm.cart = {
                id: '',
                name: '',
                cost: ''
              }
              vm.setTab('Profile')
              vm.confirm = ''
              vm.status = 202
              vm.timeout()
            }
          })
          .catch(function (error) {
            console.log(error)
            vm.setTab('Profile')
            vm.confirm = ''
            vm.status = 502
            vm.timeout()
          })
      }
    },
    topUp (amount) {
      console.log('topup:' + amount)
      var vm = this
      // const customerId = 'cus_AliBaJCMTL9kn5'
      const customerId = vm.customerId
      console.log('customerId:' + customerId)
      if (customerId) {
        var query = ''
        if (vm.cart.name === 'Pay as you go') {
          query = `${CONSTANTS.BASE_LINK}/pay_go?id=${customerId}&minutes=${vm.totalTime}`
          console.log(query)
        } else {
          query = `${CONSTANTS.BASE_LINK}/add_topup?id=${customerId}&amount=${amount}`
        }
        console.log(query)
        axios.get(query)
          .then(function (response) {
            console.log(response)
            vm.cart = {
              id: '',
              name: '',
              cost: ''
            }
            vm.setTab('Profile')
            vm.confirm = ''
            vm.status = 203
            window.analytics.track('popup_billingPurchaseComplete', { orderValue: amount })
            vm.timeout()
          })
          .catch(function (error) {
            console.log(error)
            vm.confirm = ''
            vm.status = 503
            vm.timeout()
          })
      }
    },
    confirmOrder () {
      window.analytics.track('popup_billingConfirmOrderClicked')
      var vm = this
      if (this.hiddenCardInfo === '') {
        this.status = 500
        vm.timeout()
        return
      }
      if (this.cart.id && this.cart.name !== 'Pay as you go') {
        vm.confirm = 'confirming'
        console.log('subscribing')
        this.subscribe(this.cart.id)
        return
      }
      if (this.cart.amount) {
        vm.confirm = 'confirming'
        console.log('add topup')
        this.topUp(this.cart.amount)
      } else {
        this.status = 601
        this.timeout()
      }
    },
    cancel () {
      if (this.customerId === '') {
        return
      }
      this.confirm = 'canceling'
      // const customerId = 'cus_AliBaJCMTL9kn5'
      var vm = this
      axios.get(`${CONSTANTS.BASE_LINK}/cancel`, {
        params: {
          id: this.customerId
        }
      })
        .then(function (response) {
          console.log(response)
          vm.customer = response.data.customer
          if (vm.customer.subscriptions.data.length > 0) {
            vm.subscriptions.plan_name = vm.customer.subscriptions.data[0].plan.name
            // vm.subscriptions.current_period_end=vm.customer.subscriptions.data[0].current_period_end
            vm.subscriptions.current_period_end = moment.unix(vm.customer.subscriptions.data[0].current_period_end).format('Do MMM, YY')
          } else {
            vm.subscriptions.plan_name = '-'
            vm.subscriptions.current_period_end = '-'
          }
          if (vm.customer && vm.customer.sources.data && vm.customer.sources.data.length > 0) {
            vm.sources = vm.customer.sources.data
          } else {
            vm.sources = []
          }
          vm.setTab('Profile')
          vm.confirm = ''
          vm.status = 204
          window.analytics.track('popup_billingOrderCancelled')
          vm.timeout()
        })
        .catch(function (error) {
          console.log(error)
          vm.setTab('Profile')
          vm.confirm = ''
          vm.status = 504
          vm.timeout()
        })
    }
  }
}
</script>

<style lang="css" scoped>
.root {
  width: 780px;
  min-height: 450px;
}

.checkout {
  width: 350px;
}

.cancelModal {
  width: 560px;
}

.cancelConfirmButton {
  border-radius: 8px !important;
}
</style>
