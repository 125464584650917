var baseLink = ''
var baseLink2 = ''
var pubAppLink = ''
exports.devMode = false

if (process.env.NODE_ENV === 'production') {
  baseLink = 'https://m80hzx9bl0.execute-api.us-west-2.amazonaws.com/prod'
  baseLink2 = 'https://us-central1-podcastpi-1b0c0.cloudfunctions.net'
  pubAppLink = 'https://api-dot-podcastpi-1b0c0.appspot.com'
  // demo from hugo@spext.co account
  exports.demoUserid = 'e5oQzdw51SQWqjR3jGiQ15ybyps2'
  exports.demoMediaid = '11eb-fff2-ebf23660-aa13-b71361b87724'
  exports.demoWithNoiseAudio = `podcast_audio/${this.demoUserid}/uploaded_Audio/demo_noise.flac`
  exports.demoWithNoiseSource = `${this.demoMediaid}#!@${this.demoUserid}-noise`

  exports.BASE_LINK = baseLink
  exports.STRIPE_PUBLISH_KEY = process.env.VUE_APP_STRIPE_PUBLISH_KEY
  exports.CONFIG = {
    apiKey: process.env.VUE_APP_CONFIG_APIKEY,
    authDomain: 'app.spext.co',
    databaseURL: process.env.VUE_APP_CONFIG_DATABASEURL,
    projectId: process.env.VUE_APP_CONFIG_PROJECTID,
    storageBucket: process.env.VUE_APP_CONFIG_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_CONFIG_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_CONFIG_APPID
  }
  exports.APP_ID = process.env.VUE_APP_APP_ID
  exports.PAGE_ID = process.env.VUE_APP_PAGE_ID
  exports.PUBLIC_VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY
  exports.ACCOUNT_TYPE = process.env.VUE_APP_ACCOUNT_TYPE
  if (process.env.VUE_APP_ACCOUNT_TYPE === 'Beta') {
    exports.URL = 'https://app.beta.spext.co'
  } else {
    exports.URL = 'https://app.spext.co'
  }
  exports.PUBLISH_URL = 'https://publish.spext.co'

  exports.newDemoUserId = 'e5oQzdw51SQWqjR3jGiQ15ybyps2'
  exports.newDemoMediaId = '11ed-2452-55025d90-a2c6-53fb3cde51de'
  exports.newDemoWithNoiseAudio = `/podcast_audio/${this.newDemoUserId}/uploaded_Audio/video_noise.flac`
  exports.newDemoWithNoiseVideo = `/podcast_audio/${this.newDemoUserId}/uploaded_Audio/video_noise.mp4`
  exports.newDemoWithNoiseSource = `${this.newDemoMediaId}#!@${this.newDemoUserId}-noise`
  exports.whitelistedPaths = ['/audio/1WRUbVWbSqQKo38T9pYki55Nnh12/11ef-5a5c-a1090a30-8e17-49f8833e903c', '/audio/mJsKJIqJD8QqfwvuTFksqN6as3X2/11ed-012d-4bcb3c80-9412-c7b6f243de26']
} else if (process.env.NODE_ENV === 'development') {
  baseLink = 'https://mgi60tzotd.execute-api.us-east-1.amazonaws.com/dev'
  baseLink2 = 'https://us-central1-dev-podcastpi.cloudfunctions.net'
  pubAppLink = 'https://api-dot-dev-podcastpi.appspot.com'
  // new demo hosted on hugo@spext.co
  exports.demoUserid = 'HoOIzNnYWMUsll3SOnV7OSMRRxB2'
  exports.demoMediaid = '11eb-fc06-8b683dc0-8331-6b00cf4c9dac'
  exports.demoWithNoiseAudio = `podcast_audio/${this.demoUserid}/uploaded_Audio/demo_noise.flac`
  exports.demoWithNoiseSource = `${this.demoMediaid}#!@${this.demoUserid}-noise`
  exports.newDemoUserId = 'HoOIzNnYWMUsll3SOnV7OSMRRxB2'
  exports.newDemoMediaId = '11ed-2453-61fe1380-80d3-3b4c464fa1f0'
  exports.newDemoWithNoiseAudio = `/podcast_audio/${this.newDemoUserId}/uploaded_Audio/video_noise.flac`
  exports.newDemoWithNoiseVideo = `/podcast_audio/${this.newDemoUserId}/uploaded_Audio/video_noise.mp4`
  exports.newDemoWithNoiseSource = `${this.newDemoMediaId}#!@${this.newDemoUserId}-noise`

  exports.BASE_LINK = baseLink
  exports.STRIPE_PUBLISH_KEY = process.env.VUE_APP_STRIPE_PUBLISH_KEY
  exports.CONFIG = {
    apiKey: process.env.VUE_APP_CONFIG_APIKEY,
    authDomain: 'app.dev.spext.co',
    databaseURL: process.env.VUE_APP_CONFIG_DATABASEURL,
    projectId: process.env.VUE_APP_CONFIG_PROJECTID,
    storageBucket: process.env.VUE_APP_CONFIG_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_CONFIG_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_CONFIG_APPID
  }
  exports.APP_ID = process.env.VUE_APP_APP_ID
  exports.PAGE_ID = process.env.VUE_APP_PAGE_ID
  exports.PUBLIC_VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY
  exports.ACCOUNT_TYPE = process.env.VUE_APP_ACCOUNT_TYPE
  exports.URL = 'https://app.dev.spext.co'
  exports.PUBLISH_URL = 'https://public.spext.co'

  exports.whitelistedPaths = ['/audio/v7W6sGIVOkaAh5srNrTSg8L0z3x2/11ee-837c-ee06caf0-a462-f52480e10932']
}
exports.IS_VALID = baseLink + '/isValid'
exports.CONVERT_DOC_URL = baseLink + '/export'
exports.CONVERT_PDF_URL = baseLink + '/export_pdf'
exports.TWEET_URL = baseLink + '/twitter_upload'
exports.NEW_VIDEO_GENERATE_URL = baseLink + '/get_snippet'
exports.VIDEO_GENERATE_URL = baseLink + '/update_snippet'
exports.AUDIO_SEARCH = baseLink + '/audio_search'
exports.POST_PRODUCTION = baseLink + '/post_production'
exports.SESSION_EXPORT = baseLink + '/session_export'
exports.MERGE_TRACKS = baseLink + '/multitrack_merge'
exports.AUDIO_STORY = baseLink + '/audio_story'
exports.CUT_VIDEO = baseLink + '/cut_video'
exports.REDACT = baseLink + '/redact_video'
exports.RETRY = baseLink + '/retry'
exports.SUBSCRIBE = baseLink + '/subscribe'
exports.GET_USER = baseLink2 + '/getUser'
exports.TRANSFER_BALANCE = baseLink2 + '/transferBalance'
exports.MAKE_COPY = baseLink + '/make_copy'
exports.MAKE_CLIP_DOC = baseLink + '/make_clip_doc'
exports.SPEXT_PUBLISH = baseLink + '/publish/spext/start'
exports.SPEXT_CHANNEL_PUBLISH = baseLink + '/publish/add/channel'
exports.CREATE_VIDEO_SNIPPET = baseLink + '/snippet'
exports.GET_SEARCH_RESULT = baseLink + '/search'
exports.CREATE_CUSTOM__AUTH_TOKEN = baseLink2 + '/createCustomToken'

// --------------------------------
// node AppEngine
// end points
exports.GET_USER_SNIPPETS = pubAppLink + '/user-snippets'
exports.GET_USER_INFO = pubAppLink + '/user-info'
exports.GET_TRANSCRIPT = pubAppLink + '/transcript'
exports.GET_VIDEO_URL = pubAppLink + '/videoURL'

exports.ERROR_CODES = {
  400: 'Unable to access the link, Please check.',
  401: 'File format is not supported.<br>Please convert it to one of the known formats.',
  402: 'We need sample rate to be greater than 16000 for this language.',
  403: 'We need sample rate to be greater than 8000 for English language.',
  404: 'There is some problem with the audio! Can not calculate the Sample rate.',
  500: `Error in downloading the audio.<br>Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">let us know</a> of this event.`,
  501: 'Not enough balance in account. Please add a topup.',
  502: `Error in audio processing, format conversion.<br>Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">let us know</a> in chat`,
  503: `Our server is facing a problem in transcribing.<br>Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">reach out to us</a>.`,
  504: 'File length is more than 3 hrs, Please reduce the length and try again.',
  505: 'Can not determine the size of the file.',
  506: `File size is too big for us to process. Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">reach out to us</a> for enterprise plans.`,
  600: `File not transcribed due to a server issue.<br>Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">reach out to us</a>.`
}
exports.WAITING_CODES = {
  100: 'Waiting',
  200: 'Downloading',
  201: 'Downloaded',
  202: 'Converted',
  203: 'Transcribing',
  204: 'Punctuating/Transcribed'
}
exports.ALL_PLATFORMS = false
exports.RIGHT_CLICK_DISABLED = false
exports.LOAD_AUDIO_LOCAL = false
exports.LOAD_TRANSCRIPT_LOCAL = false
exports.LOCAL_AUTH = false
exports.DEV_MODE_2 = false
exports.ADD_EMPTY_PARAGRAPH = false
exports.SHOW_SAMPLE_FILE_MODAL_RIGHT_CLICK = false
exports.ENABLE_SPELL_CHECK = false
exports.COMPATIBLE_BROWSERS = { 'chrome': true, 'safari': true, 'firefox': true }
exports.EXTRA_PARA = false // floating menu
exports.LOOP = false
// number of seconds that will be transcribed in trial
exports.TRIAL_LIMIT_PER_TRANSCRIPT = 1800
exports.GET_COUPON = baseLink2 + '/getCoupon'
exports.VOICE_PLANS = ['plan_DaHereYDMfqIMf', 'plan_DaHa3NiVUA3m90', 'plan_CfmXG3d7ceFQq1', 'plan_CgpQOYO4E3tEyV', 'producer_2019', 'dev']
exports.SAMPLE_PUBLISH_LINK = 'https://publish.spext.co/publish/3hVR0KKDVzTZCeOnzuYkEjq1Juw1/11ea-8b85-56129e06-b30f-0242ac110006'
exports.SAMPLE_PUBLISH_EPSIODE = 'https://publish.spext.co/list/28f870ecb9b649baa5abb0bece28f999'
exports.PUBLISH_EPSIODE_BASE = 'https://publish.spext.co/list/'
exports.CAL_INFO_URL = process.env.NODE_ENV === 'development' ? 'https://ts8jipon72.execute-api.us-east-1.amazonaws.com/dev' : 'https://uy77r1wobi.execute-api.us-east-2.amazonaws.com/prod'
exports.CAL_UI_URL = process.env.NODE_ENV === 'development' ? 'https://calendar.dev.spext.co' : 'https://calendar.spext.co'
// Time limit of the automatic clipper
exports.AUTO_CLIPPER_LIMIT = 80
exports.PROJECT_TYPES = [
  {
    name: 'Video Clipper',
    title: 'Clipper',
    description: 'Create clips using the transcript',
    type: 'video',
    comingSoon: false,
    icon: '/images/drive/video-clipper.svg',
    containerHoverClass: 'hover:border-green hover:bg-green-50',
    class: 'text-green',
    hoverClass: 'group-hover:text-green'
  },
  {
    name: 'Automated Video Clipper',
    title: 'Automatic Clipper',
    description: 'Create clips using timestamps/ shownotes',
    type: 'video',
    comingSoon: false,
    icon: '/images/drive/automated-video-clipper.svg',
    containerHoverClass: 'hover:border-magenta hover:bg-magenta-50',
    class: 'text-magenta',
    hoverClass: 'group-hover:text-magenta'
  },
  {
    name: 'Audiogram',
    description: 'Turn recordings into shareable videos',
    type: 'audio',
    comingSoon: false,
    icon: '/images/drive/audiogram.svg',
    containerHoverClass: 'hover:border-yellow hover:bg-yellow-50',
    class: 'text-yellow',
    hoverClass: 'group-hover:text-yellow',
    path: 'audiogram',
    extraSettings: {
      enableSubtitleFormatting: true,
      template: 'four',
      enableLogo: true,
      accentColor: '#E86B56',
      backgroundType: 'color',
      backgroundColor: '#FFFFFF',
      titleStyle: {
        color: '#20557D',
        'font-family': 'Archivo',
        'font-weight': '600'
      },
      subtitleStyle: {
        color: '#000228',
        'font-family': 'Roboto',
        'font-weight': '400'
      }
    }
  },
  {
    name: 'Blank Spext Doc',
    description: 'Create a compilation of different files',
    type: 'blank',
    comingSoon: false,
    icon: '/images/drive/blank-doc.svg',
    containerHoverClass: 'hover:border-red hover:bg-red-50',
    class: 'text-red',
    hoverClass: 'group-hover:text-red',
    path: 'audio'
  },
  {
    name: 'AI Voiceover',
    type: 'audio',
    comingSoon: true,
    icon: '/images/drive/ai-voiceover.svg',
    class: 'text-sky',
    hoverClass: 'group-hover:text-sky',
    description: 'Create human sounding voiceovers'
  },
  {
    name: 'Recording to Blog',
    type: 'audio',
    comingSoon: true,
    icon: '/images/drive/recording-to-blog.svg',
    class: 'text-yellow',
    hoverClass: 'group-hover:text-yellow',
    description: 'Convert recording to blog'
  },
  {
    name: 'Meeting Highlights',
    type: 'audio',
    comingSoon: true,
    icon: '/images/drive/meeting-highlights.svg',
    class: 'text-red',
    hoverClass: 'group-hover:text-red',
    description: 'Share the best bits of meetings'
  },
  {
    name: 'Compilation',
    type: 'video',
    comingSoon: true,
    icon: '/images/drive/compilation.svg',
    class: 'text-lime',
    hoverClass: 'group-hover:text-lime',
    description: 'Stitch different recordings together'
  }
]
