
import { Node, Fragment } from 'prosemirror-model'
import { schema } from '@/view/voiceEditor/proseEditor/Schema/schema'
import { EditorState, NodeSelection, TextSelection } from 'prosemirror-state'
import { history } from 'prosemirror-history'
import { collab, receiveTransaction } from 'prosemirror-collab/dist/collab'
import { SetDocAttr } from '@/view/voiceEditor/proseEditor/Steps/SetDocAttr'
import {
  uncompressStateJSON,
  uncompressStepJSON
} from 'prosemirror-compress/src'
import { Step } from 'prosemirror-transform'
import { format } from '@/view/voiceEditor/vue/components/formatter'
import uuidv1 from 'uuid/v1'
import { silenceSymbol } from '@/view/voiceEditor/proseEditor/CONSTANTS'
import {
  isMarkDeleted, toggleAddButton
} from '@/view/voiceEditor/proseEditor/util/utility'
import * as firebase from 'firebase'
import 'firebase/auth'
import 'firebase/database'

/**
 *mediaArray =[
 * {
 *   name, asource, time
 * },{}
 * ]
 * */
export async function insertInlineMedia(state, mediaArray) {
  let timeShift = 0
  for (const media of mediaArray) {
    media.time = media.time + timeShift
    state = addAudioVideoTime(state, media.url, media.name, media.asource, media.time, media.time, media.duration)

    timeShift += media.duration + 1
  }
  return state
}
function getDataFromPath (userId, podId, firebase) {
  return (path1, path2) => {
    const url = `${userId}/${path1}/${podId}/${path2}`
    return firebase.database()
      .ref(url)
      .once('value').then(function (value) {
        if (value.val() !== null) {
          console.log('Getting ', url)
          return value.val()
        } else {
          throw new Error('Not present')
        }
      })
  }
}

export function getTranscript (userId, podId, firebase) {
  console.log('getting podcast Transcript', userId, podId)
  //   if (CONSTANTS.LOAD_TRANSCRIPT_LOCAL) {
  //     return format(sampleTranscript, true)
  //   }

  let getData = getDataFromPath(userId, podId, firebase)
  let checkpoint = getData('podcast', 'realtime/checkpoint')
  return checkpoint.then(function (transcript) {
    let { d, k, t } = transcript || {}
    let { doc } = uncompressStateJSON({ d })
    return { doc, k: k, t: t }
  }).catch(function (err) {
    console.log('err', err)
    let edited = getData('podcast', 'editor_corrected')
    return edited.then(function (transcript) {
      return format(transcript, false, podId)
    }).catch(function (err) {
      console.log('err', err)
      let original = getData('podcast', 'editor')
      return original.then(function (transcript) {
        return format(transcript, true, podId)
      })
    })
  })
}

export function compressedStepJSONToStep (compressedStepJSON) {
  const uncompressedStepJSON = uncompressStepJSON(compressedStepJSON)
  if (uncompressedStepJSON['stepType'] === 'SetDocAttr') {
    return SetDocAttr.fromJSON(uncompressedStepJSON)
  }
  return Step.fromJSON(schema, uncompressedStepJSON)
}
export async function createProseDoc(ownerId, podId, firebase) {
  console.log('project doc', `${ownerId}/podcast-info/${podId}`)
  let voiceEditorPlugins = [
    history(),
    collab({ clientID: `${ownerId}-${uuidv1()}` })
  ]

  let content = await getTranscript(ownerId, podId, firebase)

  let { doc: tDoc, k: latestKey = -1 } = content
  let ndoc = Node.fromJSON(schema, tDoc)
  let state = EditorState.create({
    schema: schema,
    doc: ndoc,
    plugins: voiceEditorPlugins
  })
  let newState = null
  await firebase.database().ref(ownerId + '/podcast/' + podId + '/realtime/').child('changes').startAt(null, String(latestKey + 1)).once('value').then(
    (snapshot) => {
      const changes = snapshot.val() // get the data here
      if (changes) {
        const steps = []
        const stepClientIDs = []
        const placeholderClientId = '_oldClient' + Math.random()
        const keys = Object.keys(changes)
        latestKey = Math.max(...keys)
        for (let key of keys) {
          const compressedStepsJSON = changes[key].s
          steps.push(...compressedStepsJSON.map(compressedStepJSONToStep))
          stepClientIDs.push(...new Array(compressedStepsJSON.length).fill(placeholderClientId))
        }
        state = state.apply(receiveTransaction(state, steps, stepClientIDs)) // applying the change here
      }
      // Todo apply transaction
      // let transaction = vm.migrateTransaction(state)
      // if (transaction) {
      //   state = state.apply(transaction)
      // }
      newState = state
    })
  return { state: newState, latestKey: Number(latestKey) }
}
function getNextNode(position, state) {
  try {
    return state.doc.resolve(position).nodeAfter
  } catch (e) {
    return {}
  }
}
export function computeArrangement (state) {
  /**
   * traverse whole doc, find text and music elements
   * create blocks and store in blockList
   */
  let sizeTravelled = 0 // track size travelled when navigating prosmirror doc.
  let audioLocationInGlobalTimeline = 0.0 // its a location in time units in overall timeline of document
  let audioSchedule = []
  let anchorLocation = 0 // start location of node
  state.doc.content.forEach(paraNode => {
    anchorLocation = sizeTravelled
    sizeTravelled += 2 // para jump costs size two, refer prosemirror doc
    paraNode.content.forEach(node => {
      anchorLocation = sizeTravelled
      sizeTravelled += node.nodeSize
      if (!isMarkDeleted(node.marks)) {
        node.marks.forEach(mark => {
          if (mark.attrs != null && mark.attrs.uid != null) {
            let duration = mark.attrs.aend - mark.attrs.astart
            // if (mark.attrs.uid.split('-')[0] === 'silence') { // user added silence has uid which containts text silence.
            //   duration = 0.1
            // }

            if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-') &&
              !mark.attrs.uid.includes('image-') && !mark.attrs.uid.includes('endimg-')) { // for non music nodes
              audioSchedule.push({
                text: node.text,
                time: audioLocationInGlobalTimeline,
                id: mark.attrs.uid,
                node: node,
                mark: mark,
                duration: duration,
                location: anchorLocation,
                index: audioSchedule.length
              })
              audioLocationInGlobalTimeline =
                audioLocationInGlobalTimeline + duration
            }
          }
        })
      }
    })
  })
  return audioSchedule
}
export function getEndLocationOfDoc (state) {
  let sizeTravelled = 0
  state.doc.content.forEach(paraNode => {
    sizeTravelled += 2
    paraNode.content.forEach(node => {
      sizeTravelled += node.nodeSize
    })
  })
  return sizeTravelled - 1
}
function getTextBlockAtTime (time, blockList) {
  /**
   * Traverse through the blocklist and pick block for the current time
   */

  let index = 0
  for (let i = 0; i < blockList.length; i++) {
    index = i - 1
    if (blockList[i].id !== 'music' && blockList[i].time > time) {
      break
    }
  }
  return blockList[index]
}
function moveCursorToLocation (location, state) {
  /** used in blockmode */
  let doc = state.doc
  let start = doc.resolve(location)
  try {
    state.apply(
      state.tr
        .setSelection(new TextSelection(start, start))
    )
  } catch (e) {
    console.log('caught err', e)
  }
}
export function getLocationFromTime (time, state) {
  let blockList = computeArrangement(state)
  /** get the text block at a time */
  let blockAtTime = getTextBlockAtTime(time, blockList) // get the block at that time
  return blockAtTime.location - 1
}

function selectNodeAfter (start, state) {
  /**
   * Will select even the deleted nodes
   */
  let doc = state.doc
  let node = null
  if (start.nodeAfter != null) {
    node = new NodeSelection(start).node
  } else {
    start = doc.resolve(start.pos + 2)
    node = new NodeSelection(start).node
  }
  let next = 0
  if (start.nodeAfter == null) {
    next = next + node.nodeSize + 2
  } else {
    next = next + start.nodeAfter.nodeSize
  }
  return { start: start, end: doc.resolve(start.pos + next) }
}
function nextNode (position, state) {
  try {
    return state.doc.resolve(position).nodeAfter
  } catch (e) {
    return {}
  }
}
function snappedSelectionForLocation (from, to, state) {
  /**
   * it will give us the location without any selection etc
   */
  const doc = state.doc
  let anchor = doc.resolve(from)
  let head = doc.resolve(to)
  let start = null
  let end = null
  if (anchor.pos < head.pos) {
    // * selecting forwards
    start = anchor.pos - anchor.textOffset
    if (head.nodeAfter !== null) {
      // * for some cases when nodeAfter is null
      end = head.pos + head.nodeAfter.nodeSize
    } else {
      end = head.pos
    }
  } else {
    //* selecting backwards
    if (anchor.nodeAfter !== null && anchor.textOffset > 0) {
      // * for some cases when nodeAfter is null
      start = anchor.pos + anchor.nodeAfter.nodeSize
    } else {
      start = anchor.pos
    }
    end = head.pos - head.textOffset
  }

  return { start, end }
}
export function takeCursorToBoundary (state) {
  let newState = null
  /**
   * We want to move cursor to word boundry, not in the middle
   * This function change the user selection to select the boundry
   */
  const doc = state.doc
  const anchorPos = state.selection.anchor // place where you start the selection
  const headPos = state.selection.head // place where you end the selection
  let newPosition = null
  let temp = null
  // console.log(anchor, head);
  if (anchorPos - headPos === 0) {
    // * user has not selected any text
    temp = doc.resolve(anchorPos)
    if (temp.textOffset === 0) {
      //* user is already at boundary
      return state
    } else {
      //* user is inside node; resolve to right boundary
      newPosition = doc.resolve(temp.pos + temp.nodeAfter.nodeSize)
    }
  } else {
    // * user has selected text
    if (anchorPos < headPos) {
      // * selecting forwards; paste after right boundary(headPos)
      newPosition = doc.resolve(headPos)
    } else {
      // * selecting forwards; paste after right boundary(anchorPos)
      newPosition = doc.resolve(anchorPos)
    }
  }
  newState = state.apply(state.tr.setSelection(new TextSelection(newPosition, newPosition)))
  console.log(newState, 'newState')
  return newState
}

export function addInlineMediaAtEnd(state, name, asource, duration) {
  let startLocation = getEndLocationOfDoc(state)
  return loadAudioWithIndex(state, duration, name, asource, startLocation, startLocation)
}
export function addAudioVideoTime(state, path, name, asource, startTime, endTime, duration) {
  let startLocation = getLocationFromTime(startTime, state)
  let endLocation = startLocation
  if (startTime !== endTime) {
    endLocation = getLocationFromTime(endTime, state)
  }
  return addAudioVideo(state, path, name, asource, startLocation, endLocation, duration)
}
// asource = m-11eb-e660-8597f324-bc71-0242ac110005#!@MTNd7878T3MMJFAy24btur6Rs662
export const addAudioVideo = (state, path, name, asource, startLocation, endLocation, duration) => {
  return loadAudioWithIndex(state, duration, name, asource, startLocation, endLocation)
}

export function loadAudioWithIndex (astate, duration, name, asource, startLocation, endLocation) {
  astate = astate.apply(astate.tr.setSelection(new TextSelection(astate.doc.resolve(startLocation), astate.doc.resolve(endLocation))))

  let state = astate
  if (astate.selection.anchor !== astate.selection.head) {

  } else {
    state = takeCursorToBoundary(astate)
  }
  let insertPos = Math.min(state.selection.anchor, state.selection.head)
  let doc = state.doc
  let uniqueSourceId = uuidv1()
  let musicId = 'music-' + uniqueSourceId
  let silenceCount = duration / 1
  let transaction
  let newPosition = 0

  // create audio node, starpill
  let audioNode = state.schema.node(
    'music',
    {
      title: name,
      type: 'music',
      uid: uniqueSourceId,
      fadeIn: {
        start: 0,
        end: 56,
        duration: duration * 10 / 100
      },
      fadeOut: {
        end: 0,
        duration: duration * 10 / 100
      }
    },
    state.schema.text(name),
    [
      state.schema.mark('info', {
        uid: musicId,
        asource: asource,
        astart: 0,
        aend: duration,
        class: 'localaudio'
      })
    ]
  )
  // endpill
  let audioEndNode = state.schema.node(
    'music',
    {
      title: name,
      type: 'endMusic',
      uid: uniqueSourceId,
      fadeIn: {
        start: 0,
        end: 56,
        duration: duration * 10 / 100
      },
      fadeOut: {
        end: 0,
        duration: duration * 10 / 100
      }
    },
    null,
    [
      state.schema.mark('info', {
        uid: 'endmus-' + uniqueSourceId,
        astart: 0,
        aend: duration,
        class: 'localaudioend'
      })
    ]
  )

  // adding it in background (anchor!= head)
  if (state.selection.anchor !== state.selection.head) {
    let start = doc.resolve(Math.min(state.selection.anchor, state.selection.head))
    let end = doc.resolve(Math.max(state.selection.anchor, state.selection.head))
    let selectedDuration = 0
    let i
    for (i = start.pos; selectNodeAfter(doc.resolve(i), state).end.pos < end.pos; i = selectNodeAfter(doc.resolve(i), state).end.pos) {
      let node = nextNode(i, state)
      if (node == null) { // encountered paragraph
        i = i + 2
        continue
      }
      if ((selectedDuration + nodeDuration(node)) > duration) {
        break
      }
      selectedDuration = selectedDuration + nodeDuration(node)
    }
    audioNode = state.schema.node(
      'music',
      {
        title: name,
        type: 'music',
        uid: uniqueSourceId,
        fadeIn: {
          start: 0,
          end: 56,
          duration: selectedDuration * 10 / 100
        },
        fadeOut: {
          end: 0,
          duration: selectedDuration * 10 / 100
        }
      },
      state.schema.text(name),
      [
        state.schema.mark('info', {
          uid: musicId,
          asource: asource,
          astart: 0,
          aend: duration,
          class: 'localaudio'
        })
      ]
    )
    // endpill
    audioEndNode = state.schema.node(
      'music',
      {
        title: name,
        type: 'endMusic',
        uid: uniqueSourceId,
        fadeIn: {
          start: 0,
          end: 56,
          duration: selectedDuration * 10 / 100
        },
        fadeOut: {
          end: 0,
          duration: selectedDuration * 10 / 100
        }
      },
      null,
      [
        state.schema.mark('info', {
          uid: 'endmus-' + uniqueSourceId,
          astart: 0,
          aend: duration,
          class: 'localaudioend'
        })
      ]
    )
    let newNoteMark = state.schema.mark('note', {
      class: 'note',
      note_uid: uniqueSourceId
    })
    if (i === start.pos) { // fix for when one word is selected
      i = i + 1
    }
    let pos = snappedSelectionForLocation(start.pos, end.pos, state)
    // add mark on the nodes
    transaction = state.tr.addMark(pos.start, pos.end, newNoteMark).insert(insertPos, audioNode).insert(pos.end + audioNode.nodeSize, audioEndNode)

    // when adding in between
  } else {
    newPosition = insertPos + audioNode.nodeSize
    transaction = state.tr.insert(insertPos, audioNode)
    // let musicWaveClubFactor = 5
    for (let i = 0; i <= silenceCount; i++) {
      let level = Math.random() * 127
      let soundchar = String.fromCharCode(0x200 + level)
      // for (let j = 0; j <= musicWaveClubFactor; j++) {
      // soundchar += String.fromCharCode(0x200 + level)
      // level = Math.random() * 127
      // }
      let r = Math.random() // we have some gap between sound chars to look not so cramped w/r to its length
      if (Math.round(r * 10) === 1) {
        soundchar = ' '
      }
      // create anew node dynamically and assign the class music
      // let silenceNode = state.schema.text(soundchar, [
      let silenceNode = state.schema.text(silenceSymbol, [
        state.schema.mark('silence', {
          uid: 'silence-' + uuidv1(),
          astart: 0,
          aend: 0.1
        }),
        state.schema.mark('info', {
          uid: 'silence-' + uuidv1(),
          astart: 0,
          aend: 1
        }),
        state.schema.mark('note', {
          class: 'note',
          note_uid: uniqueSourceId
        })
      ])
      transaction = transaction.insert(newPosition, silenceNode)
      newPosition += silenceNode.nodeSize
    }
    transaction.insert(newPosition, audioEndNode)
  }
  state = state.apply(transaction)
  toggleAddButton(false)
  return state
  // view.dispatch(
  //   view.state.tr.setMeta('docEdited', true)
  // )

  //
}

export function addImageTime(state, name, asource, startTime, endTime, meta) {
  let startLocation = 0

  let endLocation = startLocation
  if (startTime !== endTime) {
    endLocation = getLocationFromTime(endTime, state)
  }

  // let endLocation = getEndLocationOfDoc(state)

  return addImage(state, name, asource, startLocation, endLocation, meta)
}

export const addImage = (state, name, asource, startLocation, endLocation, meta) => {
  state = state.apply(state.tr.setSelection(new TextSelection(state.doc.resolve(startLocation), state.doc.resolve(endLocation))))
  state = loadImageWithIndex(state, name, asource, meta)
  return state
}

export function loadImageWithIndex (astate, name, asource, position) {
  let state = astate

  if (astate.selection.anchor !== astate.selection.head) {

  } else {
    state = takeCursorToBoundary(astate)
  }
  let insertPos = Math.min(state.selection.anchor, state.selection.head)
  let doc = state.doc
  let uniqueSourceId = uuidv1()
  let imageId = 'image-' + uniqueSourceId
  let silenceCount = 1
  let transaction
  let newPosition = 0

  // create audio node, starpill
  console.log('project logo position: ', position)
  let imageNode = state.schema.node(
    'image',
    {
      title: name,
      type: 'image',
      uid: uniqueSourceId,
      position
    },
    state.schema.text(name),
    [
      state.schema.mark('info', {
        uid: imageId,
        asource: asource,
        astart: 0,
        aend: 0,
        mediaType: 'image',
        class: 'localimage'
      })
    ]
  )
  // endpill
  let imageEndNode = state.schema.node(
    'image',
    {
      title: name,
      type: 'endImage',
      uid: uniqueSourceId,
      position
    },
    null,
    [
      state.schema.mark('info', {
        uid: 'endimg-' + uniqueSourceId,
        astart: 0,
        aend: 0,
        class: 'localimageend'
      })
    ]
  )

  // adding it in background (anchor!= head)
  if (state.selection.anchor !== state.selection.head) {
    let start = doc.resolve(Math.min(state.selection.anchor, state.selection.head))
    let end = doc.resolve(Math.max(state.selection.anchor, state.selection.head))
    let selectedDuration = 0
    let i
    for (i = start.pos; selectNodeAfter(doc.resolve(i), state).end.pos < end.pos; i = selectNodeAfter(doc.resolve(i), state).end.pos) {
      let node = nextNode(i, state)
      if (node == null) { // encountered paragraph
        i = i + 2
        continue
      }
    }
    let newImageMark = state.schema.mark('imageMark', {
      class: 'imageMark',
      source: asource,
      image_mark_uid: uniqueSourceId,
      position
    })
    if (i === start.pos) { // fix for when one word is selected
      i = i + 1
    }
    let pos = snappedSelectionForLocation(start.pos, end.pos, state)
    // add mark on the nodes
    transaction = state.tr.addMark(pos.start, pos.end, newImageMark).insert(insertPos, imageNode).insert(pos.end + imageNode.nodeSize, imageEndNode)

    // when adding in between
  } else {
    newPosition = insertPos + imageNode.nodeSize
    transaction = state.tr.insert(insertPos, imageNode)
    // let musicWaveClubFactor = 5
    for (let i = 0; i <= silenceCount; i++) {
      let level = Math.random() * 127
      let soundchar = String.fromCharCode(0x200 + level)
      // for (let j = 0; j <= musicWaveClubFactor; j++) {
      // soundchar += String.fromCharCode(0x200 + level)
      // level = Math.random() * 127
      // }
      let r = Math.random() // we have some gap between sound chars to look not so cramped w/r to its length
      if (Math.round(r * 10) === 1) {
        soundchar = ' '
      }
      // create anew node dynamically and assign the class music
      // let silenceNode = state.schema.text(soundchar, [
      let silenceNode = state.schema.text(silenceSymbol, [
        state.schema.mark('info', {
          uid: 'silence-' + uuidv1(),
          astart: 0,
          aend: 1
        }),
        state.schema.mark('imageMark', {
          class: 'imageMark',
          source: asource,
          image_mark_uid: uniqueSourceId,
          position
        })
      ])
      transaction = transaction.insert(newPosition, silenceNode)
      newPosition += silenceNode.nodeSize
    }
    transaction.insert(newPosition, imageEndNode)
  }
  state = state.apply(transaction)
  return state
}

export function computeTotalDuration (state) {
  let sizeTravelled = 0
  let totalTime = 0
  state.doc.content.forEach(function (paraNode, offset, index) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      textNode.marks.every(function (mark) {
        if (mark.type.name === 'deleted') {
          return false
        }
        if (mark.attrs != null && mark.attrs.uid != null) {
          if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
            let duration = mark.attrs.aend - mark.attrs.astart

            totalTime += duration
          }
        }
        return true
      })
      sizeTravelled += textNode.nodeSize
    })
  })
  return totalTime
}
export function computeDocumentSize (state) {
  let sizeTravelled = 0
  state.doc.content.forEach(function (paraNode, offset, index) {
    sizeTravelled = sizeTravelled + 2
    paraNode.content.forEach(function (textNode) {
      sizeTravelled += textNode.nodeSize
    })
  })
  return sizeTravelled
}

export async function saveDoc(state, userId, podId, firebase, latestKey) {
  const { doc } = state.toJSON()

  let saveProseDoc = firebase.functions().httpsCallable('saveProseDoc')
  await saveProseDoc({ podId: podId,
    stateJSON: JSON.stringify(doc),
    latestKey: latestKey
  }).then((result) => {
    // Read result of the Cloud Function.
    return result.success
  })

  // const TIMESTAMP = { '.sv': 'timestamp' }
  // // const { doc } = state.toJSON()
  // const doc = state.doc.toJSON()
  //
  // let key = latestKey + 1
  // // check registerEmitUpdate for callback definition
  // let docc = { doc, k: key, t: TIMESTAMP }
  //
  // let { d } = compressStateJSON(docc)
  // console.log(d, 'zxczzcx', podId)
  // let updates = {}
  // updates['/podcast/' + podId + '/realtime/checkpoint/'] = { d: d, k: docc.k, t: docc.t ? docc.t : '' }
  // try {
  //   firebase
  //     .database()
  //     .ref(userId)
  //     .update(updates).then(() => {
  //       console.log('zxczzcx')
  //     }).catch(err => {
  //       console.log('zxczzcx', err)
  //     })
  // } catch (err) {
  //   console.log(err)
  // }

  // const TIMESTAMP = { '.sv': 'timestamp' }
  // const sendable = sendableSteps(state)
  // if (sendable) {
  //   const { steps, clientID } = sendable
  //
  //   let changesRef = firebase
  //     .database()
  //     .ref(userId + '/podcast/' + podId + '/realtime/changes')
  //   // save changes & checkpoint
  //   await changesRef.child(latestKey + 1).transaction(
  //     function (existingBatchedSteps) {
  //       if (!existingBatchedSteps) {
  //         return {
  //           s: compressStepsLossy(steps).map( // third party lib help in compressing the steps
  //             function (step) {
  //               let a = compressStepJSON(step.toJSON())
  //               console.log('zxczzcx', a)
  //               return a
  //             }),
  //           c: clientID,
  //           t: TIMESTAMP
  //         }
  //       }
  //     },
  //     function (error, committed, snapshot) {
  //       // // console.log('error, committed, { key }', error, committed, key)
  //       if (error) {
  //         console.log('updateCollab zxczzcx', error, sendable)
  //       } else if (committed) {
  //         let { key } = snapshot
  //         console.log('zxczzcx')
  //
  //         key = Number(key)
  //         const { doc } = state.toJSON()
  //         // check registerEmitUpdate for callback definition
  //         let docc = { doc, k: key, t: TIMESTAMP }
  //         let { d } = compressStateJSON(docc)
  //
  //         let updates = {}
  //         updates['/podcast/' + podId + '/realtime/checkpoint/'] = { d: d, k: docc.k, t: docc.t ? docc.t : '' }
  //         try {
  //           firebase
  //             .database()
  //             .ref(userId)
  //             .update(updates).then(() => {
  //               console.log('zxczzcx')
  //             }).catch(err => {
  //               console.log('zxczzcx', err)
  //             })
  //         } catch (err) {
  //           console.log(err)
  //         }
  //       }
  //     },
  //     false)
  // }
}

export function textSelection (state) {
  const doc = state.doc
  const anchor = doc.resolve(state.selection.anchor)
  const head = doc.resolve(state.selection.head)
  let start = null
  let end = null
  if (anchor.pos === head.pos) {
    start = doc.resolve(anchor.pos - anchor.textOffset)
    if (head.nodeAfter === null) {
      end = doc.resolve(head.pos)
    } else {
      end = doc.resolve(head.pos + head.nodeAfter.nodeSize) // select the word
    }
    return state.apply(state.tr.setSelection(new TextSelection(start, end)))
  } else {
    return state
  }
}
export async function createNewSpextDoc(firebase, podId, ownerId, title) {
  let emptyDocument = {
    docVersion: 2,
    type: 'doc',
    content: [{
      type: 'paragraph',
      attrs: {
        labelName: '',
        labelColor: ''
      },
      content: []
    }]
  }
  let voiceEditorPlugins = [
    history(),
    collab({ clientID: `${ownerId}-${uuidv1()}` })
  ]

  let ndoc = Node.fromJSON(schema, emptyDocument)
  let state = EditorState.create({
    schema: schema,
    doc: ndoc,
    plugins: voiceEditorPlugins
  })
  await saveDoc(state, ownerId, podId, firebase, -1)

  firebase.database().ref(ownerId + '/podcast-info/' + podId)
    .update({ status: { code: 206 }, title: title, version: 2, audio_editor: true }).then(() => {
      console.log('written to db. Updated podcastInfo', ownerId, podId)
    }).catch((err) => {
      console.log('unable to save', err)
    })
  return state
}

export async function createAndCopyDoc(fromDocState, startLocation, endLocation, podId, ownerId, title) {
  let state = await createNewSpextDoc(firebase, podId, ownerId, title)
  fromDocState = fromDocState.apply(fromDocState.tr.setSelection(new TextSelection(fromDocState.doc.resolve(startLocation), fromDocState.doc.resolve(endLocation))))
  fromDocState = textSelection(fromDocState)
  let ndoc = Fragment.from(fromDocState.selection.content())
  state = state.apply(state.tr.insert(
    1,
    ndoc
  ))
  await saveDoc(state, ownerId, podId, firebase, 1)
  return { state, latestKey: 2 }
}

export async function createAndCopyDocTime(fromDocState, startTime, endTime, podId, ownerId, title) {
  let state = await createNewSpextDoc(firebase, podId, ownerId, title)

  let startLocation = getLocationFromTime(startTime, fromDocState)
  let endLocation = startLocation
  if (startTime !== endTime) endLocation = getLocationFromTime(endTime, fromDocState)

  fromDocState = fromDocState.apply(fromDocState.tr.setSelection(new TextSelection(fromDocState.doc.resolve(startLocation), fromDocState.doc.resolve(endLocation))))
  fromDocState = textSelection(fromDocState)
  let ndoc = fromDocState.selection.content().content
  state = state.apply(state.tr.insert(
    1,
    ndoc
  ))
  await saveDoc(state, ownerId, podId, firebase, 1)
  return { state, latestKey: 2 }
}

export async function createDocstateCopy() {
  let emptyDocument = {
    docVersion: 2,
    type: 'doc',
    content: [{
      type: 'paragraph',
      attrs: {
        labelName: '',
        labelColor: ''
      },
      content: []
    }]
  }
  let voiceEditorPlugins = [
    history()
    // collab({ clientID: `${ownerId}-${uuidv1()}` })
  ]

  let ndoc = Node.fromJSON(schema, emptyDocument)
  let state = EditorState.create({
    schema: schema,
    doc: ndoc,
    plugins: voiceEditorPlugins
  })

  return state
}

export async function createClippedDoc(fromDocState, startTime, endTime) {
  let state = await createDocstateCopy()

  let startLocation = getLocationFromTime(startTime, fromDocState)
  let endLocation = startLocation
  if (startTime !== endTime) endLocation = getLocationFromTime(endTime, fromDocState)

  fromDocState = fromDocState.apply(fromDocState.tr.setSelection(new TextSelection(fromDocState.doc.resolve(startLocation), fromDocState.doc.resolve(endLocation))))
  fromDocState = textSelection(fromDocState)
  let ndoc = fromDocState.selection.content().content
  state = state.apply(state.tr.insert(
    1,
    ndoc
  ))
  return { state, latestKey: 2 }
}
export async function createClippedDocFromLocation(fromDocState, startLocation, endLocation) {
  let state = await createDocstateCopy()

  fromDocState = fromDocState.apply(fromDocState.tr.setSelection(new TextSelection(fromDocState.doc.resolve(startLocation), fromDocState.doc.resolve(endLocation))))
  fromDocState = textSelection(fromDocState)
  let ndoc = fromDocState.selection.content().content
  state = state.apply(state.tr.insert(
    1,
    ndoc
  ))
  return { state, latestKey: 2 }
}

export function addTextSelection(state, location) {
  const { start: startLocation, end: endLocation } = location
  document._customTextSelection = {
    from: startLocation,
    to: endLocation
  }
  // return state.apply(
  //   state.tr
  //     .setSelection(new TextSelection(state.doc.resolve(startLocation), state.doc.resolve(endLocation)))
  // )
}

export async function createAndCopyDocLocation(fromDocState, startLocation, endLocation, podId, ownerId, title) {
  let state = await createNewSpextDoc(firebase, podId, ownerId, title)

  fromDocState = fromDocState.apply(fromDocState.tr.setSelection(new TextSelection(fromDocState.doc.resolve(startLocation), fromDocState.doc.resolve(endLocation))))
  fromDocState = textSelection(fromDocState)
  let ndoc = fromDocState.selection.content().content
  state = state.apply(state.tr.insert(
    1,
    ndoc
  ))
  await saveDoc(state, ownerId, podId, firebase, 1)
  return { state, latestKey: 2 }
}
