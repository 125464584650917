import uuidv1 from 'uuid/v1'
export let addNoise = false
export let isDemo = false
export const info = {
  attrs: {
    uid: '', // unique id
    asource: { default: '0' }, // audio source uid!$#media_id
    astart: '',
    aend: '',
    mediaType: { default: 'audio' },
    class: { default: 'intranscript' }
  },
  parseDOM: [
    {
      tag: `span[uid]`, // each span element with uid is consider to be info
      getAttrs: function getAttrs (node) {
        let uuid = uuidv1()
        if (node.getAttribute('uid').includes('music-')) {
          uuid = 'music-' + uuid
        } else if (node.getAttribute('uid').includes('endmus-')) {
          uuid = 'endmus-' + uuid
        } else if (node.getAttribute('uid').includes('mtext-')) {
          uuid = 'mtext-' + uuid
        } else if (node.getAttribute('uid').includes('silence-')) {
          uuid = 'silence-' + uuid
        }
        return {
          uid: uuid, // for prosemirror to take each node as unique
          astart: Number(node.getAttribute('astart')),
          aend: Number(node.getAttribute('aend')),
          asource: node.getAttribute('asource'),
          class: node.getAttribute('class')
        }
      }
    }
  ],
  // toDOM: (node) => ['span', node.attrs]
  toDOM: (mark) => {
    if (window.mode === 'editTranscript') {
      // don't show silence , music icon, music waves
      if (mark.attrs.asource === '0' || mark.attrs.uid.includes('music-') || mark.attrs.uid.includes('mtext-') || mark.attrs.uid.includes('endmus-')) {
        mark.attrs.class = 'musicT'
      }
    } else {
      // if (mark.attrs.asource === '0' || mark.attrs.uid.includes('music-') || mark.attrs.uid.includes('mtext-')) {
      //   mark.attrs.class = 'music'
      // }
      if (mark.attrs.uid.includes('music-')) {
        mark.attrs.class = 'localaudio'
      }
      if (mark.attrs.uid.includes('endmus-')) {
        mark.attrs.class = 'localaudioend'
      }
    }
    if (isDemo) {
      if (addNoise) {
        if (!(mark.attrs.asource.includes('-noise') || mark.attrs.uid.includes('music-') || mark.attrs.uid.includes('mtext-'))) {
          mark.attrs.asource += '-noise'
        }
      } else {
        if (mark.attrs.asource.includes('-noise')) {
          mark.attrs.asource = mark.attrs.asource.replace('-noise', '')
        }
      }
    }

    return ['span', mark.attrs]
  }
}

export function setAddNoise (addNoisevar) {
  addNoise = addNoisevar
}

export function setIsDemo (payload) {
  isDemo = payload
}
