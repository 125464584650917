import { TextSelection, Plugin } from 'prosemirror-state'
import store from '@/services/store'
import { addCustomHighlight, removeAllMarks } from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
/**
 * the selection should snap to word/paragraph boundary when user selects something
 * when user has selected something
 * then create a new selection
 * * user can select either forward, ie anchor<head
 * * or backwards, ie head<anchor
 */
export function handleSelection () {
  return new Plugin({
    props: {
      createSelectionBetween: function (view, anchor, head) {
        // console.log('view, anchor, head', view, anchor, head)
        if (store.state.app.isMobile) {
          return snapSelection(view, anchor, head)
        }
        if (anchor.pos !== head.pos) {
          return snapSelection(view, anchor, head)
        } else {
          // removeAllMarks('customTextSelect')
        }
        // console.log('from,to,text',from,to,text)
        // return false
      }
    }
  })
}

/**
 * called when any selection is made
 * used to make selection snap to boundaries
 */
export function snapSelection (view, anchor, head) {
  /**
   * calculate start, end for the word based selection
   */

  const doc = view.state.doc
  let start = null
  let end = null
  if (anchor.pos < head.pos) {
    // * selecting forwards
    start = doc.resolve(anchor.pos - anchor.textOffset)
    if (head.nodeAfter !== null && head.textOffset > 0) {
      // * for some cases when nodeAfter is null
      end = doc.resolve(head.pos + head.nodeAfter.nodeSize)
    } else {
      end = head
    }
  } else {
    //* selecting backwards
    if (anchor.nodeAfter !== null && anchor.textOffset > 0) {
      // * for some cases when nodeAfter is null
      start = doc.resolve(anchor.pos + anchor.nodeAfter.nodeSize)
    } else {
      start = anchor
    }
    end = doc.resolve(head.pos - head.textOffset)
  }
  /** A better yet untested solution for snapping selection. Handles clicks better */
  // else if (anchor.pos > head.pos) {
  //   //* selecting backwards
  //   if (anchor.nodeAfter !== null && anchor.textOffset > 0) {
  //     // * for some cases when nodeAfter is null
  //     start = doc.resolve(anchor.pos + anchor.nodeAfter.nodeSize)
  //   } else {
  //     start = anchor
  //   }
  //   end = doc.resolve(head.pos - head.textOffset)
  // } else {
  //   if (anchor.nodeAfter !== null && anchor.textOffset > 0) {
  //     if (anchor.nodeAfter.nodeSize <= 1) {
  //       start = doc.resolve(anchor.pos + anchor.nodeAfter.nodeSize)
  //       return new TextSelection(start)
  //     } else {
  //       start = doc.resolve(anchor.pos + anchor.nodeAfter.nodeSize)
  //       end = doc.resolve(head.pos - head.textOffset)
  //     }
  //   } else {
  //     start = anchor
  //     end = anchor
  //   }
  // }

  let startPos = Math.min(start.pos, end.pos)
  let preventSelection = true
  let nodeAfter = doc.resolve(startPos).nodeAfter
  while (startPos <= Math.max(start.pos, end.pos)) {
    if (nodeAfter == null || nodeAfter.marks == null) {
      break
    }
    let seenNonMtext = true
    if (nodeAfter.marks != null) {
      nodeAfter.marks.forEach(mark => {
        if (mark.attrs != null && mark.attrs.uid != null && mark.attrs.uid.includes('mtext-')) {
          seenNonMtext = false
        }
      })
    }
    if (seenNonMtext) {
      preventSelection = false
    }

    startPos += nodeAfter.nodeSize
    nodeAfter = doc.resolve(startPos).nodeAfter
    if (nodeAfter == null) {
      startPos += 2
      try {
        nodeAfter = doc.resolve(startPos).nodeAfter
      } catch (err) {
        startPos -= 1
        nodeAfter = doc.resolve(startPos).nodeAfter
      }
    }
    // startPos = startPos + doc.resolve(startPos).nodeAfter.nodeSize
  }
  if (preventSelection) {
    return new TextSelection(end)
  }
  if (document._isPillBeingDragged) {
    if (start.pos !== document._startPos || end.pos !== document._endPos) {
      document._startPos = start.pos
      document._endPos = end.pos
      // removeAllMarks('customTextSelect')
      let color = '#ECE1FC'
      if (start.pos < end.pos) {
        // let className = document._isPillBeingDragged === 'left' ? 'customMusicPillDragDeSelection' : 'customMusicPillDragSelection'
        // addCustomHighlight(start.pos, end.pos - 1, className)
        color = document._isPillBeingDragged === 'left' ? '#fff' : '#ECE1FC'
      } else {
        // let className = document._isPillBeingDragged === 'right' ? 'customMusicPillDragDeSelection' : 'customMusicPillDragSelection'
        // addCustomHighlight(end.pos, start.pos, className)
        color = document._isPillBeingDragged === 'right' ? '#fff' : '#ECE1FC'
      }
      document.documentElement.style.setProperty('--selection-background', color)
    }
  }
  return new TextSelection(start, end)
}
