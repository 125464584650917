<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    :class="classname"
  >
    <path d="M10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9ZM9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9H0L3 12L6 9H3.75C3.75 6.0975 6.0975 3.75 9 3.75C11.9025 3.75 14.25 6.0975 14.25 9C14.251 9.96434 13.9862 10.9103 13.4847 11.734C12.9832 12.5576 12.2643 13.2271 11.4071 13.6689C10.5499 14.1107 9.58754 14.3076 8.6257 14.2382C7.66387 14.1687 6.73979 13.8354 5.955 13.275L4.89 14.355C5.74098 15.008 6.73534 15.4488 7.79068 15.6409C8.84601 15.833 9.93192 15.7708 10.9584 15.4594C11.9849 15.1481 12.9224 14.5966 13.6933 13.8507C14.4641 13.1048 15.0461 12.1859 15.3909 11.1701C15.7358 10.1544 15.8336 9.07114 15.6763 8.01007C15.519 6.94901 15.1111 5.9407 14.4864 5.06874C13.8616 4.19677 13.0381 3.48625 12.084 2.99605C11.1299 2.50586 10.0727 2.25011 9 2.25Z"/>
  </svg>
</template>

<script>
export default {
  name: 'Search',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
